import React from "react";
import PropTypes from "prop-types";
import { Formik, Field, Form } from "formik";

const CFDIsForm = ({ clients = [], onSubmit, setClient, setContentType, setStartDate, setEndDate, 
  showWarning, otherRequests, error, loading, setDownloadType }) =>
  <div class="card">

    {/* Header */}
    <div class="card-header">
      <h3 class="card-header-title">Solicitar descarga</h3>
    </div>

    {/* Body */}
    <div class="card-body">
      <div class="row">
        <div class="col">
          <Formik
            initialValues={{}}
            onSubmit={onSubmit}>
            {({ values, setFieldValue }) =>
              <Form>
                <div class="mb-3">

                  {/* Client */}
                  <div class="form-group">
                    <label class="input-label">Cliente</label>
                    <select class="form-control js-select2-custom js-datatable-filter custom-select" name="client"
                      onChange={(e) => {
                        setFieldValue("client", parseInt(e.target.value));
                        setClient(e.target.value);
                      }} required>
                      <option value={-1}>Seleccione un cliente</option>
                      {clients.map((client) => 
                        <option value={client.id} key={client.id}>
                          {client.name}, {client.rfc}
                        </option>
                      )}
                    </select>
                  </div>

                   {/* Data type */}
                   <div className="form-group">
                    <label class="input-label">Tipo de solicitud</label>
                    <Field as="select" name="content_type" class="form-control" required
                      onChange={(e) => {
                        setContentType(e.target.value);
                        setFieldValue("content_type", e.target.value);
                      }}>
                        <option value="">Selecione una opción</option>
                        <option value="CFDI">CFDI</option>
                        <option value="METADATA">Metadata</option>
                        <option value="BOTH">Ambas</option>
                    </Field>
                  </div>

                  {/* Data type */}
                  <div className="form-group">
                    <label class="input-label">Emitidos/Recibidos</label>
                    <Field as="select" name="download_type" class="form-control" required
                      onChange={(e) => {
                        setDownloadType(e.target.value);
                        setFieldValue("download_type", e.target.value);
                      }}>
                        <option value="">Selecione una opción</option>
                        <option value="RECEIVED">Recibidos</option>
                        <option value="EMITTED">Emitidos</option>
                        <option value="BOTH">Ambos</option>
                    </Field>
                  </div>

                  {/* Start date */}
                  <div class="form-group">
                    <label class="input-label">Fecha inicial</label>
                    <Field type="date" name="start_date" class="form-control" required
                      onChange={(e) => {
                        setStartDate(e.target.value + "T00:00:00");
                      }}/>
                  </div>

                  {/* End date */}
                  <div class="form-group">
                    <label class="input-label">Fecha final</label>
                    <Field type="date" name="end_date" class="form-control" required
                      onChange={(e) => {
                        setEndDate(e.target.value + "T00:00:00");
                      }}/>
                  </div>
                 
                  {/* Warning */}
                  <div class="form-group">
                    {showWarning && otherRequests > 0 && 
                      <div className="">
                        <div className="alert alert-warning">
                          {`Hay `}
                          <b> {`${otherRequests} solicitud${otherRequests == 1 ? "" : "es"} `} </b>
                          {`realizada${otherRequests == 1 ? "" : "s"} 
                          para este cliente con el mismo periodo. 
                          Si se excede el límite de solicitudes (3), 
                          ya no se podrá generar otra descarga similar.`} 
                          <br/>
                          <b> {`Quedan ${otherRequests > 3 ? 0 : 3 - otherRequests} peticiones`} </b>
                        </div>
                      </div>
                    }
                  </div>

                </div>

                { error 
                  ? <div class="alert alert-soft-danger">
                      {error}
                    </div> 
                  : null 
                }

                <button type="submit" class="btn btn-block btn-primary">
                  { loading 
                    ? <>
                        <span 
                          className="spinner-border spinner-border-sm" 
                          role="status" 
                          aria-hidden="true" />
                        <span className="ml-2">
                          Cargando...
                        </span>
                      </> 
                    : "Solicitar" 
                  }
                </button>

              </Form>}
          </Formik>
        </div>
      </div>
    </div>

  </div>;

CFDIsForm.propTypes = {
  clients: PropTypes.array,
  onSubmit: PropTypes.func.isRequired,
  setClient: PropTypes.func,
  setType: PropTypes.func,
  setStartDate: PropTypes.func,
  setEndDate: PropTypes.func,
  showWarning: PropTypes.bool,
  otherRequests: PropTypes.number,
  qSaveRequest: PropTypes.object
};

export default CFDIsForm;