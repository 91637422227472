import React from "react";
import { Link } from "react-router-dom";

const ListView = ({ 
    filters = [],
    onClickDelete = () => { },
    clientID
}) =>
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-header">
              <h3 className="card-header-title">Filtros Pre-Cargados</h3>
              <Link to={`/${clientID}/create_filter`} className="btn btn-primary">
                <i class="fa fa-receipt mr-2"></i>
                Crear filtro
              </Link>
            </div>
            <div className="card-body">
              {filters.length === 0 ? (
                <p>No hay filtros registrados.</p>
              ) : (
                <ul className="list-group">
                  {filters.map((filter, index) => (
                    <li className="list-group-item d-flex justify-content-between" key={index}>
                      {filter.name}
                      <a 
                          onClick={() => onClickDelete(filter.id)}
                          className="btn btn-danger btn-sm">
                              <i class="tio-delete mr-2"></i>
                              Eliminar
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>;

export default ListView;
