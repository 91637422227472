import React, { useState } from "react";
import View from "components/export_pdf/Admin.view";
import { useQuery } from "seed/gql";
import { Loading } from "seed/helpers";

function Admin() {

  const userId = sessionStorage.getItem("id");
  const userType = sessionStorage.getItem("user");

  const [filters, setFilters] = useState({
    client: "0",
    provider: "all",
    month: "all",
    year: "all",
    status: "all"
  });

  let query = "name <> DEFAULT ";
  if (userType != "SUPER_ADMIN") query += `AND client_users.user.id = ${userId}`;

  const reqClients = useQuery(`{
    clients {
      id
      name
      rfc
    }
  }`, query, {
    onCompleted: (data) => {
      let newFilters = Object.assign({}, filters);
      if (data.clients.length > 0) {
        newFilters["client"] = data.clients[0].id;
        setFilters(newFilters);
      }
    }
  });

  const reqProveedores = useQuery(`{
    proveedoreses {
      id
      rfc
      nombre
    }
  }`, `client.id = ${filters["client"]}`, {
    orderBy: "rfc"
  });

  if (reqClients.loading || reqProveedores.loading) return <Loading />;
  if (reqClients.error || reqProveedores.error) return "Error";

  const clients = reqClients.data.clients;
  const providers = reqProveedores.data.proveedoreses;


  const onCloseModal = () =>
    window.location = "/home/dashboard/general"


  if (userType != "ADMIN" && userType != "SUPER_ADMIN")
    window.location.href = "/dashboard/general";

  return (
    <View
      clients={clients}
      providers={providers}
      onCloseModal={onCloseModal}
    />
  );
}

Admin.propTypes = {};

export default Admin;
