import React from "react";
import { Formik, Field, Form } from "formik";
import Footer from "components/helpers/Footer";
import moment from "moment/moment";
import { Modal } from "seed/helpers";
import CFDIsDetails from "components/download/Details";
import "moment/locale/es";
moment.locale("es");

const ReportsView = ({ 
  openAccordion,
  detailsRequest,
  reports = [], 
  clients = [],
  pageNum = 1, 
  totalCount = 1, 
  totalPages = 0,
  clientFilter = [],
  typeFilter = [],
  dateFilter = { startDate: null, endDate: null },
  search = "",
  isExportShown = false,
  onChangeClient = () => { },
  onChangeType = () => { },
  onChangeDate = () => { },
  onClickPage = () => { },
  onExport = () => { },
  onRetry = () => { },
  onVerify = () => { },
  setOpenAccordion = () => { },
  setDetailsRequest = () => { },
  setSearch = () => { },
  setIsExportShown = () => { },
}) => (
  <div class="card mb-3 mb-lg-5 px-3 py-2">

    <div class="card-header d-flex justify-content-between">
      <h3>Reportes (DEV)</h3>
      <div className="d-flex">
        <input
          class="form-control mr-2"
          type="text"
          placeholder="Buscar por ID..."
          value={search}
          onChange={(event) => setSearch(event.target.value)}
        />
        <button class="btn btn-primary d-flex" onClick={() => setIsExportShown(true)}>
          <i class="fas fa-file-export mr-2 mt-1"></i> Exportar
        </button>
      </div>
    </div>

    <div class="card-body">

      <div className="row border font-weight-bold mx-1">

        <div className="col-md-3 p-2 text-center">
          <div class="dropdown" style={{ marginTop: "-10px", marginBottom: "-10px", zIndex: "100" }}>

            <a class="btn btn-link dropdown-toggle"
              href="#"
              role="button"
              id="statusFilterDropdown"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              style={{ color: "#677788" }}>
              NOMBRE
            </a>

            <div class="dropdown-menu" aria-labelledby="statusFilterDropdown">
              <form>

                {
                  clients.map((client) => (
                    <div class="dropdown-item" key={client.id}>
                      <input defaultChecked={clientFilter.find((filter) => filter == client.id)}
                        onChange={() => onChangeClient(client.id)}
                        type="checkbox" id={`filter_client_${client.id}`} name={`client_${client.id}`} />
                      <label className="px-2 align-middle" for={`filter_client_${client.id}`}>
                        <h5>{client.name}</h5>
                      </label>
                    </div>
                  ))
                }

              </form>
            </div>

          </div>
        </div>

        <div className="col-md-1 p-2">
          RFC
        </div>

        <div className="col-md-1 p-2">
          ESTADO
        </div>

        <div className="col-md-1 p-2 text-center">
          <div class="dropdown" style={{ marginTop: "-10px", marginBottom: "-10px", zIndex: "100" }}>

            <a class="btn btn-link dropdown-toggle"
              href="#"
              role="button"
              id="statusFilterDropdown"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              style={{ color: "#677788" }}>
              TIPO
            </a>

            <div class="dropdown-menu" aria-labelledby="statusFilterDropdown">
              <form>

                <div class="dropdown-item">
                  <input defaultChecked={typeFilter.find((filter) => filter == "cfdiRequest.type=EMITTED")}
                    onChange={() => onChangeType("cfdiRequest.type=EMITTED")}
                    type="checkbox" id="filter_type_EMITTED" name="type_EMITTED" />
                  <label className="px-2 align-middle" for="filter_type_EMITTED">
                    <h5>Emitidas</h5>
                  </label>
                </div>

                <div class="dropdown-item">
                  <input defaultChecked={typeFilter.find((filter) => filter == "cfdiRequest.type=RECEIVED")}
                    onChange={() => onChangeType("cfdiRequest.type=RECEIVED")}
                    type="checkbox" id="filter_type_RECEIVED" name="type_RECEIVED" />
                  <label className="px-2 align-middle" for="filter_type_RECEIVED">
                    <h5>Recibidas</h5>
                  </label>
                </div>

                <div class="dropdown-item">
                  <input defaultChecked={typeFilter.find((filter) => filter == "cfdiRequest.dataType=CFDI")}
                    onChange={() => onChangeType("cfdiRequest.dataType=CFDI")}
                    type="checkbox" id="filter_dataType_CFDI" name="dataType_CFDI" />
                  <label className="px-2 align-middle" for="filter_dataType_CFDI">
                    <h5>CFDI</h5>
                  </label>
                </div>

                <div class="dropdown-item">
                  <input defaultChecked={typeFilter.find((filter) => filter == "cfdiRequest.dataType=METADATA")}
                    onChange={() => onChangeType("cfdiRequest.dataType=METADATA")}
                    type="checkbox" id="filter_dataType_METADATA" name="dataType_METADATA" />
                  <label className="px-2 align-middle" for="filter_dataType_METADATA">
                    <h5>METADATA</h5>
                  </label>
                </div>

              </form>
            </div>

          </div>
        </div>

        <div className="col-md-2 p-2 text-center">
          <div class="dropdown" style={{ marginTop: "-10px", marginBottom: "-10px", zIndex: "100" }}>

            <a class="btn btn-link dropdown-toggle"
              href="#"
              role="button"
              id="statusFilterDropdown"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              style={{ color: "#677788" }}>
              PERIODO
            </a>

            <div class="dropdown-menu p-2" aria-labelledby="statusFilterDropdown" style={{width: "400px"}}>
              <form>
                <div class="row">
                  <div class="col-md-6 text-center">
                    Fecha inicio
                    <input type="date" name="start_date" class="form-control"
                      onChange={(event) => onChangeDate(event.target.value, "startDate")} />
                  </div>
                  <div class="col-md-6 text-center">
                    Fecha de fin
                    <input type="date" name="end_date" class="form-control"
                      onChange={(event) => onChangeDate(event.target.value, "endDate")} />
                  </div>
                </div>
              </form>
            </div>

          </div>
        </div>

        <div className="col-md-2 p-2">
          FECHA DE PETICIÓN
        </div>

        <div className="col-md-2 p-2">
          ACCIONES
        </div>

      </div>

      {
        reports.map((report) => (
          <div 
            className="row border mx-1" 
            id={`report_${report.id}`}
            key={`report_${report.id}`}
          >

            <div className="col-md-3 p-2">
              <span class="d-block font-size-sm">{report.cfdiRequest.client.name}</span>
            
              {report.cfdiRequest.isAutoDownload ? (
                <span class="badge badge-secondary">Autómatica</span>
              ) : (
                <span class="badge badge-info">Manual</span>
              )}
            </div>

            <div className="col-md-1 p-2">
              <span class="d-block font-size-sm">{report.cfdiRequest.client.rfc}</span>
            </div>

            <div className="col-md-1 p-2">
              <span class="d-block font-size-sm">
                {function () {
                  if (report.cfdiRequest.generalStatus == "REQUEST") return "Solicitado";
                  if (report.cfdiRequest.generalStatus == "VERIFICATION") return "En verificación";
                  if (report.cfdiRequest.generalStatus == "DOWNLOAD") return "Descargando";
                  if (report.cfdiRequest.generalStatus == "FINISHED") return "Finalizado";
                  if (report.cfdiRequest.codeStatus == 404 || report.cfdiRequest.codeStatus == 304) return "Error SAT";
                  if (report.cfdiRequest.generalStatus == "ERROR") return "Error";
                  if (report.cfdiRequest.generalStatus == "REJECTED") return "Rechazado";
                  if (report.cfdiRequest.generalStatus == "PARSING") return "Procesando";
                  if (report.cfdiRequest.generalStatus == "ERROR_PARSING") return "Error en el proceso";
                  if (report.cfdiRequest.generalStatus == "NO_DATA") return "Sin información";
                }()}
              </span>
            </div>

            <div className="col-md-1 p-2">
              {report.cfdiRequest.dataType}
              <br />
              <small>
                {function () {
                  if (report.cfdiRequest.type == "EMITTED") return "Emitidas";
                  if (report.cfdiRequest.type == "RECEIVED") return "Recibidas";
                }()}
              </small>
            </div>

            <div className="col-md-2 p-2">
              <span class="d-block font-size-sm">
                {moment(report.cfdiRequest.startDate).format("DD/MM/YYYY")} - {moment(report.cfdiRequest.endDate).format("DD/MM/YYYY")}
              </span>
            </div>

            <div className="col-md-2 p-2">
              <span class="d-block font-size-sm">
                {moment(report.cfdiRequest.createdAt).format("DD/MM/YYYY HH:mm:ss")}
              </span>
              <small>
                {function () {
                  if(report.cfdiRequest.generalStatus != "VERIFICATION") return;
                  moment.relativeTimeThreshold('m', 60);
                  moment.relativeTimeThreshold('h', 24 * 2);
                  return moment(report.cfdiRequest.createdAt).locale('es').fromNow();
                }()}
              </small>
            </div>

            <div className="col-md-2 p-2">

              <a 
                class="btn btn-primary text-center" 
                style={{ width: "50px" }}
                onClick={() => setOpenAccordion(openAccordion == report.cfdiRequest.id ? null : report.cfdiRequest.id)}
              >
                <i class="fas fa-eye"></i>
              </a>

              <a
                class="btn btn-info ml-1 text-center"
                href="#"
                style={{ width: "50px" }}
                onClick={() => setDetailsRequest(report.cfdiRequest.id)}
              >
                <i class="fas fa-info"></i>
              </a>

              {
                (report.cfdiRequest.generalStatus == "ERROR" || report.cfdiRequest.generalStatus == "REJECTED") &&
                  <a 
                    class="btn btn-danger ml-1 text-center"
                    href="#"
                    style={{ width: "50px" }}
                    onClick={() => onRetry(report.cfdiRequest.id)}
                  >
                    <i class="fas fa-redo"></i>
                  </a>
              }

              {
                (report.cfdiRequest.generalStatus == "REQUEST" || report.cfdiRequest.generalStatus == "VERIFICATION") &&
                  <a 
                    class="btn btn-success ml-1 text-center"
                    href="#"
                    style={{ width: "50px" }}
                    onClick={() => onVerify(report.cfdiRequest.id)}
                  >
                    <i class="fas fa-check"></i>
                  </a>
              }

            </div>

            <div className="col-md-12 mb-3">
              <div class={`collapse ${openAccordion == report.cfdiRequest.id ? "show" : ""}`} id={`details-${report.cfdiRequest.id}`}>
                <div class="pl-3">
                  <table className="table table-sm table-bordered">
                    <thead>
                      <tr>
                        <th>UUID</th>
                        <th>Folio</th>
                        <th>Fecha</th>
                        <th>Emisor</th>
                        <th>Receptor</th>
                        <th>No. Certificado</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        report.comprobantes.map((comprobante, index) => (
                          <tr key={`comprobante_${index}`}>
                            <td>{comprobante.tfdUuid}</td>
                            <td>{comprobante.folio}</td>
                            <td>{moment(comprobante.fecha).format("DD/MM/YYYY")}</td>
                            <td>{comprobante.emisorUid}</td>
                            <td>{comprobante.receptorUid}</td>
                            <td>{comprobante.noCertificado}</td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            {
              openAccordion == report.cfdiRequest.id &&
                <div className="col-md-12 text-center mb-3">
                  <button 
                    class="btn btn-primary" 
                    onClick={() => {
                      setOpenAccordion(prev => {
                        document?.getElementById(`request_${prev}`)?.scrollIntoView({})
                        return null;
                      });
                    }}
                  >
                    <i class="fas fa-eye-slash"></i> Ocultar detalles
                  </button>
                </div>
            }

          </div>
        ))
      }

    </div>

    <Footer
      objectsInPage={reports.length}
      pageNum={pageNum}
      totalPages={totalPages}
      totalCount={totalCount}
      onClickPage={onClickPage}
    />

    {
      detailsRequest &&
        <Modal
          height="700"
          title="Detalles de la solicitud"
          onClose={() => setDetailsRequest(null)}
        >
          <CFDIsDetails 
            requestId={detailsRequest}
          />
        </Modal>
    }

    {
      isExportShown &&
        <Modal
          title="Exportar reportes"
          onClose={() => setIsExportShown(false)}
        >
          <div className="bg-white rounded p-3">
            <Formik
              initialValues={{}}
              onSubmit={onExport}>
              {({ values, setFieldValue }) =>
                <Form>
                  <div class="mb-3">

                    {/* Client */}
                    <div class="form-group">
                      <label class="input-label">Tipo</label>
                      <Field
                        as="select"
                        class="form-control js-select2-custom js-datatable-filter custom-select" 
                        name="type"
                        required
                      >
                        <option value={-1}>Seleccione un tipo</option>
                        <option value="MANUAL">Manual</option>
                        <option value="AUTO">Automática</option>
                        <option value="BOTH">Ambas</option>
                      </Field>
                    </div>

                    {/* Data type */}
                    <div className="form-group">
                      <label class="input-label">Tipo de solicitud</label>
                      <Field 
                        as="select" 
                        name="content_type" 
                        class="form-control" 
                        required
                      >
                        <option value="">Selecione una opción</option>
                        <option value="CFDI">CFDI</option>
                        <option value="METADATA">Metadata</option>
                        <option value="BOTH">Ambas</option>
                      </Field>
                    </div>

                    <div className="form-group">
                      <label class="input-label">Emitidos/Recibidos</label>
                      <Field 
                        as="select" 
                        name="download_type" 
                        class="form-control" 
                        required
                      >
                        <option value="">Selecione una opción</option>
                        <option value="RECEIVED">Recibidos</option>
                        <option value="EMITTED">Emitidos</option>
                        <option value="BOTH">Ambos</option>
                      </Field>
                    </div>

                    {/* Start date */}
                    <div class="form-group">
                      <label class="input-label">Fecha inicial</label>
                      <Field type="date" name="start_date" class="form-control" required />
                    </div>

                    {/* End date */}
                    <div class="form-group">
                      <label class="input-label">Fecha final</label>
                      <Field type="date" name="end_date" class="form-control" required />
                    </div>

                  </div>

                  <button type="submit" class="btn btn-block btn-primary">
                    Descargar
                  </button>

                </Form>}
            </Formik>
          </div>
        </Modal>
    }

  </div>
);


export default ReportsView;
