import React from "react";
import View from "components/navigation/Topnav.view";
import { useDetail } from "seed/gql";

function Topnav() {  
  const userId = sessionStorage.getItem("id");

  const reqUser = useDetail(`
  {
    user {
      firstName
      lastName
      email
    }
  }`, userId);

  const { user = {} } = reqUser.data;
  const userType = sessionStorage.getItem("user");
  const isAdmin = userType == "ADMIN" || userType == "SUPER_ADMIN";
  
  return <View user={user} isAdmin={isAdmin} userType={userType} />;
}
Topnav.propTypes = {};

export default Topnav;