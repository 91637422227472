import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import CFDIsRequestsList from "components/download/List";
import PropTypes from "prop-types";
import { ModalRoute } from "seed/helpers";
import CFDIsDetails from "components/download/Details";

const RequestCFDIs = ({ 
  clients, 
  clientFilter, 
  typeFilter, 
  dateFilter, 
  onChangeClient, 
  onChangeType, 
  onChangeDate 
}) => (
  <BrowserRouter basename="/home/download">

    <div class="card mb-3 mb-lg-5 px-3 py-2">
      <div class="card-header card-header-content-sm-between">
        <h3 class="card-header-title mb-2 mb-sm-0">Descargas SAT</h3>
        <Link to="/create" className="btn btn-primary">
          <i class="tio-cloud mr-2"></i>Solicitar descarga
        </Link>
      </div>

      <div class="card-body">

        <div className="row border font-weight-bold mx-1">

          <div className="col-md-3 p-2 text-center">
            <div class="dropdown" style={{ marginTop: "-10px", marginBottom: "-10px", zIndex: "100" }}>

              <a class="btn btn-link dropdown-toggle"
                href="#"
                role="button"
                id="statusFilterDropdown"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{ color: "#677788" }}>
                NOMBRE
              </a>

              <div class="dropdown-menu" aria-labelledby="statusFilterDropdown">
                <form>

                  {
                    clients.map((client) => (
                      <div class="dropdown-item" key={client.id}>
                        <input defaultChecked={clientFilter.find((filter) => filter == client.id)}
                          onChange={() => onChangeClient(client.id)}
                          type="checkbox" id={`filter_client_${client.id}`} name={`client_${client.id}`} />
                        <label className="px-2 align-middle" for={`filter_client_${client.id}`}>
                          <h5>{client.name}</h5>
                        </label>
                      </div>
                    ))
                  }

                </form>
              </div>

            </div>
          </div>

          <div className="col-md-1 p-2">
            RFC
          </div>

          <div className="col-md-1 p-2">
            ESTADO
          </div>

          <div className="col-md-1 p-2 text-center">
            <div class="dropdown" style={{ marginTop: "-10px", marginBottom: "-10px", zIndex: "100" }}>

              <a class="btn btn-link dropdown-toggle"
                href="#"
                role="button"
                id="statusFilterDropdown"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{ color: "#677788" }}>
                TIPO
              </a>

              <div class="dropdown-menu" aria-labelledby="statusFilterDropdown">
                <form>

                  <div class="dropdown-item">
                    <input defaultChecked={typeFilter.find((filter) => filter == "type=EMITTED")}
                      onChange={() => onChangeType("type=EMITTED")}
                      type="checkbox" id="filter_type_EMITTED" name="type_EMITTED" />
                    <label className="px-2 align-middle" for="filter_type_EMITTED">
                      <h5>Emitidas</h5>
                    </label>
                  </div>

                  <div class="dropdown-item">
                    <input defaultChecked={typeFilter.find((filter) => filter == "type=RECEIVED")}
                      onChange={() => onChangeType("type=RECEIVED")}
                      type="checkbox" id="filter_type_RECEIVED" name="type_RECEIVED" />
                    <label className="px-2 align-middle" for="filter_type_RECEIVED">
                      <h5>Recibidas</h5>
                    </label>
                  </div>

                  <div class="dropdown-item">
                    <input defaultChecked={typeFilter.find((filter) => filter == "dataType=CFDI")}
                      onChange={() => onChangeType("dataType=CFDI")}
                      type="checkbox" id="filter_dataType_CFDI" name="dataType_CFDI" />
                    <label className="px-2 align-middle" for="filter_dataType_CFDI">
                      <h5>CFDI</h5>
                    </label>
                  </div>

                  <div class="dropdown-item">
                    <input defaultChecked={typeFilter.find((filter) => filter == "dataType=METADATA")}
                      onChange={() => onChangeType("dataType=METADATA")}
                      type="checkbox" id="filter_dataType_METADATA" name="dataType_METADATA" />
                    <label className="px-2 align-middle" for="filter_dataType_METADATA">
                      <h5>METADATA</h5>
                    </label>
                  </div>

                </form>
              </div>

            </div>
          </div>

          <div className="col-md-2 p-2 text-center">
            <div class="dropdown" style={{ marginTop: "-10px", marginBottom: "-10px", zIndex: "100" }}>

              <a class="btn btn-link dropdown-toggle"
                href="#"
                role="button"
                id="statusFilterDropdown"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{ color: "#677788" }}>
                PERIODO
              </a>

              <div class="dropdown-menu p-2" aria-labelledby="statusFilterDropdown" style={{width: "400px"}}>
                <form>
                  <div class="row">
                    <div class="col-md-6 text-center">
                      Fecha inicio
                      <input type="date" name="start_date" class="form-control"
                        onChange={(event) => onChangeDate(event.target.value, "startDate")} />
                    </div>
                    <div class="col-md-6 text-center">
                      Fecha de fin
                      <input type="date" name="end_date" class="form-control"
                        onChange={(event) => onChangeDate(event.target.value, "endDate")} />
                    </div>
                  </div>
                </form>
              </div>

            </div>
          </div>

          <div className="col-md-2 p-2">
            FECHA DE PETICIÓN
          </div>

          <div className="col-md-2 p-2">
            ACCIONES
          </div>

        </div>

        <CFDIsRequestsList filters={{ clientFilter, typeFilter, dateFilter }} />
      </div>

      <ModalRoute
        height="600"
        path="/:requestId(\d+)"
        component={CFDIsDetails} />

    </div>
  </BrowserRouter>
);

RequestCFDIs.propTypes = {
  clients: PropTypes.array.isRequired,
  clientFilter: PropTypes.array,
  typeFilter: PropTypes.array,
  dateFilter: PropTypes.array,
  onChangeClient: PropTypes.func,
  onChangeType: PropTypes.func,
  onChangeDate: PropTypes.func
};

export default RequestCFDIs;
