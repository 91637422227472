import React from "react";
import { Formik, Field, Form } from "formik";
import Footer from "components/helpers/Footer";
import moment from "moment/moment";
import { Modal } from "seed/helpers";
import CFDIsDetails from "components/download/Details";
import "moment/locale/es";
moment.locale("es");

const ReportsView = ({ 
  detailsRequest,
  reports = [], 
  clients = [],
  pageNum = 1, 
  totalCount = 1, 
  totalPages = 0,
  clientFilter = [],
  typeFilter = [],
  search = "",
  isExportShown = false,
  isReportLoading = false,
  isRetryShown,
  onChangeClient = () => { },
  onChangeType = () => { },
  onChangeDate = () => { },
  onClickPage = () => { },
  onExport = () => { },
  onFix = () => { },
  setDetailsRequest = () => { },
  setSearch = () => { },
  setIsExportShown = () => { },
  setIsRetryShown = () => { },
  onTestReports = () => { }
}) => (
  <div class="card mb-3 mb-lg-5 px-3 py-2">

    <div class="card-header d-flex justify-content-between">
      <h3>Reportes (DEV)</h3>
      <div className="d-flex">
        <input
          class="form-control mr-2"
          type="text"
          placeholder="Buscar por ID..."
          value={search}
          onChange={(event) => setSearch(event.target.value)}
        />
        <button class="btn btn-primary btn-sm d-flex align-items-center mr-2" onClick={() => setIsExportShown(true)}>
          <i class="fas fa-file-export mr-2"></i> Reportes
        </button>
        <button class="btn btn-secondary btn-sm align-items-center d-flex" onClick={() => setIsRetryShown(true)}>
          <i class="fas fa-redo mr-2"></i> Descargas
        </button>
        <button class="btn btn-secondary btn-sm align-items-center d-flex ml-2" onClick={onTestReports}>
          <i class="fas fa-redo mr-2"></i> Test
        </button>
      </div>
    </div>

    <div class="card-body">

      <div className="row border font-weight-bold mx-1">

        <div className="col-md-3 p-2 text-center">
          <div class="dropdown" style={{ marginTop: "-10px", marginBottom: "-10px", zIndex: "100" }}>

            <a class="btn btn-link dropdown-toggle"
              href="#"
              role="button"
              id="statusFilterDropdown"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              style={{ color: "#677788" }}>
              NOMBRE
            </a>

            <div class="dropdown-menu" aria-labelledby="statusFilterDropdown">
              <form>

                {
                  clients.map((client) => (
                    <div class="dropdown-item" key={client.id}>
                      <input defaultChecked={clientFilter.find((filter) => filter == client.id)}
                        onChange={() => onChangeClient(client.id)}
                        type="checkbox" id={`filter_client_${client.id}`} name={`client_${client.id}`} />
                      <label className="px-2 align-middle" for={`filter_client_${client.id}`}>
                        <h5>{client.name}</h5>
                      </label>
                    </div>
                  ))
                }

              </form>
            </div>

          </div>
        </div>

        <div className="col-md-1 p-2">
          RFC
        </div>

        <div className="col-md-1 p-2">
          ESTADO
        </div>

        <div className="col-md-1 p-2 text-center">
          <div class="dropdown" style={{ marginTop: "-10px", marginBottom: "-10px", zIndex: "100" }}>

            <a class="btn btn-link dropdown-toggle"
              href="#"
              role="button"
              id="statusFilterDropdown"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              style={{ color: "#677788" }}>
              TIPO
            </a>

            <div class="dropdown-menu" aria-labelledby="statusFilterDropdown">
              <form>

                <div class="dropdown-item">
                  <input defaultChecked={typeFilter.find((filter) => filter == "cfdiRequest.type=EMITTED")}
                    onChange={() => onChangeType("cfdiRequest.type=EMITTED")}
                    type="checkbox" id="filter_type_EMITTED" name="type_EMITTED" />
                  <label className="px-2 align-middle" for="filter_type_EMITTED">
                    <h5>Emitidas</h5>
                  </label>
                </div>

                <div class="dropdown-item">
                  <input defaultChecked={typeFilter.find((filter) => filter == "cfdiRequest.type=RECEIVED")}
                    onChange={() => onChangeType("cfdiRequest.type=RECEIVED")}
                    type="checkbox" id="filter_type_RECEIVED" name="type_RECEIVED" />
                  <label className="px-2 align-middle" for="filter_type_RECEIVED">
                    <h5>Recibidas</h5>
                  </label>
                </div>

                <div class="dropdown-item">
                  <input defaultChecked={typeFilter.find((filter) => filter == "cfdiRequest.dataType=CFDI")}
                    onChange={() => onChangeType("cfdiRequest.dataType=CFDI")}
                    type="checkbox" id="filter_dataType_CFDI" name="dataType_CFDI" />
                  <label className="px-2 align-middle" for="filter_dataType_CFDI">
                    <h5>CFDI</h5>
                  </label>
                </div>

                <div class="dropdown-item">
                  <input defaultChecked={typeFilter.find((filter) => filter == "cfdiRequest.dataType=METADATA")}
                    onChange={() => onChangeType("cfdiRequest.dataType=METADATA")}
                    type="checkbox" id="filter_dataType_METADATA" name="dataType_METADATA" />
                  <label className="px-2 align-middle" for="filter_dataType_METADATA">
                    <h5>METADATA</h5>
                  </label>
                </div>

              </form>
            </div>

          </div>
        </div>

        <div className="col-md-2 p-2 text-center">
          <div class="dropdown" style={{ marginTop: "-10px", marginBottom: "-10px", zIndex: "100" }}>

            <a class="btn btn-link dropdown-toggle"
              href="#"
              role="button"
              id="statusFilterDropdown"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              style={{ color: "#677788" }}>
              PERIODO
            </a>

            <div class="dropdown-menu p-2" aria-labelledby="statusFilterDropdown" style={{width: "400px"}}>
              <form>
                <div class="row">
                  <div class="col-md-6 text-center">
                    Fecha inicio
                    <input type="date" name="start_date" class="form-control"
                      onChange={(event) => onChangeDate(event.target.value, "startDate")} />
                  </div>
                  <div class="col-md-6 text-center">
                    Fecha de fin
                    <input type="date" name="end_date" class="form-control"
                      onChange={(event) => onChangeDate(event.target.value, "endDate")} />
                  </div>
                </div>
              </form>
            </div>

          </div>
        </div>

        <div className="col-md-2 p-2">
          FECHA DE PETICIÓN
        </div>

        <div className="col-md-2 p-2">
          ACCIONES
        </div>

      </div>

      {
        reports.map((report) => (
          <div 
            className="row border mx-1" 
            id={`report_${report.id}`}
            key={`report_${report.id}`}
          >

            {
              function(){

                const client = report.cfdiRequest?.client || report.comprobantes[0]?.client || {};
                const generalStatus = report.cfdiRequest?.generalStatus || "MANUAL";
                const codeStatus = report.cfdiRequest?.codeStatus || 200;
                const dataType = report.cfdiRequest?.dataType || "MANUAL";
                const type = report.cfdiRequest?.type || "MANUAL";
                const startDate = report.cfdiRequest?.startDate ? moment(report.cfdiRequest.startDate).format("DD/MM/YYYY") : "MANUAL";
                const endDate = report.cfdiRequest?.endDate ? moment(report.cfdiRequest.endDate).format("DD/MM/YYYY") : "MANUAL";
                const createdAt = report.cfdiRequest?.createdAt ? moment(report.cfdiRequest.createdAt).format("DD/MM/YYYY HH:mm:ss") : "MANUAL";

                let downloadType = report.cfdiRequest?.isAutoDownload || false;
                if(report.cfdiRequest?.isAutoDownload == true) downloadType = "SAT AUTO";
                if(report.cfdiRequest?.isAutoDownload == false) downloadType = "SAT MANUAL";
                if(report.cfdiRequest?.isAutoDownload == null) downloadType = "CW MANUAL";

                return <>

                  <div className="col-md-3 p-2">
                    <span class="d-block font-size-sm">
                      {client.name}
                    </span>
                    {
                      downloadType == "SAT AUTO" &&
                        <span class="badge badge-secondary">SAT AUTO</span>
                    }
                    {
                      downloadType == "SAT MANUAL" &&
                        <span class="badge badge-info">SAT MANUAL</span>
                    }
                    {
                      downloadType == "CW MANUAL" &&
                        <span class="badge badge-warning">CW MANUAL</span>
                    }
                  </div>

                  <div className="col-md-1 p-2">
                    <span class="d-block font-size-sm">{client.rfc}</span>
                  </div>

                  <div className="col-md-1 p-2">
                    <span class="d-block font-size-sm">
                      {function () {
                        if (generalStatus == "REQUEST") return "Solicitado";
                        if (generalStatus == "VERIFICATION") return "En verificación";
                        if (generalStatus == "DOWNLOAD") return "Descargando";
                        if (generalStatus == "FINISHED") return "Finalizado";
                        if (generalStatus == "ERROR") return "Error";
                        if (generalStatus == "REJECTED") return "Rechazado";
                        if (generalStatus == "PARSING") return "Procesando";
                        if (generalStatus == "ERROR_PARSING") return "Error en el proceso";
                        if (generalStatus == "NO_DATA") return "Sin información";
                        if (codeStatus == 404 || codeStatus == 304) return "Error SAT";
                      }()}
                    </span>
                  </div>

                  <div className="col-md-1 p-2">
                    {dataType}
                    <br />
                    <small>
                      {function () {
                        if (type == "EMITTED") return "Emitidas";
                        if (type == "RECEIVED") return "Recibidas";
                      }()}
                    </small>
                  </div>

                  <div className="col-md-2 p-2">
                    <span class="d-block font-size-sm">
                      {startDate} - {endDate}
                    </span>
                  </div>

                  <div className="col-md-2 p-2">
                    <span class="d-block font-size-sm">
                      {createdAt}
                    </span>
                  </div>

                  <div className="col-md-2 p-2">
                    <a 
                      data-toggle="collapse"
                      href={`#details-${report.id}`}
                      role="button"
                      class="btn btn-primary text-center" 
                      style={{ width: "50px" }}
                    >
                      <i class="fas fa-eye"></i>
                    </a>
                    <a
                      class="btn btn-info ml-1 text-center"
                      href="#"
                      style={{ width: "50px" }}
                      onClick={() => setDetailsRequest(report.cfdiRequest?.id)}
                    >
                      <i class="fas fa-info"></i>
                    </a>
                  </div>

                </>

              }()
            }

            <div className="col-md-12 mb-3">
              <div id={`details-${report.id}`} class="collapse">
                <div class="pl-3">
                  {
                    function(){

                      const newComprobantes = Object.assign([], report.comprobantes).sort((a, b) => new Date(a.fecha) - new Date(b.fecha));
                      const groupedComprobantes = newComprobantes.reduce((acc, comprobante) => {
                        const date = moment(comprobante.fecha).format("DD/MM/YYYY");
                        if (!acc[date]) acc[date] = [];
                        acc[date].push(comprobante);
                        return acc;
                      }, {});

                      return Object.keys(groupedComprobantes).map((date, index) => (
                        <React.Fragment key={`date_${date}_${index}`}>
                          <div className="d-flex w-100 bg-light border text-center justify-content-center">
                            <span className="font-weight-bold text-center" style={{ width: "100px" }}>
                              {date}
                            </span>
                            <span className="text-info text-center mx-1">
                              ({groupedComprobantes[date].length} comprobantes) 
                            </span>
                            <span className="text-danger text-center mx-1">
                              {
                                groupedComprobantes[date].reduce((acc, comprobante) => {
                                  if (comprobante.biEmitidoRecibido == "EMITIDO") return acc + 1;
                                  return acc;
                                }, 0)
                              } emitidos
                            </span>
                            <span className="text-success text-center mx-1">
                              {
                                groupedComprobantes[date].reduce((acc, comprobante) => {
                                  if (comprobante.biEmitidoRecibido == "RECIBIDO") return acc + 1;
                                  return acc;
                                }, 0)
                              } recibidos
                            </span>
                            <a
                              data-toggle="collapse"
                              href={`#details-${report.id}-${date.replace(/\//g, "_")}`}
                              role="button"
                              class="mx-3" 
                            >
                              Ver detalles
                            </a>
                          </div>
                          <div id={`details-${report.id}-${date.replace(/\//g, "_")}`} class="collapse">
                            <table className="table table-sm table-bordered">
                              <thead>
                                <tr>
                                  <th>UUID</th>
                                  <th>Folio</th>
                                  <th>Fecha</th>
                                  <th>Emisor</th>
                                  <th>Receptor</th>
                                  <th>Tipo</th>
                                  <th>E/R</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  groupedComprobantes[date].map((comprobante, index) => (
                                    <tr key={`comprobante_${index}`}>
                                      <td>{comprobante.tfdUuid}</td>
                                      <td>{comprobante.folio}</td>
                                      <td>{moment(comprobante.fecha).format("DD/MM/YYYY")}</td>
                                      <td>{comprobante.emisorUid}</td>
                                      <td>{comprobante.receptorUid}</td>
                                      <td>{comprobante.biTipoComprobante}</td>
                                      <td>{comprobante.biEmitidoRecibido}</td>
                                    </tr>
                                  ))
                                }
                              </tbody>
                            </table>
                          </div>
                        </React.Fragment>
                      ))

                    }()
                  }
                  <span>
                    {report.comprobantes.length + " comprobantes"}
                  </span>
                    <div className="col-md-12 text-center mb-3">
                      <button 
                        class="btn btn-primary" 
                        onClick={() => document.getElementById(`details-${report.id}`).classList.remove("show")}
                      >
                        <i class="fas fa-eye-slash"></i> Ocultar detalles
                      </button>
                    </div>
                </div>
              </div>
            </div>

          </div>
        ))
      }

    </div>

    <Footer
      objectsInPage={reports.length}
      pageNum={pageNum}
      totalPages={totalPages}
      totalCount={totalCount}
      onClickPage={onClickPage}
    />

    {
      detailsRequest &&
        <Modal
          height="700"
          title="Detalles de la solicitud"
          onClose={() => setDetailsRequest(null)}
        >
          <CFDIsDetails 
            requestId={detailsRequest}
          />
        </Modal>
    }

    {
      isExportShown &&
        <Modal
          title="Exportar reportes"
          width="700"
          onClose={() => setIsExportShown(false)}
        >
          <div className="bg-white rounded p-3">
            <h3 className="mb-3">Generar reporte</h3>
            <Formik
              initialValues={{
                auto_type: "BOTH",
                content_type: "BOTH",
                download_type: "BOTH",
                client_ids: [],
              }}
              onSubmit={onExport}>
              {({ values, setFieldValue }) =>
                <Form>
                  <div class="mb-3">

                    {/* Clients */}
                    <div
                      className="form-group"
                    >
                      <label class="input-label">Clientes</label>
                      {
                        clients.map((client, index) => (
                          <div className="d-flex align-items-start mt-1" key={client.id}>
                            <input
                              type="checkbox"
                              className="m-0 p-0 mr-2"
                              // checked={clientFilter.find((filter) => filter == client.id)}
                              onChange={(e) => {
                                if(e.target.checked) setFieldValue("client_ids", [...values.client_ids??[], client.id]);
                                else setFieldValue("client_ids", values.client_ids?.filter((id) => id != client.id));
                              }}
                            />
                            <label className="">
                              <h5 className="m-0 p-0">{client.name} <small>({client.rfc})</small> </h5>
                            </label>
                          </div>
                        ))
                      }
                    </div>

                    {/* Report Type */}
                    <div class="form-group">
                      <label class="input-label">Tipo de reporte</label>
                      <Field
                        as="select"
                        class="form-control js-select2-custom js-datatable-filter custom-select" 
                        name="report_type"
                        required
                      >
                        <option value={-1}>Seleccione un tipo</option>
                        <option value="REQUESTS">Comprobantes por solicitud</option>
                        <option value="DAY">Comprobantes por día</option>
                        <option value="MISSING_CFDIS">CFDIs faltantes</option>
                        <option value="ERROR_REQUESTS">Solicitudes con error</option>
                        <option value="COMPROBANTES">Comprobantes</option>
                      </Field>
                    </div>

                    {/* Auto type */}
                    <div class="form-group">
                      <label class="input-label">Tipo</label>
                      <Field
                        as="select"
                        class="form-control js-select2-custom js-datatable-filter custom-select" 
                        name="auto_type"
                        required
                      >
                        <option value={-1}>Seleccione un tipo</option>
                        <option value="MANUAL">Manual</option>
                        <option value="AUTO">Automática</option>
                        <option value="BOTH">Ambas</option>
                      </Field>
                    </div>

                    {/* Data type */}
                    <div className="form-group">
                      <label class="input-label">Tipo de solicitud</label>
                      <Field 
                        as="select" 
                        name="content_type" 
                        class="form-control" 
                        required
                      >
                        <option value="">Selecione una opción</option>
                        <option value="CFDI">CFDI</option>
                        <option value="METADATA">Metadata</option>
                        <option value="BOTH">Ambas</option>
                      </Field>
                    </div>

                    <div className="form-group">
                      <label class="input-label">Emitidos/Recibidos</label>
                      <Field 
                        as="select" 
                        name="download_type" 
                        class="form-control" 
                        required
                      >
                        <option value="">Selecione una opción</option>
                        <option value="RECEIVED">Recibidos</option>
                        <option value="EMITTED">Emitidos</option>
                        <option value="BOTH">Ambos</option>
                      </Field>
                    </div>

                    {/* Start date */}
                    <div class="form-group">
                      <label class="input-label">Fecha inicial</label>
                      <Field type="date" name="start_date" class="form-control" required />
                    </div>

                    {/* End date */}
                    <div class="form-group">
                      <label class="input-label">Fecha final</label>
                      <Field type="date" name="end_date" class="form-control" required />
                    </div>

                  </div>

                  <button type="submit" class="btn btn-block btn-primary">
                    {
                      isReportLoading 
                        ? <i class="fas fa-spinner fa-spin"></i>
                        : "Descargar"
                    }
                  </button>

                </Form>}
            </Formik>
          </div>
        </Modal>
    }

    {
      isRetryShown &&
        <Modal
          title="Reintentar descarga"
          onClose={() => setIsRetryShown(false)}
        >
          <div className="bg-white rounded p-3">
            <h3 className="mb-3">Reintentar descargas</h3>
            <Formik
              initialValues={{}}
              onSubmit={onFix}>
              {({ values, setFieldValue }) =>
                <Form>
                  <div class="mb-3">

                    {/* Start date */}
                    <div class="form-group">
                      <label class="input-label">Fecha inicial</label>
                      <Field type="date" name="start_date" class="form-control" required />
                    </div>

                    {/* End date */}
                    <div class="form-group">
                      <label class="input-label">Fecha final</label>
                      <Field type="date" name="end_date" class="form-control" required />
                    </div>

                  </div>

                  <button type="submit" class="btn btn-block btn-primary">
                    Reintentar
                  </button>

                </Form>}
            </Formik>
          </div>
        </Modal>
    }

  </div>
);


export default ReportsView;
