import React from "react";
import PropTypes from "prop-types";
import { Formik, Field, Form } from "formik";
import { FileField, MultiField } from "seed/helpers";

const FielForm = ({ onSubmit, error }) =>
  <div class="card">

    {/* Header */}
    <div class="card-header">
      <h3 class="card-header-title">Configurar FIEL</h3>
    </div>

    {/* Body */}
    <div class="card-body">
      <div class="row">
        <div class="col">
          <Formik
            initialValues={{}}
            onSubmit={onSubmit}>
            {({ values, setFieldValue }) =>
              <Form>
                <div class="mb-3">
                  {/* Fiel cer */}
                  <div class="form-group">
                    <label class="input-label">Certificado </label>
                    <FileField name="fiel_cer" setFieldValue={setFieldValue} required/>
                  </div>
                  {/* Fiel key */}
                  <div class="form-group">
                    <label class="input-label">Llave </label>
                    <FileField name="fiel_key" setFieldValue={setFieldValue} required/>
                  </div>
                  {/* Fiel pass */}
                  <div class="form-group">
                    <label class="input-label">Contraseña</label>
                    <Field type="password" name="fiel_pass" class="form-control" required/>
                  </div>
                </div>
                {error ? <div class="alert alert-soft-danger">{error}</div> : null}
                <button type="submit" class="btn btn-block btn-primary">Guardar</button>
              </Form>}
          </Formik>
        </div>
      </div>
    </div>

  </div>;

FielForm.propTypes = {
  client: PropTypes.object,
  users: PropTypes.array,
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.string
};

export default FielForm;