import React from "react";
import PropTypes from "prop-types";
import { Formik, Field, Form } from "formik";
import FileField from "components/helpers/FileField";

const FormV = ({ clients, onSubmit, reqLoading, error, setTotalFiles, 
  setUploadedFiles, totalFiles, uploadedFiles }) => (
  <div class="card">
    <div class="row justify-content-center mx-3 my-5">
      <div class="col">
        <div class="row">
          <div class="col h1">Cargar datos</div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <Formik initialValues={{}} onSubmit={onSubmit}>
              {({ values, setFieldValue }) => (
                <Form>
                  <label class="input-label h4 text-left">
                    Selecciona un cliente
                  </label>
                  <Field
                    component="select"
                    name="client_id"
                    class="form-control"
                    required
                  >
                    <option value="">Selecciona una opción</option>
                    {clients.map((e, idx) => (
                      <option key={idx} value={e.id}>
                        {e.name}, {e.rfc}
                      </option>
                    ))}
                  </Field>

                  <label class="input-label h4 textleft mt-3">Tipo de datos</label>
                  <Field as="select" name="datatype" class="form-control" required>
                    <option value="">Selecione una opción</option>
                    <option value="METADATA">Metadata</option>
                    <option value="CFDI">CFDI</option>
                  </Field>

                  <label class="input-label h4 text-left mt-4">
                    Selecciona los archivos a subir <br/><small>(xmls para cfdis o txts para metadata)</small>
                  </label>

                  <label class="py-1 px-2 mt-1 mb-3 w-100" style={{background:"#f5f5f5"}}>
                    <i style={{fontSize: "0.8rem"}}>Para mayor velocidad de carga, subir los archivos en un zip</i>
                  </label>

                  <FileField
                    name={`xml_files`}
                    accept={values.datatype == "METADATA" ? ".txt" : values.datatype == "CFDI" ? ".xml,.zip" : ".null"}
                    multiple={true}
                    setFieldValue={setFieldValue}
                    setTotalFiles={setTotalFiles}
                    setUploadedFiles={setUploadedFiles}
                    required
                  />

                  {totalFiles > 0 && 
                    <div class="row mt-4">
                      <div class="col text-center">
                        <div class="progress">
                          <div 
                            style={{width: `${(uploadedFiles/totalFiles)*100}%`}}
                            class="progress-bar" 
                            role="progressbar" 
                            aria-label="Basic example" 
                            aria-valuenow="50" 
                            aria-valuemin="0" 
                            aria-valuemax="100"/>
                        </div>
                        Subido{totalFiles != 1 ? "s" : null} {uploadedFiles} de {totalFiles}
                      </div>
                    </div>
                  }

                  { error != null
                    ? <div class="alert alert-soft-danger">
                      {error}
                    </div>
                  : null }

                  <button type="submit" class="btn btn-block btn-primary mt-3" disabled={uploadedFiles != totalFiles}>
                    { reqLoading && 
                      <>
                        <span 
                          className="spinner-border spinner-border-sm" 
                          role="status" 
                          aria-hidden="true" />
                        <span className="ml-2">
                          Cargando...
                        </span>
                      </>
                    }
                    { !reqLoading && 
                      <span>Subir datos</span>
                    }
                  </button>

                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  </div>
);

FormV.propTypes = {
  clients: PropTypes.array.isRequired,
  onSubmit: PropTypes.func,
  reqLoading: PropTypes.bool,
  error: PropTypes.string,
  setTotalFiles: PropTypes.func,
  setUploadedFiles: PropTypes.func,
  totalFiles: PropTypes.number,
  uploadedFiles: PropTypes.number
};

export default FormV;
