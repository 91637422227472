import React from "react";
import PropTypes from "prop-types";
import { BrowserRouter, Route } from "react-router-dom";
import { Link, NavLink } from "react-router-dom";
import LoadList from "components/load/List";

const Load = ({ clients, clientsFilter, onChangeCheckbox }) => (
  <BrowserRouter basename="home/load">
    <div class="card mb-3 mb-lg-5 px-3 py-2">
      <div class="card-header card-header-content-sm-between">
        <h3 class="card-header-title mb-2 mb-sm-0">Carga manual</h3>
        <Link to="/create" className="btn btn-primary">
          <i class="tio-file-add mr-2"></i>Cargar datos
        </Link>
      </div>

      <div class="card-body">

        <div className="row border font-weight-bold mx-1">

          <div className="col-md-1 p-2">
            ID
          </div>

          <div className="col-md-2 p-2">
            Fecha
          </div>

          <div className="col-md-3 p-2 text-center">
            <div class="dropdown" style={{ marginTop: "-10px", marginBottom: "-10px", zIndex: "100" }}>

              <a class="btn btn-link dropdown-toggle"
                href="#"
                role="button"
                id="statusFilterDropdown"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{ color: "#677788" }}>
                Cliente
              </a>

              <div class="dropdown-menu" aria-labelledby="statusFilterDropdown">
                <form>

                  {
                    clients.map((client) => (
                      <div class="dropdown-item" key={client.id}>
                        <input defaultChecked={clientsFilter.find((filter) => filter == client.id)}
                          onChange={() => onChangeCheckbox(client.id)}
                          type="checkbox" id={`filter_client_${client.id}`} name={`client_${client.id}`} />
                        <label className="px-2 align-middle" for={`filter_client_${client.id}`}>
                          <h5>{client.name}</h5>
                        </label>
                      </div>
                    ))
                  }

                </form>
              </div>

            </div>
          </div>

          <div className="col-md-2 p-2">
            Estado
          </div>

          <div className="col-md-3 p-2">
            Descripción
          </div>

          <div className="col-md-1 p-2">
            Acciones
          </div>

        </div>

        <LoadList clientsFilter={clientsFilter} />
      </div>

    </div>
  </BrowserRouter>
);

Load.propTypes = {
  clients: PropTypes.array.isRequired,
  clientsFilter: PropTypes.array.isRequired,
  onChangeCheckbox: PropTypes.func.isRequired
};

export default Load;
