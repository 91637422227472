import React from "react";
import PropTypes from "prop-types";
import { useDelete, useSet, useQuery, useDetail } from "seed/gql";
import { DELETE_CLIENT } from "seed/gql/queries";
import { CLIENT, SET_CLIENT } from "seed/gql/queries";
import { Loading } from "seed/helpers";
import View from "components/clients/Form.view";
import { useState } from "react";

function ClientFormSet({ clientId, onCompleted = () => null, onError = () => null  }) {

  const [validationError, setValidationError] = useState(null);

  const qClient = useDetail(`{
    client {
      name
      rfc
      dailyDownload
    }
  }`, clientId);
  const qUsers = useQuery(`{ users { } }`);
  const qClients = useQuery(`{ clients { rfc } }`);
  const [callSet, qSet] = useSet(SET_CLIENT, {
    onCompleted: () =>
      onCompleted()
  });

  const [callDelete] = useDelete(DELETE_CLIENT, {
    onCompleted: () =>
      window.location = "/home/clients"
  });

  console.log(qClient)

  if(qClient.loading) return <Loading />;
  if(qClient.error && qClient.error.message.includes("matching query does not exist")) 
    window.location.href = "/home/clients";

  const { client = {} } = qClient.data;
  const { users = [] } = qUsers.data;
  const { clients = [] } = qClients.data;

  let error = qSet.error ? "Ha ocurrido un error" : null;
  if(validationError) error = validationError;

  const onSubmit = (values) => {

    const rfcExists = clients.find((client) => client.rfc == values.rfc);

    if(rfcExists && rfcExists.id != clientId) {
      setValidationError("Ya existe un cliente con ese RFC");
    }
    else {
      setValidationError(null);
      values.id = parseInt(clientId);
      callSet(values);
    }

  };

  const onClickDelete = () => {
    const deleteConfirm = window.confirm("¿Estás seguro de eliminar al cliente\nTODA LA INFORMACIÓN SERÁ BORRADA");
    if (deleteConfirm == true)
      callDelete({ id: parseInt(clientId) });
  }

  return <View
    client={client}
    users={users}
    error={error}
    onClickDelete={onClickDelete}
    onSubmit={onSubmit}
  />;
}

ClientFormSet.propTypes = {
  clientId: PropTypes.number.isRequired,
  onCompleted: PropTypes.func,
  onError: PropTypes.func
};

export default ClientFormSet;