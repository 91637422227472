import React from "react";
import PropTypes from "prop-types";
import { useQuery } from "seed/gql";
import { usePost } from "seed/api";
import View from "components/clients/Form.view";

function ClientFormSave({ onCompleted = () => null, onError = () => null }) {
  const userId = sessionStorage.getItem("id");
  const qUsers = useQuery(`{ users { } }`);

  const [callSave, qSave] = usePost("/clients/create_client", {
    onCompleted: () => window.location = "/home/clients",
      //Note: When the component is wrap in a ModalRoute it bind the event 'closeModal()'
  });

  const { users = [] } = qUsers.data;

  let error = null;
  if(qSave.error && qSave.error.status == 420) error = "Ya existe un cliente con ese RFC";
  else if(qSave.error) error = "Ha ocurrido un error";

  const onSubmit = (values) => {
    values.admin = parseInt(userId);
    callSave(values);
  }

  return <View
    users={users}
    error={error}
    onSubmit={onSubmit}
  />;
}

ClientFormSave.propTypes = {
  onCompleted: PropTypes.func,
  onError: PropTypes.func
};

export default ClientFormSave;