import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import View from "components/dev/Downloads.view";
import { usePagination, useQuery } from "seed/gql";
import { Loading } from "seed/helpers";
import { usePost, useGetCall } from "seed/api";
import { getDownloadFilters } from "components/utils/filtersUtil";

function Downloads() {

  const userId = sessionStorage.getItem("id");
  const userType = sessionStorage.getItem("user");
  const pageSize = 30;
  const [pageNum, setPageNum] = useState(1);

  const [search, setSearch] = useState("")
  const [typeFilter, setTypeFilter] = useState([])
  const [clientFilter, setClientFilter] = useState([]);
  const [dateFilter, setDateFilter] = useState({ startDate: null, endDate: null });
  const [openAccordion, setOpenAccordion] = useState(null);
  const [detailsRequest, setDetailsRequest] = useState(null);

  let filterString = getDownloadFilters({ clientFilter, typeFilter, dateFilter });
  filterString += (filterString.length > 0 ? " AND " : "") 
    + "(general_status<>FINISHED AND general_status<>PARSING AND general_status<>ERROR_PARSING)";

  if(search != "")
    filterString = "id = " + search;

  const qCFDIsRequests = usePagination(`{
    cfdiRequestPagination {
      totalPages
      totalCount
      cfdiRequests {
        createdAt
        type
        startDate
        endDate
        dataType
        generalStatus
        codeStatus
        isAutoDownload
        metadata
        rawDownload {
          name
          url
        }
        client {
          name
          rfc
        }
      }
    }
  }`, pageNum, pageSize, `(${filterString})`, { orderBy: "-created_at"});

  const reqClients = useQuery(`{
    clients {
      name
      rfc
    }
  }`, {}, {orderBy: "name"});

  const [callGetExport, reqGetExport] = useGetCall(`/cfdi_requests/export`, {}, {
    onCompleted: (data) => {
      
      let csvContent = "data:text/csv;charset=utf-8,";
      let content = data.map((row) => Object.values(row).join(","));      
      csvContent += content.join("\n");

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "cfdi_requests.csv");
      document.body.appendChild(link);
      link.click();

    }
  });

  const [callRetry, reqRetry] = usePost("/cfdi_requests/retry_request", {
    onCompleted: (data) => {
      alert("Solicitud reintentada con éxito");
      qCFDIsRequests.refetch();
    },
    onError: (error) => {
      alert("Error al reintentar la descarga");
    }
  });

  const [callVerify, reqVerify] = usePost("/cfdi_requests/verify_request", {
    onCompleted: (data) => {
      alert("Verificación realizada con éxito");
      qCFDIsRequests.refetch();
    },
    onError: (error) => {
      alert("Error al verificar la petición");
    }
  });

  const onChangeClient = (id) => {
    const newFilters = [...clientFilter];
    const index = newFilters.findIndex((filter) => filter == id);

    if (index == -1) {
      newFilters.push(id);
    } else {
      newFilters.splice(index, 1);
    }

    setClientFilter(newFilters)
  }

  const onChangeType = (status) => {
    const newFilters = [...typeFilter];
    const index = newFilters.findIndex((filter) => filter == status);

    if (index == -1) {
      newFilters.push(status);
    } else {
      newFilters.splice(index, 1);
    }

    setTypeFilter(newFilters)
  }

  const onChangeDate = (date, type) => {
    const newDateFilter = {...dateFilter};
    if (date == "") newDateFilter[type] = null;
    else newDateFilter[type] = date;
    setDateFilter(newDateFilter);
  }

  const onExport = () => 
    callGetExport();

  const onRetry = (requestId) => {
    callRetry({ request_id: requestId });
    setOpenAccordion(requestId);
  }

  const onVerify = (requestId) => {
    callVerify({ request_id: requestId });
    setOpenAccordion(requestId);
  }

  const { clients = [] } = reqClients.data;

  const {
    cfdiRequests = [],
    totalPages = 0,
    totalCount = 0,
  } = qCFDIsRequests?.data?.cfdiRequestPagination || {};

  return <View 
    openAccordion={openAccordion}
    clients={clients}
    requests={cfdiRequests}
    pageNum={pageNum}
    totalPages={totalPages}
    totalCount={totalCount}
    clientFilter={clientFilter}
    typeFilter={typeFilter}
    dateFilter={dateFilter}
    detailsRequest={detailsRequest}
    search={search}
    onChangeClient={onChangeClient}
    onChangeType={onChangeType}
    onChangeDate={onChangeDate}    
    onClickPage={setPageNum}
    onExport={onExport}
    onRetry={onRetry}
    onVerify={onVerify}
    setOpenAccordion={setOpenAccordion}
    setDetailsRequest={setDetailsRequest}
    setSearch={setSearch}
  />;
}

Downloads.propTypes = {};

export default Downloads;