import React, { useState } from "react";
import { usePagination } from "seed/gql";
import { Loading } from "seed/helpers";
import View from "components/clients/List.view";
import { deleteDuplicates } from "components/utils/filtersUtil";

function ClientList() {
  const userId = sessionStorage.getItem("id");
  const userType = sessionStorage.getItem("user");
  const isAdmin = userType == "SUPER_ADMIN" || userType == "ADMIN"
  const pageSize = 15;
  const [pageNum, setPageNum] = useState(1);

  let query = "name <> DEFAULT ";
  if (userType != "SUPER_ADMIN") query += `AND client_users.user.id = ${userId}`;

  const reqClients = usePagination(`{
    clientPagination {
      totalPages
      totalCount
      clients {
        fielKey {}
        name
        rfc
        dailyDownload
        createdAt
      }
    }
  }`, pageNum, pageSize, query, {
    orderBy: "name"
  });

  if (reqClients.loading) return <Loading />;
  if (reqClients.error) return "Error";
  const {
    clients = [],
    totalPages = 0,
    totalCount = 0,
  } = reqClients.data.clientPagination;

  const onClickPage = (pageNum) => setPageNum(pageNum);

  return (
    <View
      clients={clients}
      pageNum={pageNum}
      totalPages={totalPages}
      totalCount={totalCount}
      onClickPage={onClickPage}
      reqClients={reqClients}
      isAdmin={isAdmin}
    />
  );
}

ClientList.propTypes = {};

export default ClientList;
