import React, { useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "seed/gql";
import { Loading } from "seed/helpers";
import View from "components/download/Form.view";
import { usePost } from "seed/api";
import { deleteDuplicates } from "components/utils/filtersUtil";

function CFDIsForm({ onCompleted = () => null, onError = () => null, qCFDIsRequests }) {

  const userId = sessionStorage.getItem("id");
  const userType = sessionStorage.getItem("user");

  const [client, setClient] = useState(null);
  const [contentyType, setContentType] = useState(null);
  const [downloadType, setDownloadType] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [validationError, setValidationError] = useState(null);

  let query = "name <> DEFAULT ";
  if (userType != "SUPER_ADMIN") query = `client_users.user.id = ${userId}`;

  const qCfdiRequest = useQuery(`{
    cfdiRequests {
      type
      startDate
      endDate
      client {
        id
      }
    }
  }`, `((client.id=${client}) AND (dataType=${contentyType}) AND (startDate=${startDate}) AND (endDate=${endDate}))`);

  const qClients = useQuery(`{
    clients {
      id
      name
      rfc
    }
  }`, query);

  const [callSaveRequest, qSaveRequest] = usePost("/cfdi_requests/create_cfdi_request", {
    onCompleted: () => {
      qCFDIsRequests.refetch();
      onCompleted();
    }
  });

  if (qClients.loading) return <Loading />;
  if (qClients.error) return <div>Error</div>;

  const clients = qClients.data.clients
  const { cfdiRequests = [] } = qCfdiRequest.data;
  const cfdiRequestsType = cfdiRequests.map((cfdiRequest) => cfdiRequest.type == contentyType);

  let loading = qSaveRequest.loading;
  let error = qSaveRequest.error ? "Ha ocurrido un error, verifique si está configurada la FIEL de este cliente" : null;
  if (validationError) error = validationError;

  const onSubmit = (values) => {

    if (!client) {
      setValidationError("Debe seleccionar un cliente");
      return;
    }
    else if (!contentyType) {
      setValidationError("Debe seleccionar un tipo de solicitud");
      return;
    }
    else if (!downloadType) {
      setValidationError("Debe seleccionar si se trata de archivos emitidos o recibidos");
      return;
    }
    else if (!startDate) {
      setValidationError("Debe seleccionar una fecha de inicio");
      return;
    }
    else if (!endDate) {
      setValidationError("Debe seleccionar una fecha de fin");
      return;
    }

    const auxStartDate = new Date(startDate);
    const auxEndDate = new Date(endDate);

    if (auxStartDate > auxEndDate) {
      setValidationError("La fecha de inicio debe ser menor a la fecha de fin");
      return;
    }

    const differenceInMilliseconds = Math.abs(auxEndDate - auxStartDate);
    const millisecondsPerYear = 1000 * 60 * 60 * 24 * 365.25;
    const years = differenceInMilliseconds / millisecondsPerYear;

    if (years > 1) {
      setValidationError("La solicitud de descarga es de un periodo mayor de un año");
      return;
    }

    callSaveRequest({
      client: client,
      content_type: contentyType,
      download_type: downloadType,
      start_date: startDate.split("T")[0],
      end_date: endDate.split("T")[0]
    });

  }

  const showWarning = () => {

    if (client == null || client == -1) return false;
    if (contentyType == null || contentyType == "") return false;
    if (downloadType == null || contentyType == "") return false;
    if (startDate == null || startDate == "") return false;
    if (endDate == null || endDate == "") return false;

    if (contentyType == "METADATA") return false;

    return true;

  }

  return <View
    error={error}
    loading={loading}
    clients={clients}
    qSaveRequest={qSaveRequest}
    onSubmit={onSubmit}
    setClient={setClient}
    setContentType={setContentType}
    setStartDate={setStartDate}
    setDownloadType={setDownloadType}
    setEndDate={setEndDate}
    showWarning={showWarning()}
    otherRequests={cfdiRequestsType.length}
  />;
}

CFDIsForm.propTypes = {
  onCompleted: PropTypes.func,
  onError: PropTypes.func,
  qCFDIsRequests: PropTypes.object
};

export default CFDIsForm;