import React from "react";
import PropTypes from "prop-types";
import View from "components/clients/filters/List.view";
import { DELETE_FILTER } from "seed/gql/queries";
import { useQuery, useDelete } from "seed/gql";
import { Loading} from "seed/helpers";


function ListFilter(props) {

    const clientID = props.match.params.clientId;

    const reqFilters = useQuery(
        `{
            filters {
                id
                name
                type
            }
        }`,
        "client_id = " + clientID
    );

    const [callDelete, reqDelete] = useDelete(DELETE_FILTER, {
        onCompleted: () => {
          reqFilters.refetch();
        }
      })

    if (reqFilters.loading) return <Loading />;
    if (reqFilters.error) return "Error";

    const { filters = [] } = reqFilters.data;

    const onClickDelete = (id) => {
        if (window.confirm("¿Desea eliminar el filtro?") == true)
            callDelete({ id: id });
    };

    return <View 
            filters={filters} 
            onClickDelete={onClickDelete} 
            clientID={clientID}
            />;

}

ListFilter.propTypes = {};

export default ListFilter;