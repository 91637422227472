import React from "react";
import PropTypes from "prop-types";
import { PaginationFooter } from "seed/helpers";

const Footer = ({
  objectsInPage = 0,
  pageNum = 1,
  totalPages = 0,
  totalCount = 0,
  onClickPage = () => {},
}) => (
  <div>
    <div class="card-footer">
      <div class="row justify-content-center justify-content-between align-items-sm-center">
        <div class="col-sm mb-2 mb-sm-0">
        </div>
        <div class="col-sm-auto" style={{overflow: "auto"}}>
          <PaginationFooter
            pageNum={pageNum}
            totalPages={totalPages}
            onClickPage={onClickPage}
          />
        </div>
      </div>
    </div>
  </div>
);

Footer.propTypes = {
  objectsInPage: PropTypes.number,
  pageNum: PropTypes.number,
  totalPages: PropTypes.number,
  totalCount: PropTypes.number,
  onClickPage: PropTypes.func,
};

export default Footer;
