import React, { useState } from "react";
import PropTypes from "prop-types";
import View from "components/clients/accounts/Form.view";
import { usePost } from "seed/api";
import { useHistory } from "react-router-dom";

function FormSave({ clientId, reqUsers }) {

  const history = useHistory();
  const [error, setError] = useState(null);

  const [saveClientUser, reqClientUser] = usePost("/client_users/accounts", {
    onCompleted: () => { 
      reqUsers.refetch();
      history.goBack();
    },
    onError: (data) => {
      switch (data.status) {
        case 401:
          setError("Correo ya registrado, por favor registre otro");
          break;
        
        default:
          setError("Ha ocurrido un error, por favor intente nuevamente");
          break;
      }
    }
  })

  const onSubmit = (values) => {
    const data = {
      first_name: values["firstName"],
      last_name: values["lastName"],
      email: values["email"],
      password: values["password"],
      user_type: values["userType"],
      client_id: clientId
    };
    saveClientUser(data);
  }

  return <View
    error={error}
    onSubmit={onSubmit}
  />;


}

FormSave.propTypes = {
  clientId: PropTypes.number.isRequired,
  reqUsers: PropTypes.object.isRequired,
  onCompleted: PropTypes.func
};

export default FormSave;