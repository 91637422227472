import React from "react";
import PropTypes from "prop-types";
import { Formik, Field, Form } from "formik";

const ClientForm = ({ client = {}, users = [], onSubmit, error, onClickDelete }) => (
  <div class="card">
    {/* Header */}
    <div class="card-header">
      <h3 class="card-header-title">Cliente</h3>
    </div>

    {/* Body */}
    <div class="card-body">
      <div class="row">
        <div class="col">
          <Formik initialValues={client} onSubmit={onSubmit}>
            {({ values, setFieldValue }) => (
              <Form>
                <div class="mb-3">
                  {/* Name */}
                  <div class="form-group">
                    <label class="input-label">Nombre</label>
                    <Field type="text" name="name" class="form-control" required/>
                  </div>
                  {/* Rfc */}
                  <div class="form-group">
                    <label class="input-label">Rfc</label>
                    <Field type="text" name="rfc" class="form-control" required/>
                  </div>
                  {/* Daily Download */}
                  <div class="form-group">
                    <div className="row">
                      <div className="col-md-12 d-flex justify-content-start">
                        <label class="input-label">Descargas automáticas</label>
                        <Field type="checkbox" name="dailyDownload" class="my-1 mx-3"/>
                      </div>
                    </div>
                  </div>
                  {/* Admin */}
                  <div class="form-group">
                    <div></div>
                  </div>
                </div>
                {error ? (
                  <div class="alert alert-soft-danger">{error}</div>
                ) : null}
                <button type="submit" class="btn btn-block btn-primary">
                  Enviar
                </button>
              </Form>
            )}
          </Formik>

          {
            onClickDelete 
              ? <button class="w-100 mt-2 btn btn-block btn-danger" onClick={onClickDelete}>
                 Eliminar cliente
                </button> 
              : null
          }
        </div>
      </div>
    </div>
  </div>
);

ClientForm.propTypes = {
  client: PropTypes.object,
  users: PropTypes.array,
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.string,
  onClickDelete: PropTypes.func
};

export default ClientForm;
