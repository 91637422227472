import React, { useEffect, useState } from "react";
import { useGetCall } from "seed/api";
import View from "components/Home.view";

function Home() {
   const [isAuth, setIsAuth] = useState(false)
   const [callAuth, reqCall] = useGetCall("/auth/user", "", {
    onCompleted: (data) => setIsAuth(true),
    onError: () => {
      sessionStorage.clear();
      localStorage.clear();
      window.location.replace("/login");
    }
  })
   useEffect(() => {    
     if (localStorage.getItem("id") != null) { //Preload data from localStorage
       sessionStorage.setItem("token", localStorage.getItem("token"));
       sessionStorage.setItem("id", localStorage.getItem("id"));
       sessionStorage.setItem("user", localStorage.getItem("user"));
     }
     callAuth();
   }, []); // eslint-disable-line react-hooks/exhaustive-deps
  if (!isAuth) return null;
  return <View />;
}

Home.propTypes = {};

export default Home;