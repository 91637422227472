import React, { useState } from "react";
import PropTypes from "prop-types";
import View from "components/clients/accounts/List.view";
import { usePagination, useDelete } from "seed/gql";
import { DELETE_CLIENT_USER } from "seed/gql/queries";
import { Loading } from "seed/helpers";

function List({ clientId }) {

  const pageSize = 15;
  const [pageNum, setPageNum] = useState(1);

  const [callDelete, reqDelete] = useDelete(DELETE_CLIENT_USER, {
    onCompleted: () => {
      reqUsers.refetch();
    }
  })

  const reqUsers = usePagination(`{
    clientUserPagination {
      totalPages
      totalCount
      clientUsers {
        id
        type
        user {
          id
          username
          firstName
          lastName
          email
          isActive
          createdAt
        }
      }
    }
  }`, pageNum, pageSize, `client.id=${clientId}`);

  if (reqUsers.loading) return <Loading />;
  if (reqUsers.error) return "Error";
  const { clientUsers = [], totalPages = 0 } = reqUsers.data.clientUserPagination;

  const onClickPage = (pageNum) => setPageNum(pageNum);
  const onClickDelete = (id) => {
    if (window.confirm("¿Desea eliminar el usuario?") == true)
      callDelete({ id: id });
  }

  const getType = (type) => {
    if (type == "ADMIN") return "Adminsitrador";
    if (type == "CLIENT") return "Cliente";
    if (type == "CLIENT_NO_NOMINA") return "Cliente sin nómina";
    if (type == "CLIENT_NO_PAGO") return "Cliente sin pagos";
    if (type == "CLIENT_NO_NOMINA_PAGO") return "Cliente sin nómina ni pagos";
  }

  return <View
    reqUsers={reqUsers}
    clientId={clientId}
    clientUsers={clientUsers}
    pageNum={pageNum}
    totalPages={totalPages}
    onClickPage={onClickPage}
    onClickDelete={onClickDelete}
    getType={getType}
  />;
}

List.propTypes = {
  clientId: PropTypes.number.isRequired,
};

export default List;