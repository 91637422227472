import React from "react";
import PropTypes from "prop-types";
import { Formik, Field, Form } from "formik";
import { Typeahead } from "react-bootstrap-typeahead";
import { FileField } from "seed/helpers";

const FormView = ({
  onSubmit,
  selectedType,
  handleTypeChange,
  onDownloadExcel
}) => {
  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-header-title">Crear Filtro</h3>
      </div>
      <div className="card-body">
        <Formik
          initialValues={{ name: "", type: "UUID", uuids: [], folios: [] }}
          onSubmit={onSubmit}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <div className="mb-3">
                <div className="col-md-12">
                  <Field as="text" className="form-group">
                    <label>Nombre del filtro</label>
                    <input type="text" name="name" className="form-control" required />
                  </Field>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Tipo</label>
                    <Field
                      as="select"
                      name="type"
                      className="form-control"
                      value={selectedType}
                      onChange={handleTypeChange}
                    >
                      <option value="UUID">UUID</option>
                      <option value="FOLIO">Folio</option>
                    </Field>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group">
                    <label>
                      Descargar plantilla
                    </label>
                    <button type="button" className="ml-3 btn btn-outline-primary float-end"
                      onClick={onDownloadExcel}>
                      Plantilla
                      <i className="fas fa-file-excel ml-2"></i>
                    </button>
                  </div>
                </div>



                <div className="col-md-12">
                  <div className="form-group">
                    <label>
                      Cargar plantilla con datos
                    </label>
                    <FileField className="mt-2" accept=".xlsx" setFieldValue={setFieldValue}
                      name="template" id="template" required />
                  </div>
                </div>
              </div>
              <button type="submit" className="btn btn-primary float-end">
                Crear
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

FormView.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  uniqueUUIDs: PropTypes.array.isRequired,
  uniqueFolios: PropTypes.array.isRequired,
  setUniqueUUIDs: PropTypes.func.isRequired,
  setUniqueFolios: PropTypes.func.isRequired,
  selectedUUIDs: PropTypes.array.isRequired,
  setSelectedUUIDs: PropTypes.func.isRequired,
  selectedFolios: PropTypes.array.isRequired,
  setSelectedFolios: PropTypes.func.isRequired,
  selectedType: PropTypes.string.isRequired,
  handleTypeChange: PropTypes.func.isRequired,
};

export default FormView;
