import React from "react";
import PropTypes from "prop-types";
import { BrowserRouter } from "react-router-dom";
import { ModalRoute } from "seed/helpers";
import ExportCFDI from "components/export_cfdi/ExportCFDI";

const Admin = ({
  clients = [],
  providers = [],
  onCloseModal = () => { },
}) => (
  <BrowserRouter basename="/home/export_cfdi">
    <ModalRoute path="/" component={() => <ExportCFDI clients={clients} providers={providers}/>}
      onClose = {onCloseModal} />
  </BrowserRouter>
);

Admin.propTypes = {
  clients: PropTypes.array,
  clientId: PropTypes.number,
  onChangeClient: PropTypes.func,
  onClickDownload: PropTypes.func,
};

export default Admin;