import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useQuery } from "seed/gql";
import { Loading } from "seed/helpers";
import View from "components/dashboard/Dashboard.view";

function Dashboard() {

  const [selectedClient, setSelectedClient] = useState(null);

  const userId = sessionStorage.getItem("id");
  const [showMessage, setShowMessage] = useState(
    !localStorage.getItem("hideMessageB")
  )

  const reqClients = useQuery(`{
    clientUsers {
      client { name }
      user { email }
    }
  }`, `user.id = ${userId}`);

  if (reqClients.loading) return <Loading />;
  if (reqClients.error) return "Error";

  const { clientUsers = [] } = reqClients.data;

  const onClickHideMessage = () => {
    localStorage.setItem("hideMessageB", true)
    setShowMessage(false)
  }

  return <View
    clientUsers={clientUsers}
    showMessage={showMessage}
    setSelectedClient={setSelectedClient}
    onClickHideMessage={onClickHideMessage}
  />;
}

Dashboard.propTypes = {};

export default Dashboard;