import React from "react";
import { Switch, Route, Redirect, BrowserRouter } from "react-router-dom";
import Downloads from "components/dev/Downloads";
import Reports from "components/dev/Reports";

const Home = () => (
  <BrowserRouter basename="/home/dev">
    <Switch>
      <Route path="/downloads" component={Downloads} />
      <Route path="/reports" component={Reports} />
    </Switch>
  </BrowserRouter>
);

Home.propTypes = {};

export default Home;
