import React from "react";
import { Formik, Field, Form } from "formik";
import PropTypes from "prop-types";

const ExportCFDIView = ({ clients, providers, defaultClientId,
  onSubmit = () => { }, error, errorInput, loading, defaultStartDate, defaultEndDate }) => (
  <div class="card">
    <div class="row justify-content-center mx-3 my-5">
      <div class="col">
        <div class="row">
          <div class="col h1">Exportar CFDIs almacenados</div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <Formik initialValues={{ 
              client_id: clients[0].id, 
              provider: "all", 
              status: "all"
            }} onSubmit={onSubmit}>
              {({ values, setFieldValue }) => (
                <Form>
                  <div class="mb-3">

                    <div class="form-group">
                      <label class="input-label">Cliente</label>
                      <Field as="select" name="client_id" class="form-control" required>
                        {clients.map((e, idx) => 
                          <option key={idx} value={e.id}>{e.name}, {e.rfc}</option>)}
                      </Field>
                    </div>

                    <div class="form-group">
                      <label class="input-label">Proveedor</label>
                      <Field as="select" name="provider" class="form-control" required>
                        <option value="all">Todos</option>
                        {providers.map((provider) => (
                          <option value={provider.rfc} key={provider.rfc}>
                            {provider.rfc + " - " + provider.nombre}
                          </option>
                        ))}
                      </Field>
                    </div>

                    <div class="form-group">
                      <label class="input-label">Emitidos/Recibidos</label>
                      <Field as="select" name="status" class="form-control" required>
                        <option value="all" default>Todos</option>
                        <option value="EMITIDO">Emitido</option>
                        <option value="RECIBIDO">Recibido</option>
                      </Field>
                    </div>

                    <div class="form-group">
                      <label class="input-label">Fecha inicial</label>
                      <Field type="date" name="start_date" class="form-control" required/>
                    </div>

                    <div class="form-group">
                      <label class="input-label">Fecha final</label>
                      <Field type="date" name="end_date" class="form-control" required/>
                    </div>

                  </div>
                  {error && <div class="alert alert-soft-danger">{error}</div>}
                  {errorInput && (
                    <div class="alert alert-soft-danger">{errorInput}</div>
                  )}
                  <button type="submit" class="btn btn-block btn-primary">
                    {loading
                      ? <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true" />
                        <span className="ml-2">
                          Descargando...
                        </span>
                      </>
                      : "Descargar"
                    }
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  </div >
);

ExportCFDIView.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.string,
  errorInput: PropTypes.string,
  loading: PropTypes.bool,
  clients: PropTypes.array.isRequired,
  providers: PropTypes.array.isRequired,
  defaultClientId: PropTypes.string,
  defaultStartDate: PropTypes.string,
  defaultEndDate: PropTypes.string
};

export default ExportCFDIView;
