import React, { useState } from "react";
import PropTypes from "prop-types";
import View from "components/clients/FielForm.view";
import { usePost } from "seed/api";
import { useDetail } from "seed/gql";
import { Loading } from "seed/helpers";

function FielForm({ clientId, onCompleted = () => null, onError = () => null, reqClients }) {

  const [validationError, setValidationError] = useState(null);
  const qClient = useDetail(`{ client { } }`, clientId);
  const [callSaveFiel, qSaveFiel] = usePost("/clients/save_fiel", {
    onCompleted: () => {
      reqClients.refetch();
      onCompleted();
    },
  });

  if(qClient.loading) return <Loading/>;
  if(!qClient.data.client) window.location.href = "/home/clients";
  if(qClient.error) return <div>Error</div>;

  let error = qSaveFiel.error ? "Las credenciales son incorrectas" : null;
  if(validationError) error = validationError;

  const onSubmit = (values) => {

    if(!values.fiel_cer) {
      setValidationError('Debe seleccionar un certificado .cer');
    }
    else if(!values.fiel_key) {
      setValidationError('Debe seleccionar una llave .key');
    }
    else if(values.fiel_cer.name.split('.').pop() != 'cer') {
      setValidationError('El certificado debe ser un archivo tipo .cer');
    }
    else if(values.fiel_key.name.split('.').pop() != 'key') {
      setValidationError('La llave debe ser un archivo tipo .key');
    }
    else {
      setValidationError(null);
      values.client = parseInt(clientId);
      callSaveFiel(values);
    }
 
  }

  return <View
    error={error}
    onSubmit={onSubmit}
  />;
}

FielForm.propTypes = {
  clientId: PropTypes.string,
  onCompleted: PropTypes.func,
  onError: PropTypes.func,
  reqClients: PropTypes.object
};

export default FielForm;