/*
__Seed builder__
  AUTO_GENERATED (Read only)
  Modify via builder
*/

export const SINGULARS =
{
  "blacklistSats": "blacklistSat",
  "blacklistSat": "blacklistSat",
  "cfdiFiles": "cfdiFile",
  "cfdiFile": "cfdiFile",
  "cfdiRelacionadoses": "cfdiRelacionados",
  "cfdiRelacionados": "cfdiRelacionados",
  "cfdiRequests": "cfdiRequest",
  "cfdiRequest": "cfdiRequest",
  "clients": "client",
  "client": "client",
  "clientUsers": "clientUser",
  "clientUser": "clientUser",
  "comprobantes": "comprobante",
  "comprobante": "comprobante",
  "conceptos": "concepto",
  "concepto": "concepto",
  "deduccions": "deduccion",
  "deduccion": "deduccion",
  "documentoRelacionados": "documentoRelacionado",
  "documentoRelacionado": "documentoRelacionado",
  "eqs": "eq",
  "eq": "eq",
  "filters": "filter",
  "filter": "filter",
  "filterItems": "filterItem",
  "filterItem": "filterItem",
  "impuestosLocalesTrasladoses": "impuestosLocalesTraslados",
  "impuestosLocalesTraslados": "impuestosLocalesTraslados",
  "impuestosTrasladosConceptos": "impuestosTrasladosConcepto",
  "impuestosTrasladosConcepto": "impuestosTrasladosConcepto",
  "impuestosTrasladosTotaleses": "impuestosTrasladosTotales",
  "impuestosTrasladosTotales": "impuestosTrasladosTotales",
  "nominas": "nomina",
  "nomina": "nomina",
  "otroPagos": "otroPago",
  "otroPago": "otroPago",
  "pagos": "pago",
  "pago": "pago",
  "pagosTotaleses": "pagosTotales",
  "pagosTotales": "pagosTotales",
  "percepcions": "percepcion",
  "percepcion": "percepcion",
  "processes": "process",
  "process": "process",
  "proveedoreses": "proveedores",
  "proveedores": "proveedores",
  "retencionesConceptos": "retencionesConcepto",
  "retencionesConcepto": "retencionesConcepto",
  "retencionesDrs": "retencionesDr",
  "retencionesDr": "retencionesDr",
  "retencionesLocaleses": "retencionesLocales",
  "retencionesLocales": "retencionesLocales",
  "retencionesPs": "retencionesP",
  "retencionesP": "retencionesP",
  "retencionesTotaleses": "retencionesTotales",
  "retencionesTotales": "retencionesTotales",
  "tcs": "tc",
  "tc": "tc",
  "trasladoDrs": "trasladoDr",
  "trasladoDr": "trasladoDr",
  "trasladoPs": "trasladoP",
  "trasladoP": "trasladoP",
  "users": "user",
  "user": "user",
};