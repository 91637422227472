import React from "react";
import PropTypes from "prop-types";
import { PaginationFooter } from "seed/helpers";
import { Link } from "react-router-dom";
import UserFormSave from "components/clients/accounts/FormSave";
import UserFormEdit from "components/clients/accounts/FormSet";
import { ModalRoute } from "seed/helpers";

const ListView = ({ 
  clientUsers, 
  pageNum, 
  totalPages, 
  onClickPage = () => { }, 
  onClickDelete = () => { },
  getType = () => { }, 
  clientId, 
  reqUsers 
}) =>
  <>
    <div class="table-responsive">
      <table
        class="table table-borderless table-thead-bordered table-nowrap
      table-align-middle"
      >
        <thead class="thead-light">
          <tr role="row">
            <th>NOMBRE (S)</th>
            <th>APELLIDOS</th>
            <th>CORREO</th>
            <th>TIPO</th>
            <th>ACCIONES</th>
          </tr>
        </thead>

        <tbody>
          {clientUsers.map((clientUser) => (
            <tr
              class="justify-content-center"
              role="row"
              key={"user_" + clientUser.user.id}
            >
              <td>
                <span class="d-block font-size-sm">{clientUser.user.firstName}</span>
              </td>
              <td>
                <span class="d-block font-size-sm">{clientUser.user.lastName}</span>
              </td>
              <td>
                <span class="d-block font-size-sm">{clientUser.user.email}</span>
              </td>
              <td>
                <span class="d-block font-size-sm">
                  {getType(clientUser.type)}
                </span>
              </td>
              <td>
                <Link to={`/${clientUser.id}/edit`} class="btn btn-sm btn-white mr-3">
                  <i class="tio-edit mr-2"></i>
                  Editar
                </Link>
                <a class="btn btn-sm btn-danger" onClick={() => onClickDelete(clientUser.id)}>
                  <i class="tio-delete mr-2"></i>
                  Eliminar
                </a>
              </td>
            </tr>

          ))}
        </tbody>

      </table>
    </div>
    
    <PaginationFooter pageNum={pageNum} totalPages={totalPages} onClickPage={onClickPage} />

    <ModalRoute path="/create" component={() => <UserFormSave clientId={clientId} reqUsers={reqUsers}/>} />
    <ModalRoute path="/:clientUserId(\d+)/edit" component={({ clientUserId }) => 
      <UserFormEdit reqUsers={reqUsers} clientUserId={clientUserId}/>} />

  </>;

ListView.propTypes = {
  clientUsers: PropTypes.array.isRequired,
  pageNum: PropTypes.number,
  totalPages: PropTypes.number,
  onClickPage: PropTypes.func,
  onClickDelete: PropTypes.func.isRequired,
  getType: PropTypes.func.isRequired,
  clientId: PropTypes.number.isRequired,
  reqUsers: PropTypes.func.isRequired
};

export default ListView;