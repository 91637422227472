import React, { useState } from "react";
import PropTypes from "prop-types";
import View from "components/load/Form.view";
import { useQuery } from "seed/gql";
import { Loading } from "seed/helpers";
import { usePost } from "seed/api";
import { deleteDuplicates } from "components/utils/filtersUtil";

function Form({ onCompleted = () => null, onError = () => null, reqProcesses }) {

  const userId = sessionStorage.getItem("id");
  const userType = sessionStorage.getItem("user");
  const [ totalFiles, setTotalFiles ] = useState(0);
  const [ uploadedFiles, setUploadedFiles ] = useState(0);
  const [ validationErrors, setValidationErrors ] = useState(null);

  let query = "";
  if (userType != "SUPER_ADMIN") query = `client_users.user.id = ${userId}`; 

  const reqClients = useQuery(`{
      clients {
        name
        rfc
        createdAt
      }
  }`, query);

  const [callSave, reqSave] = usePost("/comprobantes/upload_files", { 
    onCompleted: (data) => {
      alert(data);
      reqProcesses.refetch();
      onCompleted();
    },
  })

  if(reqClients.loading) return <Loading />;
  if(reqClients.error) return "Error";
  
  const clients = reqClients.data.clients
  let error = reqSave.error ? "Ha ocurrido un error durante la carga de datos" : null;
  if(validationErrors) error = validationErrors;

  const onSubmit = (values) => {

    if(!values["xml_files_ids"] || values["xml_files_ids"].length == 0) {
      setValidationErrors("No se han seleccionado archivos");
      return;
    }

    const params = {
      "client_id": values["client_id"],
      "xml_files_ids": values["xml_files_ids"],
      "datatype": values["datatype"]
    }
    callSave(params)

  }

  return <View 
    clients={clients}
    reqLoading={reqSave.loading}
    setTotalFiles={setTotalFiles}
    setUploadedFiles={setUploadedFiles}
    totalFiles={totalFiles}
    uploadedFiles={uploadedFiles}
    onSubmit={onSubmit}
    error={error}
  />;
}

Form.propTypes = {
  onCompleted: PropTypes.func,
  onError: PropTypes.func,
  reqProcesses: PropTypes.object
};

export default Form;