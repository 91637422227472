import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Footer from "components/helpers/Footer";
import FielForm from "components/clients/FielForm";
import { ModalRoute } from "seed/helpers";

const ClientList = ({
  clients = [],
  pageNum = 1,
  totalPages = 0,
  totalCount = 0,
  onClickPage = () => {},
  reqClients = {},
  isAdmin = false,
}) => (
  <div class="card">
    <div class="table-responsive">
      <table
        class="table table-borderless table-thead-bordered table-nowrap
        table-align-middle card-table"
      >
        <thead class="thead-light">
          <tr role="row">
            <th>NOMBRE CLIENTE</th>
            <th>RFC</th>
            <th>Estado de FIEL</th>
            <th>ACCIONES</th>
          </tr>
        </thead>
        <tbody>
          {clients.map((client) => (
            <tr
              class="justify-content-center"
              role="row"
              key={"client_" + client.id}
            >
              <td>
                <span class="d-block font-size-sm">{client.name
                  }<br/>
                  <i>{client.dailyDownload ? "Descargas automáticas activadas" : null}</i></span>
              </td>
              <td>
                <span class="d-block font-size-sm">{client.rfc}</span>
              </td>
              <td>
                <h3>
                  {client.fielKey != null 
                    ? <span className="badge badge-primary">
                        Configurada
                      </span>
                    : <span className="badge badge-secondary">
                        Sin configurar
                      </span>
                  }
                </h3>
              </td>
              <td>
                {isAdmin ?
                <Link to={`/${client.id}/edit`} class="btn btn-sm btn-white">
                  <i class="tio-edit mr-2"></i>
                  Editar
                </Link> : null
                }
                <Link to={`/${client.id}/fiel`} class="btn btn-sm btn-white mx-2">
                  <i class="tio-edit mr-2"></i>
                  Configurar FIEL
                </Link>
                <Link to={`/${client.id}/filters`} class="btn btn-sm btn-white mx-2">
                  <i class="fa fa-filter mr-2"></i>
                  Filtros
                </Link>
                <Link to={`/${client.id}/accounts`} class="btn btn-sm btn-dark mx-2">
                  <i class="fa fa-user mr-2"></i>
                  Cuentas
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>

    <Footer
      objectsInPage={clients.length}
      pageNum={pageNum}
      totalPages={totalPages}
      totalCount={totalCount}
      onClickPage={onClickPage}
    />

    <ModalRoute
      path="/:clientId(\d+)/fiel"
      reqClients={reqClients}
      component={FielForm} />

  </div>
);

ClientList.propTypes = {
  clients: PropTypes.array.isRequired,
  pageNum: PropTypes.number,
  totalPages: PropTypes.number,
  totalCount: PropTypes.number,
  onClickPage: PropTypes.func,
  reqClients: PropTypes.object
};

export default ClientList;
