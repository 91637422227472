import React from "react";
import PropTypes from "prop-types";

const StatusMessages = {
  5000: "Indica que la solicitud de descarga que se está verificando fue aceptada.",
  5002: "Para el caso de descarga tipo CFDI, se tiene un límite máximo para solicitudes con los mismos parámetros " + 
        "(Fecha inicial, Fecha final, RFC Emisor, RFC Receptor).",
  5003: "Indica que con base en los parámetros de consulta se está superando el tope máximo de CFDI o Metadata, por solicitud de descarga masiva.",
  5004: "Indica que la solicitud de descarga que se está verificando no generó paquetes por falta de información",
  5005: "En caso de que exista una solicitud vigente con los mismos parámetros " +
        "(Fecha inicial, Fecha final, RFC Emisor, RFC Receptor, Tipo de Solicitud), no se permitirá generar una nueva solicitud.",
  404: "Error genérico, en caso de presentarse realizar nuevamente la petición y si persiste el error levantar un RMA.",
}

const CFDIsDetails = ({ cfdiRequest = {}, onRetry = () => {} }) =>
  <div class="card">

    {/* Header */}
    <div class="card-header">
      <div className="d-flex flex-column">
        <h3 class="">Solicitud {cfdiRequest.client.rfc} - {cfdiRequest.dataType}</h3>
        <span>{function(){
          if(cfdiRequest.generalStatus == "REQUEST") return "Solicitado";
          if(cfdiRequest.generalStatus == "VERIFICATION") return "En verificación";
          if(cfdiRequest.generalStatus == "DOWNLOAD") return "Descargando";
          if(cfdiRequest.generalStatus == "FINISHED") return "Finalizado";
          if(cfdiRequest.generalStatus == "ERROR") return "Error";
        }()}</span>
      </div>
    </div>

    {/* Body */}
    <div className="card-body">

      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label className="input-label">Cliente</label>
            <input className="form-control" type="text" value={cfdiRequest.client.name} disabled/>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label className="input-label">RFC</label>
            <input className="form-control" type="text" value={cfdiRequest.client.rfc} disabled/>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label className="input-label">Fecha inicial</label>
            <input className="form-control" type="text" value={cfdiRequest.startDate.split("T")[0]} disabled/>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label className="input-label">Fecha final</label>
            <input className="form-control" type="text" value={cfdiRequest.endDate.split("T")[0]} disabled/>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label className="input-label">Código Status</label>
            <input className="form-control" type="text" value={cfdiRequest.codeStatus} disabled/>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label className="input-label">Status de descarga</label>
            <input className="form-control" type="text" value={function(){
              if(cfdiRequest.requestStatus == -1) return "Solicitando";
              if(cfdiRequest.requestStatus == 1) return "Aceptada";
              if(cfdiRequest.requestStatus == 2) return "En proceso";
              if(cfdiRequest.requestStatus == 3) return "Terminada";
              if(cfdiRequest.requestStatus == 4) return "Error";
              if(cfdiRequest.requestStatus == 5) return "Rechazada";
              if(cfdiRequest.requestStatus == 6) return "Vencida";
            }()} disabled/>
          </div>
        </div>
      </div>

      
      {cfdiRequest.requestStatus == 5 && 
          <span>
            <i>
            El status Rechazado se puede deber a que el periodo de fechas ya fue solicitado, el SAT no encontró CFDIs o
            hubo un error en el proceso. 
            </i><br/>
          </span>
        }


      <div className="row mt-3">
        <div className="col-md-12">
          <div className="form-group">
            <label className="input-label">Mensaje</label>
            <textarea 
              className="form-control" 
              type="text" 
              value={
                cfdiRequest.message + ". " + (StatusMessages[cfdiRequest.codeStatus]??"")
              } 
              disabled
            />
          </div>
        </div>
      </div>

      {
        <button type="button" class="btn btn-primary ml-2" onClick={() => {

          if(cfdiRequest.generalStatus == "ERROR") {
            alert("No es posible realizar la descarga porque hubo un error en la solicitud.");
            return;
          }

          if(cfdiRequest.generalStatus == "NO_DATA") {
            alert("No es posible realizar ya que no se encontró información en el SAT.");
            return;
          }

          if(cfdiRequest.generalStatus != "FINISHED") {
            alert("No es posible realizar la descarga porque la solicitud aún sigue en proceso.");
            return;
          }

          let files = cfdiRequest.rawDownload;
          for(let i = 0; i < files.length; i++) {
            let link = document.createElement("a");
            link.href = files[i].url;
            link.download = files[i].name;
            link.click();
          }
          
        }}>
          Descargar archivo original del SAT
        </button>
      }

    </div>

  </div>;

CFDIsDetails.propTypes = {
  cfdiRequest: PropTypes.object,
  onRetry: PropTypes.func
};

export default CFDIsDetails;