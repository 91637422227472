import React from "react";
import PropTypes from "prop-types";
import { BrowserRouter, Link } from "react-router-dom";
import AccountList from "components/clients/accounts/List";


const AccountsView = ({ clientId }) =>
  <BrowserRouter basename={`home/clients/${clientId}/accounts`}>
    <div class="card content container-fluid">

      <div class="page-header mt-5">
        <div class="row align-items-end">

          <div class="col-sm">
            <h1 class="page-header-title">Cuentas</h1>
          </div>

          <div class="col-sm-auto">
            <div class="btn-group" role="group">
              <Link to="/create" className="btn btn-primary">
                <i class="tio-add mr-1"></i>Crear cuenta
              </Link>
            </div>
          </div>

        </div>
      </div>

      <div class="card-body">
        <AccountList clientId={clientId} />
      </div>

    </div>
  </BrowserRouter>

  ;

AccountsView.propTypes = {
  clientId: PropTypes.number.isRequired,
};

export default AccountsView;