import React from "react";
import PropTypes from "prop-types";
import { Formik, Field, Form } from "formik";

const FormView = ({ user = {}, onSubmit, error, isUpdate = false }) =>
  <div class="card">

    {/* Header */}
    <div class="card-header">
      <h3 class="card-header-title">Usuario</h3>
    </div>

    {/* Body */}
    <div class="card-body">
      <div class="row">
        <div class="col">
          <Formik
            initialValues={user}
            onSubmit={onSubmit}>
            {({ values, setFieldValue }) =>
              <Form>
                <div class="mb-3">
                  {/* FirstName */}
                  <div class="form-group">
                    <label class="input-label">Nombre(s)</label>
                    <Field type="text" name="firstName" class="form-control" required/>
                  </div>
                  {/* LastName */}
                  <div class="form-group">
                    <label class="input-label">Apellidos</label>
                    <Field type="text" name="lastName" class="form-control" required/>
                  </div>
                  {/* Email */}
                  <div class="form-group">
                    <label class="input-label">Email</label>
                    <Field type="email" name="email" class="form-control" required/>
                  </div>
                  {/* Type */}
                  <div class="form-group">
                    <label class="input-label">Tipo</label>
                    <Field as="select" name="userType" class="form-control" required>
                      <option value="">Seleccione una opcion</option>
                      <option value="ADMIN">Administrador</option>
                      <option value="CLIENT">Cliente</option>
                      <option value="CLIENT_NO_NOMINA">Cliente sin nómina</option>
                      <option value="CLIENT_NO_PAGO">Cliente sin pagos</option>
                      <option value="CLIENT_NO_NOMINA_PAGO">Cliente sin nómina ni pagos</option>
                    </Field>
                  </div>
                  {/* Password */}
                  <div class="form-group">
                    <label class="input-label">Contraseña</label>
                    <Field type="password" name="password" class="form-control" required/>
                  </div>
                </div>

                {error ? <div class="alert alert-soft-danger">{error}</div> : null}
                <button type="submit" class="btn btn-block btn-primary"> 
                  {!isUpdate ? "Crear" : "Actualizar"}
                </button>

              </Form>}
          </Formik>
        </div>
      </div>
    </div>

  </div>;

FormView.propTypes = {
  user: PropTypes.object, 
  onSubmit: PropTypes.func.isRequired, 
  error: PropTypes.string, 
  isUpdate: PropTypes.bool,
};

export default FormView;