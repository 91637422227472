import React from "react";
import PropTypes from "prop-types";
import { useDetail } from "seed/gql";
import { Loading } from "seed/helpers";
import View from "components/download/Details.view";
import { usePost } from "seed/api";

function CFDIsDetails({ requestId }) {

  const qRequest = useDetail(`{
    cfdiRequest {
      client {
          name
          rfc
      }
      rawDownload {
        name
        url
      }
      generalStatus
      requestStatus
      codeStatus
      startDate
      endDate
      message
      metadata
      dataType
    }
  }`, requestId)

  if(qRequest.loading) return <Loading />;
  if(qRequest.error && qRequest.error.message.includes("matching query does not exist")) 
    window.location.href = "/home/download";
  if(qRequest.error) return <div>Error</div>;

  const { cfdiRequest } = qRequest.data;

  return <View
    cfdiRequest={cfdiRequest}
  />;
}

CFDIsDetails.propTypes = {
  requestId: PropTypes.string.isRequired
};

export default CFDIsDetails;