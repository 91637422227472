import React from "react";
import PropTypes from "prop-types";
import View from "components/clients/Clients.view";

function Clients() {
  const userType = sessionStorage.getItem("user"); 
  if (userType != "ADMIN" && userType != "SUPER_ADMIN") 
    window.location.href = "/dashboard/general";

  const isAdmin = userType == "SUPER_ADMIN" || userType == "ADMIN";
  return <View isAdmin={isAdmin}/>;
}

Clients.propTypes = {};

export default Clients;