import React from "react";
import { Formik, Field, Form } from "formik";
import PropTypes from "prop-types";
import { Typeahead } from 'react-bootstrap-typeahead';

const ExportPDFView = ({ 
  clients, 
  providers, 
  defaultClientId, 
  showAlert, 
  warningMessage,
  onSubmit = () => { },
  error, 
  errorInput, 
  loading, 
  setAlert,
  series, 
  folios, 
  uuids, 
  setStartDateSelected, 
  setEndDateSelected, 
  startDateSelected,
  endDateSelected, 
  setProvider, 
  setStatus, 
  filterType, 
  setFilterType 
}) => (
  <div class="card">
    <div class="row justify-content-center mx-3 my-5">
      <div class="col">
        <div class="row">
          <div class="col h1">Exportar a PDF</div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <Formik initialValues={{ 
              client_id: clients[0].id, 
              provider: "all", 
              status: "all",
              filter_type: "na"
            }} onSubmit={onSubmit}>
              {({ values, setFieldValue }) => (
                <Form>
                  <div class="mb-3">

                    <div class="form-group">
                      <label class="input-label">Cliente</label>
                      <Field as="select" name="client_id" class="form-control" required>
                        {clients.map((e, idx) => 
                          <option key={idx} value={e.id}>{e.name}, {e.rfc}</option>)}
                      </Field>
                    </div>

                    <div class="form-group" onChange = {(e) => setProvider(e.target.value)}>
                      <label class="input-label">Proveedor</label>
                      <Field as="select" name="provider" class="form-control" required>
                        <option value="all">Todos</option>
                        {providers.map((provider) => (
                          <option value={provider.rfc} key={provider.rfc}>
                            {provider.rfc + " - " + provider.nombre}
                          </option>
                        ))}
                      </Field>
                    </div>

                    <div class="form-group" onChange = {(e) => setStatus(e.target.value)}>
                      <label class="input-label">Emitidos/Recibidos</label>
                      <Field as="select" name="status" class="form-control" required>
                        <option value="all" default>Todos</option>
                        <option value="EMITIDO">Emitido</option>
                        <option value="RECIBIDO">Recibido</option>
                      </Field>
                    </div>

                    <div class="form-group">
                      <label class="input-label">Fecha inicial</label>
                      <Field type="date" name="start_date" class="form-control" 
                        onChange={(e) => setStartDateSelected(e.target.value)} required/>
                    </div>

                    <div class="form-group">
                      <label class="input-label">Fecha final</label>
                      <Field type="date" name="end_date" class="form-control" 
                        onChange={(e) => setEndDateSelected(e.target.value)} required/>
                    </div>
                    
                    {startDateSelected !== "" && endDateSelected !== "" ?
                      <div class="form-group" onChange={(e) => setFilterType(e.target.value)}>
                        <label class="input-label">Filtros adicionales</label>
                        <Field as="select" name="filter_type" class="form-control" required>
                          <option value="na">Ninguno</option>
                          <option value="folio">Por Folio</option>
                          <option value="serie">Por Serie</option>
                          <option value="uuid">Por UUID</option>
                        </Field>
                      </div>
                    : null}
                    
                    {(startDateSelected !== "" && endDateSelected !== "") && filterType === "serie" ?
                      <div className="form-group">
                        <label className="input-label">Serie</label>
                        <Typeahead
                          id="serie"
                          value="Todos"
                          options={series.map((serie) => ({ value: serie, label: serie }))}
                          onChange={(selected) => setFieldValue('serie', selected[0] ? selected[0].value : '')}
                        />
                      </div>
                      : null
                    }

                    {(startDateSelected !== "" && endDateSelected !== "") && filterType === "folio" ?
                      <div className="form-group">
                        <label className="input-label">Folio</label>
                        <Typeahead
                          id="folio"
                          options={folios.map((folio) => ({ value: folio, label: folio }))}
                          onChange={(selected) => setFieldValue('folio', selected[0] ? selected[0].value : '')}
                        />
                      </div>
                      : null
                    }

                    {(startDateSelected !== "" && endDateSelected !== "") && filterType === "uuid" ?
                      <div className="form-group">
                        <label className="input-label">UUID</label>
                        <Typeahead
                          id="tfd_uuid"
                          options={uuids.map((uuid) => ({ value: uuid, label: uuid }))}
                          onChange={(selected) => setFieldValue('tfd_uuid', selected[0] ? selected[0].value : '')}
                        />
                      </div>
                      : null
                    }

                  </div>
                  {error && <div class="alert alert-soft-danger">{error}</div>}
                  {errorInput && (
                    <div class="alert alert-soft-danger">{errorInput}</div>
                  )}
                  <button type="submit" class="btn btn-block btn-primary">
                    {loading
                      ? <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true" />
                        <span className="ml-2">
                          Exportando...
                        </span>
                      </>
                      : "Exportar"
                    }
                  </button>
                  {showAlert && (
                    <div class="modal" style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
                      <div class="modal-dialog">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title">Advertencia</h5>
                            <button type="button" class="close" onClick={() => setAlert(false)}>
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div class="modal-body" style = {{color:"black"}}>
                            {warningMessage}
                          </div>
                          <div class="modal-footer">
                            <button class="btn btn-primary" type = "submit">
                              Aceptar
                            </button>
                            <button class="btn btn-secondary" onClick={() => setAlert(false)}>
                              Cancelar
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  
  </div >
);

ExportPDFView.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.string,
  errorInput: PropTypes.string,
  loading: PropTypes.bool,
  clients: PropTypes.array.isRequired,
  providers: PropTypes.array.isRequired,
  defaultClientId: PropTypes.string,
  defaultStartDate: PropTypes.string,
  defaultEndDate: PropTypes.string,
  series: PropTypes.array,
  folios: PropTypes.array,
  uuids: PropTypes.array,
};

export default ExportPDFView;
