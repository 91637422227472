import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import View from "components/export_pdf/ExportPDF.view";
import swal from "sweetalert";
import { usePost } from "seed/api";
import { stringToDate } from "components/utils/date";

function ExportPDF({ defaultClientId = 0, onCompleted = () => null, clients, providers }) {

  const [errorInput, setErrorInput] = useState(null);
  const [showAlert, setAlert] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const [clientID, setClientID] = useState(clients[0].id);
  const [startDateSelected, setStartDateSelected] = useState("");
  const [endDateSelected, setEndDateSelected] = useState("");
  const [provider, setProvider] = useState("all");
  const [status, setStatus] = useState("all");
  const [filterType, setFilterType] = useState("na");

  const [series, setSeries] = useState(["Todos"]);
  const [folios, setFolios] = useState(["Todos"]);
  const [uuids, setUUIDs] = useState(["Todos"]);

  const [callOptions, reqOptions] = usePost("/comprobantes/get_options", {
    onCompleted: (data) => {
      if (data["status"] == "OK") {
        setSeries(data["series"]);
        setFolios(data["folios"]);
        setUUIDs(data["uuids"]);
      }
    }
  });

  const [callZip, reqZip] = usePost("/comprobantes/download_cfdis_pdf", {
    onCompleted: (data) => {
      if (data["status"] === "OK") {
        swal("¡Listo!", "Se enviará un link para descargar los archivos correspondientes una vez terminado el proceso.", "success").then(() => {
          window.location.href = "/home";
        });
      }
    }
  });

  useEffect(() => {
    if (startDateSelected != "" && endDateSelected != "") {
      const startDate = new Date(startDateSelected);
      const endDate = new Date(endDateSelected);

      if(startDate > endDate) {
        setErrorInput("La fecha inicial debe ser menor a la fecha final");
        return;
      } else{
        callOptions({ client_id: clientID, 
                      start_date: startDateSelected, 
                      end_date: endDateSelected, 
                      provider: provider, 
                      status: status
                    });
      }
    }
  }, [startDateSelected, endDateSelected, provider, status]);

  const today = new Date();
  const year = today.getFullYear();
  const month = ('0' + (today.getMonth() + 1)).slice(-2);
  const day = ('0' + today.getDate()).slice(-2);

  const startDate = `${year}-${month}-01`;
  const endDate = `${year}-${month}-${day}`;

  const onChangeClient = (e) => {
    const value = e.target.value;
    setClientID(value);
    if(startDateSelected != "" && endDateSelected != "")
      callOptions({ client_id: clientID, 
                    start_date: startDateSelected, 
                    end_date: endDateSelected, 
                    provider: provider, 
                    status: status
                  });
  };

  const onSubmit = (values) => {

    const clientId = values["client_id"];
    let start_date = startDateSelected;
    let end_date = endDateSelected;
    const provider = values["provider"];
    const status = values["status"];
    const userID = sessionStorage.getItem("id");
    let folio = "";
    let serie = "";
    let uuid = "";
    if(filterType == "folio") {
      folio = values["folio"] ?? "Todos";
    } else if(filterType == "serie") {
      serie = values["serie"] ?? "Todos";
    } else{
      uuid = values["tfd_uuid"] ?? "Todos";
    }

    if(!start_date) start_date = new Date('1995-12-17T03:24:00')
    else start_date = stringToDate(start_date, "yyyy-mm-dd", "-")
        
    if(!end_date) end_date = new Date()
    else end_date = stringToDate(end_date, "yyyy-mm-dd", "-")

    if(start_date > end_date) {
      setErrorInput("La fecha inicial debe ser menor a la fecha final");
      return;
    }

    start_date = start_date.toISOString().substr(0, 10)
    end_date = end_date.toISOString().substr(0, 10)

    setErrorInput(null);   
    callZip({
      client_id: clientId,
      start_date: start_date,
      end_date: end_date,
      provider: provider,
      status: status,
      user_id: userID,
      folio: folio,
      serie: serie,
      uuid: uuid
    });
  };

  const error = reqZip.error ? "Ha ocurrido un error" : null;
  const loading = reqZip.loading;

  return <View
    error={error}
    uuids={uuids}
    series={series}
    folios={folios}
    clients={clients}
    loading={loading}
    onSubmit={onSubmit}
    setAlert={setAlert}
    showAlert={showAlert}
    providers={providers}
    setStatus={setStatus}
    errorInput={errorInput}
    filterType={filterType}
    defaultEndDate={endDate}
    setProvider={setProvider}
    setClientID={setClientID}
    defaultStartDate={startDate}
    setFilterType={setFilterType}
    onChangeClient={onChangeClient}
    warningMessage={warningMessage}
    defaultClientId={defaultClientId}
    endDateSelected={endDateSelected}
    startDateSelected={startDateSelected}
    setStartDateSelected={setStartDateSelected}
    setEndDateSelected={setEndDateSelected}
    />;
}

ExportPDF.propTypes = {
  clientId: PropTypes.number.isRequired,
  onCompleted: PropTypes.func
};

export default ExportPDF;
