import React, { useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "seed/gql";
import View from "components/download/Download.view";
import { Loading } from "seed/helpers";

function CFDIsRequest() {
  const userType = sessionStorage.getItem("user"); 
  if (userType != "ADMIN" && userType != "SUPER_ADMIN") 
    window.location.href = "/dashboard/general";

  const userId = sessionStorage.getItem("id");
  let queryClients = "";
  if (userType == "ADMIN") queryClients = `client_users.user.id = ${userId}`;

  const [typeFilter, setTypeFilter] = useState([])
  const [clientFilter, setClientFilter] = useState([]);
  const [dateFilter, setDateFilter] = useState({ startDate: null, endDate: null });

  const reqClients = useQuery(`{
    clients {
      name
      rfc
    }
  }`, queryClients, {orderBy: "name"});

  if (reqClients.loading) return <Loading />;
  if (reqClients.error) return "Error";

  const { clients = [] } = reqClients.data;

  const onChangeClient = (id) => {
    const newFilters = [...clientFilter];
    const index = newFilters.findIndex((filter) => filter == id);

    if (index == -1) {
      newFilters.push(id);
    } else {
      newFilters.splice(index, 1);
    }

    setClientFilter(newFilters)
  }

  const onChangeType = (status) => {
    const newFilters = [...typeFilter];
    const index = newFilters.findIndex((filter) => filter == status);

    if (index == -1) {
      newFilters.push(status);
    } else {
      newFilters.splice(index, 1);
    }

    setTypeFilter(newFilters)
  }

  const onChangeDate = (date, type) => {
    const newDateFilter = {...dateFilter};
    if (date == "") newDateFilter[type] = null;
    else newDateFilter[type] = date;
    setDateFilter(newDateFilter);
  }

  return <View 
    clients={clients}
    clientFilter={clientFilter}
    typeFilter={typeFilter}
    dateFilter={dateFilter}
    onChangeClient={onChangeClient}
    onChangeType={onChangeType}
    onChangeDate={onChangeDate}
  />;
}

CFDIsRequest.propTypes = {};

export default CFDIsRequest;