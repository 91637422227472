import React from "react";
import { Switch, Route, Redirect, BrowserRouter } from "react-router-dom";
import { ScriptTag } from "seed/helpers";
import Dashboard from "components/dashboard/Dashboard";
import Clients from "components/clients/Clients";
import Topnav from "components/navigation/Topnav";
import Logout from "components/auth/Logout";
import Download from "components/download/Download";
import Load from "components/load/Load";
import ExportPDF from "components/export_pdf/Admin";
import ExportCFDI from "components/export_cfdi/Admin";
import Information from "components/navigation/Information";
import Dev from "components/dev/Dev";

const Home = () => (
  <div>
      <main>
        <Topnav />

        <div 
          class="content container" 
          style={{ marginTop: "-11rem", maxWidth: "85%"}}
        >
          <Switch>
            <Route path="/dashboard" component={Dashboard} />
            <Route path="/clients" component={Clients} />
            <Route path="/download" component={Download} />
            <Route path="/load" component={Load} />
            <Route path="/export_pdf" component={ExportPDF} />
            <Route path="/export_cfdi" component={ExportCFDI} />
            <Route path="/information" component={Information} />
            <Route path="/logout" component={Logout} />
            <Route path="/dev" component={Dev} />
            <Redirect to="/dashboard/general" />
          </Switch>
        </div>

      </main>

    <ScriptTag
      content={`
      // Builder toggle invoker
      $('.js-navbar-vertical-aside-toggle-invoker').click(function () {
        $('.js-navbar-vertical-aside-toggle-invoker i').tooltip('hide');
      });

      // Initialization of navbar vertical navigation
      var sidebar = $('.js-navbar-vertical-aside').hsSideNav();

      // Initialization of tooltip in navbar vertical menu
      $('.js-nav-tooltip-link').tooltip({ boundary: 'window' })

      $(".js-nav-tooltip-link").on("show.bs.tooltip", function(e) {
        if (!$("body").hasClass("navbar-vertical-aside-mini-mode")) {
          return false;
        }
      });

      // Initialization of unfold
      $('.js-hs-unfold-invoker').each(function () {
        var unfold = new HSUnfold($(this)).init();
      });

      // Initialization of form search
      $('.js-form-search').each(function () {
        new HSFormSearch($(this)).init()
      });
    `}
    />
  </div>
);

Home.propTypes = {};

export default Home;
