/*
__Seed builder__
  AUTO_GENERATED (Read only)
  Modify via builder
*/

export const BLACKLIST_SAT = `
{
  blacklistSat {
    id
    rfcContribuyente
    nombreContribuyente
    situacionContribuyente
    publicacionPresuntos
    publicacionDesvirtuados
    publicacionDefinitivos
    publicacionSentenciaFavorable
  }
}
`;

export const SET_BLACKLIST_SAT = `
mutation Set(
  $id: Int!,
  $rfcContribuyente: String,
  $nombreContribuyente: String,
  $situacionContribuyente: String,
  $publicacionPresuntos: String,
  $publicacionDesvirtuados: String,
  $publicacionDefinitivos: String,
  $publicacionSentenciaFavorable: String,
)
{
  setBlacklistSat(
    id: $id,
    rfcContribuyente: $rfcContribuyente,
    nombreContribuyente: $nombreContribuyente,
    situacionContribuyente: $situacionContribuyente,
    publicacionPresuntos: $publicacionPresuntos,
    publicacionDesvirtuados: $publicacionDesvirtuados,
    publicacionDefinitivos: $publicacionDefinitivos,
    publicacionSentenciaFavorable: $publicacionSentenciaFavorable,
  ) {
    blacklistSat {
      id
      rfcContribuyente
      nombreContribuyente
      situacionContribuyente
      publicacionPresuntos
      publicacionDesvirtuados
      publicacionDefinitivos
      publicacionSentenciaFavorable
    }
  }
}
`;

export const SAVE_BLACKLIST_SAT = `
mutation Save(
  $rfcContribuyente: String!,
  $nombreContribuyente: String!,
  $situacionContribuyente: String!,
  $publicacionPresuntos: String!,
  $publicacionDesvirtuados: String!,
  $publicacionDefinitivos: String!,
  $publicacionSentenciaFavorable: String!,
)
{
  saveBlacklistSat(
    rfcContribuyente: $rfcContribuyente,
    nombreContribuyente: $nombreContribuyente,
    situacionContribuyente: $situacionContribuyente,
    publicacionPresuntos: $publicacionPresuntos,
    publicacionDesvirtuados: $publicacionDesvirtuados,
    publicacionDefinitivos: $publicacionDefinitivos,
    publicacionSentenciaFavorable: $publicacionSentenciaFavorable,
  ) {
    blacklistSat {
      id
    }
  }
}
`;

export const DELETE_BLACKLIST_SAT = `
mutation Delete($id: Int!)
{
  deleteBlacklistSat(id: $id)
  {
    id
  }
}
`;

export const CFDI_FILE = `
{
  cfdiFile {
    id
    codeStatus
    name
    message
    file {
      id
      url
    }
    cfdiRequest {
      id
    }
  }
}
`;

export const SET_CFDI_FILE = `
mutation Set(
  $id: Int!,
  $codeStatus: Int,
  $name: String,
  $message: String,
  $file: Int,
  $cfdiRequest: Int,
)
{
  setCfdiFile(
    id: $id,
    codeStatus: $codeStatus,
    name: $name,
    message: $message,
    file: $file,
    cfdiRequest: $cfdiRequest,
  ) {
    cfdiFile {
      id
      codeStatus
      name
      message
      file {
        id
      }
      cfdiRequest {
        id
      }
    }
  }
}
`;

export const SAVE_CFDI_FILE = `
mutation Save(
  $codeStatus: Int!,
  $name: String!,
  $message: String!,
  $file: Int,
  $cfdiRequest: Int!,
)
{
  saveCfdiFile(
    codeStatus: $codeStatus,
    name: $name,
    message: $message,
    file: $file,
    cfdiRequest: $cfdiRequest,
  ) {
    cfdiFile {
      id
    }
  }
}
`;

export const DELETE_CFDI_FILE = `
mutation Delete($id: Int!)
{
  deleteCfdiFile(id: $id)
  {
    id
  }
}
`;

export const CFDI_RELACIONADOS = `
{
  cfdiRelacionados {
    id
    relationUuid
    tipoRelacion
    origin {
      id
    }
    relatedComprobante {
      id
    }
  }
}
`;

export const SET_CFDI_RELACIONADOS = `
mutation Set(
  $id: Int!,
  $relationUuid: String,
  $tipoRelacion: String,
  $origin: Int,
  $relatedComprobante: Int,
)
{
  setCfdiRelacionados(
    id: $id,
    origin: $origin,
    relationUuid: $relationUuid,
    tipoRelacion: $tipoRelacion,
    relatedComprobante: $relatedComprobante,
  ) {
    cfdiRelacionados {
      id
      relationUuid
      tipoRelacion
      origin {
        id
      }
      relatedComprobante {
        id
      }
    }
  }
}
`;

export const SAVE_CFDI_RELACIONADOS = `
mutation Save(
  $relationUuid: String!,
  $tipoRelacion: String!,
  $origin: Int!,
  $relatedComprobante: Int,
)
{
  saveCfdiRelacionados(
    origin: $origin,
    relationUuid: $relationUuid,
    tipoRelacion: $tipoRelacion,
    relatedComprobante: $relatedComprobante,
  ) {
    cfdiRelacionados {
      id
    }
  }
}
`;

export const DELETE_CFDI_RELACIONADOS = `
mutation Delete($id: Int!)
{
  deleteCfdiRelacionados(id: $id)
  {
    id
  }
}
`;

export const CFDI_REQUEST = `
{
  cfdiRequest {
    id
    requestId
    startDate
    endDate
    requestStatus
    codeStatus
    message
    generalStatus
    type
    dataType
    isAutoDownload
    metadata
    notified
    client {
      id
    }
  }
}
`;

export const SET_CFDI_REQUEST = `
mutation Set(
  $id: Int!,
  $requestId: String,
  $startDate: DateTime,
  $endDate: DateTime,
  $requestStatus: Int,
  $codeStatus: Int,
  $message: String,
  $generalStatus: String,
  $type: String,
  $dataType: String,
  $isAutoDownload: Boolean,
  $metadata: GenericScalar,
  $notified: Boolean,
  $client: Int,
)
{
  setCfdiRequest(
    id: $id,
    requestId: $requestId,
    startDate: $startDate,
    endDate: $endDate,
    requestStatus: $requestStatus,
    codeStatus: $codeStatus,
    message: $message,
    generalStatus: $generalStatus,
    type: $type,
    dataType: $dataType,
    client: $client,
    isAutoDownload: $isAutoDownload,
    metadata: $metadata,
    notified: $notified,
  ) {
    cfdiRequest {
      id
      requestId
      startDate
      endDate
      requestStatus
      codeStatus
      message
      generalStatus
      type
      dataType
      isAutoDownload
      metadata
      notified
      client {
        id
      }
    }
  }
}
`;

export const SAVE_CFDI_REQUEST = `
mutation Save(
  $requestId: String!,
  $startDate: DateTime!,
  $endDate: DateTime!,
  $requestStatus: Int!,
  $codeStatus: Int!,
  $message: String!,
  $generalStatus: String!,
  $type: String!,
  $dataType: String!,
  $isAutoDownload: Boolean!,
  $metadata: GenericScalar!,
  $notified: Boolean!,
  $client: Int!,
)
{
  saveCfdiRequest(
    requestId: $requestId,
    startDate: $startDate,
    endDate: $endDate,
    requestStatus: $requestStatus,
    codeStatus: $codeStatus,
    message: $message,
    generalStatus: $generalStatus,
    type: $type,
    dataType: $dataType,
    client: $client,
    isAutoDownload: $isAutoDownload,
    metadata: $metadata,
    notified: $notified,
  ) {
    cfdiRequest {
      id
    }
  }
}
`;

export const DELETE_CFDI_REQUEST = `
mutation Delete($id: Int!)
{
  deleteCfdiRequest(id: $id)
  {
    id
  }
}
`;

export const CLIENT = `
{
  client {
    id
    name
    rfc
    fielPass
    dailyDownload
    token
    urlDashboard
    migrated
    fielKey {
      id
      url
    }
    fielCer {
      id
      url
    }
    admin {
      id
    }
  }
}
`;

export const SET_CLIENT = `
mutation Set(
  $id: Int!,
  $name: String,
  $rfc: String,
  $fielPass: String,
  $dailyDownload: Boolean,
  $token: String,
  $fielKey: Int,
  $fielCer: Int,
  $urlDashboard: String,
  $migrated: Boolean,
  $admin: Int,
)
{
  setClient(
    id: $id,
    name: $name,
    rfc: $rfc,
    fielPass: $fielPass,
    dailyDownload: $dailyDownload,
    token: $token,
    fielKey: $fielKey,
    fielCer: $fielCer,
    admin: $admin,
    urlDashboard: $urlDashboard,
    migrated: $migrated,
  ) {
    client {
      id
      name
      rfc
      fielPass
      dailyDownload
      token
      urlDashboard
      migrated
      fielKey {
        id
      }
      fielCer {
        id
      }
      admin {
        id
      }
    }
  }
}
`;

export const SAVE_CLIENT = `
mutation Save(
  $name: String!,
  $rfc: String!,
  $fielPass: String,
  $dailyDownload: Boolean!,
  $token: String!,
  $fielKey: Int,
  $fielCer: Int,
  $urlDashboard: String!,
  $migrated: Boolean!,
  $admin: Int!,
)
{
  saveClient(
    name: $name,
    rfc: $rfc,
    fielPass: $fielPass,
    dailyDownload: $dailyDownload,
    token: $token,
    fielKey: $fielKey,
    fielCer: $fielCer,
    admin: $admin,
    urlDashboard: $urlDashboard,
    migrated: $migrated,
  ) {
    client {
      id
    }
  }
}
`;

export const DELETE_CLIENT = `
mutation Delete($id: Int!)
{
  deleteClient(id: $id)
  {
    id
  }
}
`;

export const CLIENT_USER = `
{
  clientUser {
    id
    type
    client {
      id
    }
    user {
      id
    }
  }
}
`;

export const SET_CLIENT_USER = `
mutation Set(
  $id: Int!,
  $type: String,
  $client: Int,
  $user: Int,
)
{
  setClientUser(
    id: $id,
    type: $type,
    client: $client,
    user: $user,
  ) {
    clientUser {
      id
      type
      client {
        id
      }
      user {
        id
      }
    }
  }
}
`;

export const SAVE_CLIENT_USER = `
mutation Save(
  $type: String!,
  $client: Int!,
  $user: Int!,
)
{
  saveClientUser(
    type: $type,
    client: $client,
    user: $user,
  ) {
    clientUser {
      id
    }
  }
}
`;

export const DELETE_CLIENT_USER = `
mutation Delete($id: Int!)
{
  deleteClientUser(id: $id)
  {
    id
  }
}
`;

export const COMPROBANTE = `
{
  comprobante {
    id
    tfdUuid
    folio
    fecha
    version
    subtotal
    total
    impuestosTrasladosTotales
    tipoCambio
    tipoCambioRaw
    moneda
    formaPago
    tipoComprobante
    metodoPago
    lugarExpedicion
    emisorRfc
    emisorNombre
    emisorRawNombre
    emisorRegimenFiscal
    emisorUid
    receptorRfc
    receptorUsoCfdi
    receptorNombre
    receptorRawNombre
    receptorRegimenFiscal
    receptorUid
    receptorNumregidtrib
    receptorResidenciaFiscal
    serie
    sello
    efectoComprobante
    condicionesPago
    biTipoComprobante
    biEmitidoRecibido
    biIsInBlacklist
    fechaCancelacion
    isCancelled
    hasMetadata
    hasCfdis
    fechaTimbrado
    descuento
    tipoCambioUpdated
    emisorUpdated
    receptorUpdated
    noCertificado
    impLocTrasXmlnsImplocal
    impLocTrasVersion
    impLocTrasTotalRetenciones
    impLocTrasTotalTraslados
    lastUpdate
    isComplemento
    file {
      id
      url
    }
    client {
      id
    }
  }
}
`;

export const SET_COMPROBANTE = `
mutation Set(
  $id: Int!,
  $tfdUuid: String,
  $folio: String,
  $fecha: DateTime,
  $version: Float,
  $subtotal: Float,
  $total: Float,
  $impuestosTrasladosTotales: Float,
  $tipoCambio: Float,
  $tipoCambioRaw: Float,
  $moneda: String,
  $formaPago: String,
  $tipoComprobante: String,
  $metodoPago: String,
  $lugarExpedicion: String,
  $emisorRfc: String,
  $emisorNombre: String,
  $emisorRawNombre: String,
  $emisorRegimenFiscal: String,
  $emisorUid: String,
  $receptorRfc: String,
  $receptorUsoCfdi: String,
  $receptorNombre: String,
  $receptorRawNombre: String,
  $receptorRegimenFiscal: String,
  $receptorUid: String,
  $receptorNumregidtrib: String,
  $receptorResidenciaFiscal: String,
  $serie: String,
  $sello: String,
  $efectoComprobante: String,
  $condicionesPago: String,
  $biTipoComprobante: String,
  $biEmitidoRecibido: String,
  $biIsInBlacklist: String,
  $fechaCancelacion: DateTime,
  $isCancelled: Boolean,
  $hasMetadata: Boolean,
  $hasCfdis: Boolean,
  $file: Int,
  $fechaTimbrado: DateTime,
  $descuento: Float,
  $tipoCambioUpdated: Boolean,
  $emisorUpdated: Boolean,
  $receptorUpdated: Boolean,
  $noCertificado: String,
  $impLocTrasXmlnsImplocal: String,
  $impLocTrasVersion: Float,
  $impLocTrasTotalRetenciones: Float,
  $impLocTrasTotalTraslados: Float,
  $lastUpdate: DateTime,
  $isComplemento: Boolean,
  $client: Int,
)
{
  setComprobante(
    id: $id,
    tfdUuid: $tfdUuid,
    folio: $folio,
    fecha: $fecha,
    version: $version,
    subtotal: $subtotal,
    total: $total,
    impuestosTrasladosTotales: $impuestosTrasladosTotales,
    tipoCambio: $tipoCambio,
    tipoCambioRaw: $tipoCambioRaw,
    moneda: $moneda,
    formaPago: $formaPago,
    tipoComprobante: $tipoComprobante,
    metodoPago: $metodoPago,
    lugarExpedicion: $lugarExpedicion,
    emisorRfc: $emisorRfc,
    emisorNombre: $emisorNombre,
    emisorRawNombre: $emisorRawNombre,
    emisorRegimenFiscal: $emisorRegimenFiscal,
    emisorUid: $emisorUid,
    receptorRfc: $receptorRfc,
    receptorUsoCfdi: $receptorUsoCfdi,
    receptorNombre: $receptorNombre,
    receptorRawNombre: $receptorRawNombre,
    receptorRegimenFiscal: $receptorRegimenFiscal,
    receptorUid: $receptorUid,
    receptorNumregidtrib: $receptorNumregidtrib,
    receptorResidenciaFiscal: $receptorResidenciaFiscal,
    serie: $serie,
    sello: $sello,
    efectoComprobante: $efectoComprobante,
    condicionesPago: $condicionesPago,
    biTipoComprobante: $biTipoComprobante,
    biEmitidoRecibido: $biEmitidoRecibido,
    biIsInBlacklist: $biIsInBlacklist,
    fechaCancelacion: $fechaCancelacion,
    isCancelled: $isCancelled,
    hasMetadata: $hasMetadata,
    hasCfdis: $hasCfdis,
    file: $file,
    client: $client,
    fechaTimbrado: $fechaTimbrado,
    descuento: $descuento,
    tipoCambioUpdated: $tipoCambioUpdated,
    emisorUpdated: $emisorUpdated,
    receptorUpdated: $receptorUpdated,
    noCertificado: $noCertificado,
    impLocTrasXmlnsImplocal: $impLocTrasXmlnsImplocal,
    impLocTrasVersion: $impLocTrasVersion,
    impLocTrasTotalRetenciones: $impLocTrasTotalRetenciones,
    impLocTrasTotalTraslados: $impLocTrasTotalTraslados,
    lastUpdate: $lastUpdate,
    isComplemento: $isComplemento,
  ) {
    comprobante {
      id
      tfdUuid
      folio
      fecha
      version
      subtotal
      total
      impuestosTrasladosTotales
      tipoCambio
      tipoCambioRaw
      moneda
      formaPago
      tipoComprobante
      metodoPago
      lugarExpedicion
      emisorRfc
      emisorNombre
      emisorRawNombre
      emisorRegimenFiscal
      emisorUid
      receptorRfc
      receptorUsoCfdi
      receptorNombre
      receptorRawNombre
      receptorRegimenFiscal
      receptorUid
      receptorNumregidtrib
      receptorResidenciaFiscal
      serie
      sello
      efectoComprobante
      condicionesPago
      biTipoComprobante
      biEmitidoRecibido
      biIsInBlacklist
      fechaCancelacion
      isCancelled
      hasMetadata
      hasCfdis
      fechaTimbrado
      descuento
      tipoCambioUpdated
      emisorUpdated
      receptorUpdated
      noCertificado
      impLocTrasXmlnsImplocal
      impLocTrasVersion
      impLocTrasTotalRetenciones
      impLocTrasTotalTraslados
      lastUpdate
      isComplemento
      file {
        id
      }
      client {
        id
      }
    }
  }
}
`;

export const SAVE_COMPROBANTE = `
mutation Save(
  $tfdUuid: String!,
  $folio: String!,
  $fecha: DateTime!,
  $version: Float!,
  $subtotal: Float!,
  $total: Float!,
  $impuestosTrasladosTotales: Float!,
  $tipoCambio: Float!,
  $tipoCambioRaw: Float!,
  $moneda: String!,
  $formaPago: String!,
  $tipoComprobante: String!,
  $metodoPago: String!,
  $lugarExpedicion: String!,
  $emisorRfc: String!,
  $emisorNombre: String!,
  $emisorRawNombre: String!,
  $emisorRegimenFiscal: String!,
  $emisorUid: String!,
  $receptorRfc: String!,
  $receptorUsoCfdi: String!,
  $receptorNombre: String!,
  $receptorRawNombre: String!,
  $receptorRegimenFiscal: String!,
  $receptorUid: String!,
  $receptorNumregidtrib: String!,
  $receptorResidenciaFiscal: String!,
  $serie: String!,
  $sello: String!,
  $efectoComprobante: String!,
  $condicionesPago: String!,
  $biTipoComprobante: String!,
  $biEmitidoRecibido: String!,
  $biIsInBlacklist: String!,
  $fechaCancelacion: DateTime,
  $isCancelled: Boolean!,
  $hasMetadata: Boolean!,
  $hasCfdis: Boolean!,
  $file: Int!,
  $fechaTimbrado: DateTime!,
  $descuento: Float!,
  $tipoCambioUpdated: Boolean!,
  $emisorUpdated: Boolean!,
  $receptorUpdated: Boolean!,
  $noCertificado: String!,
  $impLocTrasXmlnsImplocal: String!,
  $impLocTrasVersion: Float!,
  $impLocTrasTotalRetenciones: Float!,
  $impLocTrasTotalTraslados: Float!,
  $lastUpdate: DateTime!,
  $isComplemento: Boolean!,
  $client: Int!,
)
{
  saveComprobante(
    tfdUuid: $tfdUuid,
    folio: $folio,
    fecha: $fecha,
    version: $version,
    subtotal: $subtotal,
    total: $total,
    impuestosTrasladosTotales: $impuestosTrasladosTotales,
    tipoCambio: $tipoCambio,
    tipoCambioRaw: $tipoCambioRaw,
    moneda: $moneda,
    formaPago: $formaPago,
    tipoComprobante: $tipoComprobante,
    metodoPago: $metodoPago,
    lugarExpedicion: $lugarExpedicion,
    emisorRfc: $emisorRfc,
    emisorNombre: $emisorNombre,
    emisorRawNombre: $emisorRawNombre,
    emisorRegimenFiscal: $emisorRegimenFiscal,
    emisorUid: $emisorUid,
    receptorRfc: $receptorRfc,
    receptorUsoCfdi: $receptorUsoCfdi,
    receptorNombre: $receptorNombre,
    receptorRawNombre: $receptorRawNombre,
    receptorRegimenFiscal: $receptorRegimenFiscal,
    receptorUid: $receptorUid,
    receptorNumregidtrib: $receptorNumregidtrib,
    receptorResidenciaFiscal: $receptorResidenciaFiscal,
    serie: $serie,
    sello: $sello,
    efectoComprobante: $efectoComprobante,
    condicionesPago: $condicionesPago,
    biTipoComprobante: $biTipoComprobante,
    biEmitidoRecibido: $biEmitidoRecibido,
    biIsInBlacklist: $biIsInBlacklist,
    fechaCancelacion: $fechaCancelacion,
    isCancelled: $isCancelled,
    hasMetadata: $hasMetadata,
    hasCfdis: $hasCfdis,
    file: $file,
    client: $client,
    fechaTimbrado: $fechaTimbrado,
    descuento: $descuento,
    tipoCambioUpdated: $tipoCambioUpdated,
    emisorUpdated: $emisorUpdated,
    receptorUpdated: $receptorUpdated,
    noCertificado: $noCertificado,
    impLocTrasXmlnsImplocal: $impLocTrasXmlnsImplocal,
    impLocTrasVersion: $impLocTrasVersion,
    impLocTrasTotalRetenciones: $impLocTrasTotalRetenciones,
    impLocTrasTotalTraslados: $impLocTrasTotalTraslados,
    lastUpdate: $lastUpdate,
    isComplemento: $isComplemento,
  ) {
    comprobante {
      id
    }
  }
}
`;

export const DELETE_COMPROBANTE = `
mutation Delete($id: Int!)
{
  deleteComprobante(id: $id)
  {
    id
  }
}
`;

export const CONCEPTO = `
{
  concepto {
    id
    cantidad
    descuento
    valorUnitario
    importe
    claveUnidad
    unidad
    claveProdServ
    descripcion
    noIdentificacion
    moneda
    tipoCambio
    comprobante {
      id
    }
  }
}
`;

export const SET_CONCEPTO = `
mutation Set(
  $id: Int!,
  $cantidad: Float,
  $descuento: Float,
  $valorUnitario: Float,
  $importe: Float,
  $claveUnidad: String,
  $unidad: String,
  $claveProdServ: String,
  $descripcion: String,
  $noIdentificacion: String,
  $moneda: String,
  $tipoCambio: Float,
  $comprobante: Int,
)
{
  setConcepto(
    id: $id,
    cantidad: $cantidad,
    descuento: $descuento,
    valorUnitario: $valorUnitario,
    importe: $importe,
    claveUnidad: $claveUnidad,
    unidad: $unidad,
    claveProdServ: $claveProdServ,
    descripcion: $descripcion,
    noIdentificacion: $noIdentificacion,
    moneda: $moneda,
    tipoCambio: $tipoCambio,
    comprobante: $comprobante,
  ) {
    concepto {
      id
      cantidad
      descuento
      valorUnitario
      importe
      claveUnidad
      unidad
      claveProdServ
      descripcion
      noIdentificacion
      moneda
      tipoCambio
      comprobante {
        id
      }
    }
  }
}
`;

export const SAVE_CONCEPTO = `
mutation Save(
  $cantidad: Float!,
  $descuento: Float!,
  $valorUnitario: Float!,
  $importe: Float!,
  $claveUnidad: String!,
  $unidad: String!,
  $claveProdServ: String!,
  $descripcion: String!,
  $noIdentificacion: String!,
  $moneda: String!,
  $tipoCambio: Float!,
  $comprobante: Int!,
)
{
  saveConcepto(
    cantidad: $cantidad,
    descuento: $descuento,
    valorUnitario: $valorUnitario,
    importe: $importe,
    claveUnidad: $claveUnidad,
    unidad: $unidad,
    claveProdServ: $claveProdServ,
    descripcion: $descripcion,
    noIdentificacion: $noIdentificacion,
    moneda: $moneda,
    tipoCambio: $tipoCambio,
    comprobante: $comprobante,
  ) {
    concepto {
      id
    }
  }
}
`;

export const DELETE_CONCEPTO = `
mutation Delete($id: Int!)
{
  deleteConcepto(id: $id)
  {
    id
  }
}
`;

export const DEDUCCION = `
{
  deduccion {
    id
    tipo
    clave
    concepto
    importe
    tipoNombre
    origin {
      id
    }
  }
}
`;

export const SET_DEDUCCION = `
mutation Set(
  $id: Int!,
  $tipo: String,
  $clave: String,
  $concepto: String,
  $importe: Float,
  $tipoNombre: String,
  $origin: Int,
)
{
  setDeduccion(
    id: $id,
    tipo: $tipo,
    clave: $clave,
    concepto: $concepto,
    importe: $importe,
    tipoNombre: $tipoNombre,
    origin: $origin,
  ) {
    deduccion {
      id
      tipo
      clave
      concepto
      importe
      tipoNombre
      origin {
        id
      }
    }
  }
}
`;

export const SAVE_DEDUCCION = `
mutation Save(
  $tipo: String!,
  $clave: String!,
  $concepto: String!,
  $importe: Float!,
  $tipoNombre: String!,
  $origin: Int!,
)
{
  saveDeduccion(
    tipo: $tipo,
    clave: $clave,
    concepto: $concepto,
    importe: $importe,
    tipoNombre: $tipoNombre,
    origin: $origin,
  ) {
    deduccion {
      id
    }
  }
}
`;

export const DELETE_DEDUCCION = `
mutation Delete($id: Int!)
{
  deleteDeduccion(id: $id)
  {
    id
  }
}
`;

export const DOCUMENTO_RELACIONADO = `
{
  documentoRelacionado {
    id
    idDocumento
    serie
    folio
    monedaDr
    metodoPagoDr
    impSaldoAnt
    impPagado
    impSaldoInsoluto
    objetoImpDr
    moneda
    tipoCambio
    fechaPagoNodo
    version
    numParcialidad
    pago {
      id
    }
    relatedComprobante {
      id
    }
  }
}
`;

export const SET_DOCUMENTO_RELACIONADO = `
mutation Set(
  $id: Int!,
  $idDocumento: String,
  $serie: String,
  $folio: String,
  $monedaDr: String,
  $metodoPagoDr: String,
  $impSaldoAnt: Float,
  $impPagado: Float,
  $impSaldoInsoluto: Float,
  $objetoImpDr: String,
  $moneda: String,
  $tipoCambio: Float,
  $fechaPagoNodo: DateTime,
  $version: Float,
  $numParcialidad: Int,
  $pago: Int,
  $relatedComprobante: Int,
)
{
  setDocumentoRelacionado(
    id: $id,
    idDocumento: $idDocumento,
    serie: $serie,
    folio: $folio,
    monedaDr: $monedaDr,
    metodoPagoDr: $metodoPagoDr,
    impSaldoAnt: $impSaldoAnt,
    impPagado: $impPagado,
    impSaldoInsoluto: $impSaldoInsoluto,
    objetoImpDr: $objetoImpDr,
    pago: $pago,
    moneda: $moneda,
    tipoCambio: $tipoCambio,
    relatedComprobante: $relatedComprobante,
    fechaPagoNodo: $fechaPagoNodo,
    version: $version,
    numParcialidad: $numParcialidad,
  ) {
    documentoRelacionado {
      id
      idDocumento
      serie
      folio
      monedaDr
      metodoPagoDr
      impSaldoAnt
      impPagado
      impSaldoInsoluto
      objetoImpDr
      moneda
      tipoCambio
      fechaPagoNodo
      version
      numParcialidad
      pago {
        id
      }
      relatedComprobante {
        id
      }
    }
  }
}
`;

export const SAVE_DOCUMENTO_RELACIONADO = `
mutation Save(
  $idDocumento: String!,
  $serie: String!,
  $folio: String!,
  $monedaDr: String!,
  $metodoPagoDr: String!,
  $impSaldoAnt: Float!,
  $impPagado: Float!,
  $impSaldoInsoluto: Float!,
  $objetoImpDr: String!,
  $moneda: String!,
  $tipoCambio: Float!,
  $fechaPagoNodo: DateTime,
  $version: Float,
  $numParcialidad: Int,
  $pago: Int!,
  $relatedComprobante: Int,
)
{
  saveDocumentoRelacionado(
    idDocumento: $idDocumento,
    serie: $serie,
    folio: $folio,
    monedaDr: $monedaDr,
    metodoPagoDr: $metodoPagoDr,
    impSaldoAnt: $impSaldoAnt,
    impPagado: $impPagado,
    impSaldoInsoluto: $impSaldoInsoluto,
    objetoImpDr: $objetoImpDr,
    pago: $pago,
    moneda: $moneda,
    tipoCambio: $tipoCambio,
    relatedComprobante: $relatedComprobante,
    fechaPagoNodo: $fechaPagoNodo,
    version: $version,
    numParcialidad: $numParcialidad,
  ) {
    documentoRelacionado {
      id
    }
  }
}
`;

export const DELETE_DOCUMENTO_RELACIONADO = `
mutation Delete($id: Int!)
{
  deleteDocumentoRelacionado(id: $id)
  {
    id
  }
}
`;

export const EQ = `
{
  eq {
    id
    amount
    currency
    year
    month
  }
}
`;

export const SET_EQ = `
mutation Set(
  $id: Int!,
  $amount: Float,
  $currency: String,
  $year: Int,
  $month: Int,
)
{
  setEq(
    id: $id,
    amount: $amount,
    currency: $currency,
    year: $year,
    month: $month,
  ) {
    eq {
      id
      amount
      currency
      year
      month
    }
  }
}
`;

export const SAVE_EQ = `
mutation Save(
  $amount: Float!,
  $currency: String!,
  $year: Int!,
  $month: Int!,
)
{
  saveEq(
    amount: $amount,
    currency: $currency,
    year: $year,
    month: $month,
  ) {
    eq {
      id
    }
  }
}
`;

export const DELETE_EQ = `
mutation Delete($id: Int!)
{
  deleteEq(id: $id)
  {
    id
  }
}
`;

export const FILTER = `
{
  filter {
    id
    name
    type
    clientId {
      id
    }
  }
}
`;

export const SET_FILTER = `
mutation Set(
  $id: Int!,
  $name: String,
  $type: String,
  $clientId: Int,
)
{
  setFilter(
    id: $id,
    name: $name,
    type: $type,
    clientId: $clientId,
  ) {
    filter {
      id
      name
      type
      clientId {
        id
      }
    }
  }
}
`;

export const SAVE_FILTER = `
mutation Save(
  $name: String!,
  $type: String!,
  $clientId: Int!,
)
{
  saveFilter(
    name: $name,
    type: $type,
    clientId: $clientId,
  ) {
    filter {
      id
    }
  }
}
`;

export const DELETE_FILTER = `
mutation Delete($id: Int!)
{
  deleteFilter(id: $id)
  {
    id
  }
}
`;

export const FILTER_ITEM = `
{
  filterItem {
    id
    value
    filterId {
      id
    }
  }
}
`;

export const SET_FILTER_ITEM = `
mutation Set(
  $id: Int!,
  $value: String,
  $filterId: Int,
)
{
  setFilterItem(
    id: $id,
    value: $value,
    filterId: $filterId,
  ) {
    filterItem {
      id
      value
      filterId {
        id
      }
    }
  }
}
`;

export const SAVE_FILTER_ITEM = `
mutation Save(
  $value: String!,
  $filterId: Int!,
)
{
  saveFilterItem(
    value: $value,
    filterId: $filterId,
  ) {
    filterItem {
      id
    }
  }
}
`;

export const DELETE_FILTER_ITEM = `
mutation Delete($id: Int!)
{
  deleteFilterItem(id: $id)
  {
    id
  }
}
`;

export const IMPUESTOS_LOCALES_TRASLADOS = `
{
  impuestosLocalesTraslados {
    id
    impLocTraslado
    tasaDeTraslado
    importe
    tipoCambio
    moneda
    comprobante {
      id
    }
  }
}
`;

export const SET_IMPUESTOS_LOCALES_TRASLADOS = `
mutation Set(
  $id: Int!,
  $impLocTraslado: String,
  $tasaDeTraslado: Float,
  $importe: Float,
  $tipoCambio: Float,
  $moneda: String,
  $comprobante: Int,
)
{
  setImpuestosLocalesTraslados(
    id: $id,
    impLocTraslado: $impLocTraslado,
    tasaDeTraslado: $tasaDeTraslado,
    importe: $importe,
    comprobante: $comprobante,
    tipoCambio: $tipoCambio,
    moneda: $moneda,
  ) {
    impuestosLocalesTraslados {
      id
      impLocTraslado
      tasaDeTraslado
      importe
      tipoCambio
      moneda
      comprobante {
        id
      }
    }
  }
}
`;

export const SAVE_IMPUESTOS_LOCALES_TRASLADOS = `
mutation Save(
  $impLocTraslado: String!,
  $tasaDeTraslado: Float!,
  $importe: Float!,
  $tipoCambio: Float!,
  $moneda: String!,
  $comprobante: Int!,
)
{
  saveImpuestosLocalesTraslados(
    impLocTraslado: $impLocTraslado,
    tasaDeTraslado: $tasaDeTraslado,
    importe: $importe,
    comprobante: $comprobante,
    tipoCambio: $tipoCambio,
    moneda: $moneda,
  ) {
    impuestosLocalesTraslados {
      id
    }
  }
}
`;

export const DELETE_IMPUESTOS_LOCALES_TRASLADOS = `
mutation Delete($id: Int!)
{
  deleteImpuestosLocalesTraslados(id: $id)
  {
    id
  }
}
`;

export const IMPUESTOS_TRASLADOS_CONCEPTO = `
{
  impuestosTrasladosConcepto {
    id
    base
    importe
    impuesto
    tipoFactor
    tasaOCuota
    tipoCambio
    moneda
    concepto {
      id
    }
  }
}
`;

export const SET_IMPUESTOS_TRASLADOS_CONCEPTO = `
mutation Set(
  $id: Int!,
  $base: Float,
  $importe: Float,
  $impuesto: String,
  $tipoFactor: String,
  $tasaOCuota: Float,
  $tipoCambio: Float,
  $moneda: String,
  $concepto: Int,
)
{
  setImpuestosTrasladosConcepto(
    id: $id,
    base: $base,
    importe: $importe,
    impuesto: $impuesto,
    tipoFactor: $tipoFactor,
    tasaOCuota: $tasaOCuota,
    tipoCambio: $tipoCambio,
    moneda: $moneda,
    concepto: $concepto,
  ) {
    impuestosTrasladosConcepto {
      id
      base
      importe
      impuesto
      tipoFactor
      tasaOCuota
      tipoCambio
      moneda
      concepto {
        id
      }
    }
  }
}
`;

export const SAVE_IMPUESTOS_TRASLADOS_CONCEPTO = `
mutation Save(
  $base: Float!,
  $importe: Float!,
  $impuesto: String!,
  $tipoFactor: String!,
  $tasaOCuota: Float!,
  $tipoCambio: Float!,
  $moneda: String!,
  $concepto: Int!,
)
{
  saveImpuestosTrasladosConcepto(
    base: $base,
    importe: $importe,
    impuesto: $impuesto,
    tipoFactor: $tipoFactor,
    tasaOCuota: $tasaOCuota,
    tipoCambio: $tipoCambio,
    moneda: $moneda,
    concepto: $concepto,
  ) {
    impuestosTrasladosConcepto {
      id
    }
  }
}
`;

export const DELETE_IMPUESTOS_TRASLADOS_CONCEPTO = `
mutation Delete($id: Int!)
{
  deleteImpuestosTrasladosConcepto(id: $id)
  {
    id
  }
}
`;

export const IMPUESTOS_TRASLADOS_TOTALES = `
{
  impuestosTrasladosTotales {
    id
    importe
    impuesto
    tipoFactor
    tasaOCuota
    tipoCambio
    moneda
    comprobante {
      id
    }
  }
}
`;

export const SET_IMPUESTOS_TRASLADOS_TOTALES = `
mutation Set(
  $id: Int!,
  $importe: Float,
  $impuesto: String,
  $tipoFactor: String,
  $tasaOCuota: Float,
  $tipoCambio: Float,
  $moneda: String,
  $comprobante: Int,
)
{
  setImpuestosTrasladosTotales(
    id: $id,
    importe: $importe,
    impuesto: $impuesto,
    tipoFactor: $tipoFactor,
    tasaOCuota: $tasaOCuota,
    tipoCambio: $tipoCambio,
    moneda: $moneda,
    comprobante: $comprobante,
  ) {
    impuestosTrasladosTotales {
      id
      importe
      impuesto
      tipoFactor
      tasaOCuota
      tipoCambio
      moneda
      comprobante {
        id
      }
    }
  }
}
`;

export const SAVE_IMPUESTOS_TRASLADOS_TOTALES = `
mutation Save(
  $importe: Float!,
  $impuesto: String!,
  $tipoFactor: String!,
  $tasaOCuota: Float!,
  $tipoCambio: Float!,
  $moneda: String!,
  $comprobante: Int!,
)
{
  saveImpuestosTrasladosTotales(
    importe: $importe,
    impuesto: $impuesto,
    tipoFactor: $tipoFactor,
    tasaOCuota: $tasaOCuota,
    tipoCambio: $tipoCambio,
    moneda: $moneda,
    comprobante: $comprobante,
  ) {
    impuestosTrasladosTotales {
      id
    }
  }
}
`;

export const DELETE_IMPUESTOS_TRASLADOS_TOTALES = `
mutation Delete($id: Int!)
{
  deleteImpuestosTrasladosTotales(id: $id)
  {
    id
  }
}
`;

export const NOMINA = `
{
  nomina {
    id
    tipo
    version
    fechaPago
    fechaInicialPago
    fechaFinalPago
    numDiasPagados
    totalPercepciones
    totalDeducciones
    totalOtrosPagos
    emisorRegistroPatronal
    receptorCurp
    receptorNumSeguridadSocial
    receptorFechaInicioRelLaboral
    receptorAntiguedad
    receptorTipoContrato
    receptorSindicalizado
    receptorTipoJornada
    receptorTipoRegimen
    receptorNumEmpleado
    receptorDepartamento
    receptorPuesto
    receptorRiesgoPuesto
    receptorPeriodicidadPago
    receptorSalarioBase
    receptorSalarioDiario
    receptorClaveEntFed
    receptorCuentaBancaria
    receptorBanco
    percepcionesTotalSueldos
    percepcionesTotalGravado
    percepcionesTotalExento
    deduccionesTotalOtras
    deduccionesTotalImpuestosRetenidos
    origin {
      id
    }
  }
}
`;

export const SET_NOMINA = `
mutation Set(
  $id: Int!,
  $tipo: String,
  $version: Float,
  $fechaPago: DateTime,
  $fechaInicialPago: DateTime,
  $fechaFinalPago: DateTime,
  $numDiasPagados: Float,
  $totalPercepciones: Float,
  $totalDeducciones: Float,
  $totalOtrosPagos: Float,
  $emisorRegistroPatronal: String,
  $receptorCurp: String,
  $receptorNumSeguridadSocial: String,
  $receptorFechaInicioRelLaboral: DateTime,
  $receptorAntiguedad: String,
  $receptorTipoContrato: String,
  $receptorSindicalizado: String,
  $receptorTipoJornada: String,
  $receptorTipoRegimen: String,
  $receptorNumEmpleado: String,
  $receptorDepartamento: String,
  $receptorPuesto: String,
  $receptorRiesgoPuesto: String,
  $receptorPeriodicidadPago: String,
  $receptorSalarioBase: Float,
  $receptorSalarioDiario: Float,
  $receptorClaveEntFed: String,
  $receptorCuentaBancaria: String,
  $receptorBanco: String,
  $percepcionesTotalSueldos: Float,
  $percepcionesTotalGravado: Float,
  $percepcionesTotalExento: Float,
  $deduccionesTotalOtras: Float,
  $deduccionesTotalImpuestosRetenidos: Float,
  $origin: Int,
)
{
  setNomina(
    id: $id,
    tipo: $tipo,
    version: $version,
    fechaPago: $fechaPago,
    fechaInicialPago: $fechaInicialPago,
    fechaFinalPago: $fechaFinalPago,
    numDiasPagados: $numDiasPagados,
    totalPercepciones: $totalPercepciones,
    totalDeducciones: $totalDeducciones,
    totalOtrosPagos: $totalOtrosPagos,
    emisorRegistroPatronal: $emisorRegistroPatronal,
    receptorCurp: $receptorCurp,
    receptorNumSeguridadSocial: $receptorNumSeguridadSocial,
    receptorFechaInicioRelLaboral: $receptorFechaInicioRelLaboral,
    receptorAntiguedad: $receptorAntiguedad,
    receptorTipoContrato: $receptorTipoContrato,
    receptorSindicalizado: $receptorSindicalizado,
    receptorTipoJornada: $receptorTipoJornada,
    receptorTipoRegimen: $receptorTipoRegimen,
    receptorNumEmpleado: $receptorNumEmpleado,
    receptorDepartamento: $receptorDepartamento,
    receptorPuesto: $receptorPuesto,
    receptorRiesgoPuesto: $receptorRiesgoPuesto,
    receptorPeriodicidadPago: $receptorPeriodicidadPago,
    receptorSalarioBase: $receptorSalarioBase,
    receptorSalarioDiario: $receptorSalarioDiario,
    receptorClaveEntFed: $receptorClaveEntFed,
    receptorCuentaBancaria: $receptorCuentaBancaria,
    receptorBanco: $receptorBanco,
    percepcionesTotalSueldos: $percepcionesTotalSueldos,
    percepcionesTotalGravado: $percepcionesTotalGravado,
    percepcionesTotalExento: $percepcionesTotalExento,
    deduccionesTotalOtras: $deduccionesTotalOtras,
    deduccionesTotalImpuestosRetenidos: $deduccionesTotalImpuestosRetenidos,
    origin: $origin,
  ) {
    nomina {
      id
      tipo
      version
      fechaPago
      fechaInicialPago
      fechaFinalPago
      numDiasPagados
      totalPercepciones
      totalDeducciones
      totalOtrosPagos
      emisorRegistroPatronal
      receptorCurp
      receptorNumSeguridadSocial
      receptorFechaInicioRelLaboral
      receptorAntiguedad
      receptorTipoContrato
      receptorSindicalizado
      receptorTipoJornada
      receptorTipoRegimen
      receptorNumEmpleado
      receptorDepartamento
      receptorPuesto
      receptorRiesgoPuesto
      receptorPeriodicidadPago
      receptorSalarioBase
      receptorSalarioDiario
      receptorClaveEntFed
      receptorCuentaBancaria
      receptorBanco
      percepcionesTotalSueldos
      percepcionesTotalGravado
      percepcionesTotalExento
      deduccionesTotalOtras
      deduccionesTotalImpuestosRetenidos
      origin {
        id
      }
    }
  }
}
`;

export const SAVE_NOMINA = `
mutation Save(
  $tipo: String!,
  $version: Float!,
  $fechaPago: DateTime,
  $fechaInicialPago: DateTime,
  $fechaFinalPago: DateTime,
  $numDiasPagados: Float!,
  $totalPercepciones: Float!,
  $totalDeducciones: Float!,
  $totalOtrosPagos: Float!,
  $emisorRegistroPatronal: String!,
  $receptorCurp: String!,
  $receptorNumSeguridadSocial: String!,
  $receptorFechaInicioRelLaboral: DateTime,
  $receptorAntiguedad: String!,
  $receptorTipoContrato: String!,
  $receptorSindicalizado: String!,
  $receptorTipoJornada: String!,
  $receptorTipoRegimen: String!,
  $receptorNumEmpleado: String!,
  $receptorDepartamento: String!,
  $receptorPuesto: String!,
  $receptorRiesgoPuesto: String!,
  $receptorPeriodicidadPago: String!,
  $receptorSalarioBase: Float!,
  $receptorSalarioDiario: Float!,
  $receptorClaveEntFed: String!,
  $receptorCuentaBancaria: String!,
  $receptorBanco: String!,
  $percepcionesTotalSueldos: Float!,
  $percepcionesTotalGravado: Float!,
  $percepcionesTotalExento: Float!,
  $deduccionesTotalOtras: Float!,
  $deduccionesTotalImpuestosRetenidos: Float!,
  $origin: Int!,
)
{
  saveNomina(
    tipo: $tipo,
    version: $version,
    fechaPago: $fechaPago,
    fechaInicialPago: $fechaInicialPago,
    fechaFinalPago: $fechaFinalPago,
    numDiasPagados: $numDiasPagados,
    totalPercepciones: $totalPercepciones,
    totalDeducciones: $totalDeducciones,
    totalOtrosPagos: $totalOtrosPagos,
    emisorRegistroPatronal: $emisorRegistroPatronal,
    receptorCurp: $receptorCurp,
    receptorNumSeguridadSocial: $receptorNumSeguridadSocial,
    receptorFechaInicioRelLaboral: $receptorFechaInicioRelLaboral,
    receptorAntiguedad: $receptorAntiguedad,
    receptorTipoContrato: $receptorTipoContrato,
    receptorSindicalizado: $receptorSindicalizado,
    receptorTipoJornada: $receptorTipoJornada,
    receptorTipoRegimen: $receptorTipoRegimen,
    receptorNumEmpleado: $receptorNumEmpleado,
    receptorDepartamento: $receptorDepartamento,
    receptorPuesto: $receptorPuesto,
    receptorRiesgoPuesto: $receptorRiesgoPuesto,
    receptorPeriodicidadPago: $receptorPeriodicidadPago,
    receptorSalarioBase: $receptorSalarioBase,
    receptorSalarioDiario: $receptorSalarioDiario,
    receptorClaveEntFed: $receptorClaveEntFed,
    receptorCuentaBancaria: $receptorCuentaBancaria,
    receptorBanco: $receptorBanco,
    percepcionesTotalSueldos: $percepcionesTotalSueldos,
    percepcionesTotalGravado: $percepcionesTotalGravado,
    percepcionesTotalExento: $percepcionesTotalExento,
    deduccionesTotalOtras: $deduccionesTotalOtras,
    deduccionesTotalImpuestosRetenidos: $deduccionesTotalImpuestosRetenidos,
    origin: $origin,
  ) {
    nomina {
      id
    }
  }
}
`;

export const DELETE_NOMINA = `
mutation Delete($id: Int!)
{
  deleteNomina(id: $id)
  {
    id
  }
}
`;

export const OTRO_PAGO = `
{
  otroPago {
    id
    tipo
    clave
    concepto
    importe
    tipoNombre
    origin {
      id
    }
  }
}
`;

export const SET_OTRO_PAGO = `
mutation Set(
  $id: Int!,
  $tipo: String,
  $clave: String,
  $concepto: String,
  $importe: Float,
  $tipoNombre: String,
  $origin: Int,
)
{
  setOtroPago(
    id: $id,
    tipo: $tipo,
    clave: $clave,
    concepto: $concepto,
    importe: $importe,
    tipoNombre: $tipoNombre,
    origin: $origin,
  ) {
    otroPago {
      id
      tipo
      clave
      concepto
      importe
      tipoNombre
      origin {
        id
      }
    }
  }
}
`;

export const SAVE_OTRO_PAGO = `
mutation Save(
  $tipo: String!,
  $clave: String!,
  $concepto: String!,
  $importe: Float!,
  $tipoNombre: String!,
  $origin: Int!,
)
{
  saveOtroPago(
    tipo: $tipo,
    clave: $clave,
    concepto: $concepto,
    importe: $importe,
    tipoNombre: $tipoNombre,
    origin: $origin,
  ) {
    otroPago {
      id
    }
  }
}
`;

export const DELETE_OTRO_PAGO = `
mutation Delete($id: Int!)
{
  deleteOtroPago(id: $id)
  {
    id
  }
}
`;

export const PAGO = `
{
  pago {
    id
    fechaPago
    formaPago
    monedaP
    monto
    tipoCambioRaw
    tipoCambio
    version
    comprobante {
      id
    }
  }
}
`;

export const SET_PAGO = `
mutation Set(
  $id: Int!,
  $fechaPago: DateTime,
  $formaPago: String,
  $monedaP: String,
  $monto: Float,
  $tipoCambioRaw: Float,
  $tipoCambio: Float,
  $version: Float,
  $comprobante: Int,
)
{
  setPago(
    id: $id,
    fechaPago: $fechaPago,
    formaPago: $formaPago,
    monedaP: $monedaP,
    monto: $monto,
    tipoCambioRaw: $tipoCambioRaw,
    tipoCambio: $tipoCambio,
    comprobante: $comprobante,
    version: $version,
  ) {
    pago {
      id
      fechaPago
      formaPago
      monedaP
      monto
      tipoCambioRaw
      tipoCambio
      version
      comprobante {
        id
      }
    }
  }
}
`;

export const SAVE_PAGO = `
mutation Save(
  $fechaPago: DateTime!,
  $formaPago: String!,
  $monedaP: String!,
  $monto: Float!,
  $tipoCambioRaw: Float!,
  $tipoCambio: Float!,
  $version: Float!,
  $comprobante: Int!,
)
{
  savePago(
    fechaPago: $fechaPago,
    formaPago: $formaPago,
    monedaP: $monedaP,
    monto: $monto,
    tipoCambioRaw: $tipoCambioRaw,
    tipoCambio: $tipoCambio,
    comprobante: $comprobante,
    version: $version,
  ) {
    pago {
      id
    }
  }
}
`;

export const DELETE_PAGO = `
mutation Delete($id: Int!)
{
  deletePago(id: $id)
  {
    id
  }
}
`;

export const PAGOS_TOTALES = `
{
  pagosTotales {
    id
    montoTotalPagos
    totTrasIvaB16
    totTrasImpIva16
    comprobante {
      id
    }
  }
}
`;

export const SET_PAGOS_TOTALES = `
mutation Set(
  $id: Int!,
  $montoTotalPagos: Float,
  $totTrasIvaB16: Float,
  $totTrasImpIva16: Float,
  $comprobante: Int,
)
{
  setPagosTotales(
    id: $id,
    montoTotalPagos: $montoTotalPagos,
    totTrasIvaB16: $totTrasIvaB16,
    totTrasImpIva16: $totTrasImpIva16,
    comprobante: $comprobante,
  ) {
    pagosTotales {
      id
      montoTotalPagos
      totTrasIvaB16
      totTrasImpIva16
      comprobante {
        id
      }
    }
  }
}
`;

export const SAVE_PAGOS_TOTALES = `
mutation Save(
  $montoTotalPagos: Float!,
  $totTrasIvaB16: Float!,
  $totTrasImpIva16: Float!,
  $comprobante: Int!,
)
{
  savePagosTotales(
    montoTotalPagos: $montoTotalPagos,
    totTrasIvaB16: $totTrasIvaB16,
    totTrasImpIva16: $totTrasImpIva16,
    comprobante: $comprobante,
  ) {
    pagosTotales {
      id
    }
  }
}
`;

export const DELETE_PAGOS_TOTALES = `
mutation Delete($id: Int!)
{
  deletePagosTotales(id: $id)
  {
    id
  }
}
`;

export const PERCEPCION = `
{
  percepcion {
    id
    tipo
    clave
    concepto
    tipoNombre
    importeGravado
    importeExento
    origin {
      id
    }
  }
}
`;

export const SET_PERCEPCION = `
mutation Set(
  $id: Int!,
  $tipo: String,
  $clave: String,
  $concepto: String,
  $tipoNombre: String,
  $importeGravado: Float,
  $importeExento: Float,
  $origin: Int,
)
{
  setPercepcion(
    id: $id,
    tipo: $tipo,
    clave: $clave,
    concepto: $concepto,
    tipoNombre: $tipoNombre,
    importeGravado: $importeGravado,
    importeExento: $importeExento,
    origin: $origin,
  ) {
    percepcion {
      id
      tipo
      clave
      concepto
      tipoNombre
      importeGravado
      importeExento
      origin {
        id
      }
    }
  }
}
`;

export const SAVE_PERCEPCION = `
mutation Save(
  $tipo: String!,
  $clave: String!,
  $concepto: String!,
  $tipoNombre: String!,
  $importeGravado: Float!,
  $importeExento: Float!,
  $origin: Int!,
)
{
  savePercepcion(
    tipo: $tipo,
    clave: $clave,
    concepto: $concepto,
    tipoNombre: $tipoNombre,
    importeGravado: $importeGravado,
    importeExento: $importeExento,
    origin: $origin,
  ) {
    percepcion {
      id
    }
  }
}
`;

export const DELETE_PERCEPCION = `
mutation Delete($id: Int!)
{
  deletePercepcion(id: $id)
  {
    id
  }
}
`;

export const PROCESS = `
{
  process {
    id
    clientAuxId
    cfdiRequestId
    status
    description
    errors
    type
    client {
      id
    }
  }
}
`;

export const SET_PROCESS = `
mutation Set(
  $id: Int!,
  $clientAuxId: Int,
  $cfdiRequestId: Int,
  $status: String,
  $description: String,
  $errors: String,
  $type: String,
  $client: Int,
)
{
  setProcess(
    id: $id,
    clientAuxId: $clientAuxId,
    cfdiRequestId: $cfdiRequestId,
    status: $status,
    description: $description,
    errors: $errors,
    type: $type,
    client: $client,
  ) {
    process {
      id
      clientAuxId
      cfdiRequestId
      status
      description
      errors
      type
      client {
        id
      }
    }
  }
}
`;

export const SAVE_PROCESS = `
mutation Save(
  $clientAuxId: Int!,
  $cfdiRequestId: Int!,
  $status: String!,
  $description: String!,
  $errors: String!,
  $type: String!,
  $client: Int,
)
{
  saveProcess(
    clientAuxId: $clientAuxId,
    cfdiRequestId: $cfdiRequestId,
    status: $status,
    description: $description,
    errors: $errors,
    type: $type,
    client: $client,
  ) {
    process {
      id
    }
  }
}
`;

export const DELETE_PROCESS = `
mutation Delete($id: Int!)
{
  deleteProcess(id: $id)
  {
    id
  }
}
`;

export const PROVEEDORES = `
{
  proveedores {
    id
    rfc
    nombre
    biIsInBlacklist
    client {
      id
    }
  }
}
`;

export const SET_PROVEEDORES = `
mutation Set(
  $id: Int!,
  $rfc: String,
  $nombre: String,
  $biIsInBlacklist: String,
  $client: Int,
)
{
  setProveedores(
    id: $id,
    rfc: $rfc,
    nombre: $nombre,
    biIsInBlacklist: $biIsInBlacklist,
    client: $client,
  ) {
    proveedores {
      id
      rfc
      nombre
      biIsInBlacklist
      client {
        id
      }
    }
  }
}
`;

export const SAVE_PROVEEDORES = `
mutation Save(
  $rfc: String!,
  $nombre: String!,
  $biIsInBlacklist: String!,
  $client: Int!,
)
{
  saveProveedores(
    rfc: $rfc,
    nombre: $nombre,
    biIsInBlacklist: $biIsInBlacklist,
    client: $client,
  ) {
    proveedores {
      id
    }
  }
}
`;

export const DELETE_PROVEEDORES = `
mutation Delete($id: Int!)
{
  deleteProveedores(id: $id)
  {
    id
  }
}
`;

export const RETENCIONES_CONCEPTO = `
{
  retencionesConcepto {
    id
    base
    importe
    impuesto
    tipoFactor
    tasaOCuota
    tipoCambio
    moneda
    concepto {
      id
    }
  }
}
`;

export const SET_RETENCIONES_CONCEPTO = `
mutation Set(
  $id: Int!,
  $base: Float,
  $importe: Float,
  $impuesto: String,
  $tipoFactor: String,
  $tasaOCuota: Float,
  $tipoCambio: Float,
  $moneda: String,
  $concepto: Int,
)
{
  setRetencionesConcepto(
    id: $id,
    base: $base,
    importe: $importe,
    impuesto: $impuesto,
    tipoFactor: $tipoFactor,
    tasaOCuota: $tasaOCuota,
    tipoCambio: $tipoCambio,
    moneda: $moneda,
    concepto: $concepto,
  ) {
    retencionesConcepto {
      id
      base
      importe
      impuesto
      tipoFactor
      tasaOCuota
      tipoCambio
      moneda
      concepto {
        id
      }
    }
  }
}
`;

export const SAVE_RETENCIONES_CONCEPTO = `
mutation Save(
  $base: Float!,
  $importe: Float!,
  $impuesto: String!,
  $tipoFactor: String!,
  $tasaOCuota: Float!,
  $tipoCambio: Float!,
  $moneda: String!,
  $concepto: Int!,
)
{
  saveRetencionesConcepto(
    base: $base,
    importe: $importe,
    impuesto: $impuesto,
    tipoFactor: $tipoFactor,
    tasaOCuota: $tasaOCuota,
    tipoCambio: $tipoCambio,
    moneda: $moneda,
    concepto: $concepto,
  ) {
    retencionesConcepto {
      id
    }
  }
}
`;

export const DELETE_RETENCIONES_CONCEPTO = `
mutation Delete($id: Int!)
{
  deleteRetencionesConcepto(id: $id)
  {
    id
  }
}
`;

export const RETENCIONES_DR = `
{
  retencionesDr {
    id
    baseDr
    importeDr
    impuestoDr
    tipoFactorDr
    tasaOCuotaDr
    tipoCambio
    moneda
    docRelacionado {
      id
    }
  }
}
`;

export const SET_RETENCIONES_DR = `
mutation Set(
  $id: Int!,
  $baseDr: Float,
  $importeDr: Float,
  $impuestoDr: String,
  $tipoFactorDr: String,
  $tasaOCuotaDr: Float,
  $tipoCambio: Float,
  $moneda: String,
  $docRelacionado: Int,
)
{
  setRetencionesDr(
    id: $id,
    baseDr: $baseDr,
    importeDr: $importeDr,
    impuestoDr: $impuestoDr,
    tipoFactorDr: $tipoFactorDr,
    tasaOCuotaDr: $tasaOCuotaDr,
    tipoCambio: $tipoCambio,
    moneda: $moneda,
    docRelacionado: $docRelacionado,
  ) {
    retencionesDr {
      id
      baseDr
      importeDr
      impuestoDr
      tipoFactorDr
      tasaOCuotaDr
      tipoCambio
      moneda
      docRelacionado {
        id
      }
    }
  }
}
`;

export const SAVE_RETENCIONES_DR = `
mutation Save(
  $baseDr: Float!,
  $importeDr: Float!,
  $impuestoDr: String!,
  $tipoFactorDr: String!,
  $tasaOCuotaDr: Float!,
  $tipoCambio: Float!,
  $moneda: String!,
  $docRelacionado: Int!,
)
{
  saveRetencionesDr(
    baseDr: $baseDr,
    importeDr: $importeDr,
    impuestoDr: $impuestoDr,
    tipoFactorDr: $tipoFactorDr,
    tasaOCuotaDr: $tasaOCuotaDr,
    tipoCambio: $tipoCambio,
    moneda: $moneda,
    docRelacionado: $docRelacionado,
  ) {
    retencionesDr {
      id
    }
  }
}
`;

export const DELETE_RETENCIONES_DR = `
mutation Delete($id: Int!)
{
  deleteRetencionesDr(id: $id)
  {
    id
  }
}
`;

export const RETENCIONES_LOCALES = `
{
  retencionesLocales {
    id
    impLocRetenido
    tasaDeTraslado
    importe
    tipoCambio
    moneda
    comprobante {
      id
    }
  }
}
`;

export const SET_RETENCIONES_LOCALES = `
mutation Set(
  $id: Int!,
  $impLocRetenido: String,
  $tasaDeTraslado: Float,
  $importe: Float,
  $tipoCambio: Float,
  $moneda: String,
  $comprobante: Int,
)
{
  setRetencionesLocales(
    id: $id,
    impLocRetenido: $impLocRetenido,
    tasaDeTraslado: $tasaDeTraslado,
    importe: $importe,
    comprobante: $comprobante,
    tipoCambio: $tipoCambio,
    moneda: $moneda,
  ) {
    retencionesLocales {
      id
      impLocRetenido
      tasaDeTraslado
      importe
      tipoCambio
      moneda
      comprobante {
        id
      }
    }
  }
}
`;

export const SAVE_RETENCIONES_LOCALES = `
mutation Save(
  $impLocRetenido: String!,
  $tasaDeTraslado: Float!,
  $importe: Float!,
  $tipoCambio: Float!,
  $moneda: String!,
  $comprobante: Int!,
)
{
  saveRetencionesLocales(
    impLocRetenido: $impLocRetenido,
    tasaDeTraslado: $tasaDeTraslado,
    importe: $importe,
    comprobante: $comprobante,
    tipoCambio: $tipoCambio,
    moneda: $moneda,
  ) {
    retencionesLocales {
      id
    }
  }
}
`;

export const DELETE_RETENCIONES_LOCALES = `
mutation Delete($id: Int!)
{
  deleteRetencionesLocales(id: $id)
  {
    id
  }
}
`;

export const RETENCIONES_P = `
{
  retencionesP {
    id
    importeP
    impuestoP
    monedaP
    tipoCambio
    pago {
      id
    }
  }
}
`;

export const SET_RETENCIONES_P = `
mutation Set(
  $id: Int!,
  $importeP: Float,
  $impuestoP: String,
  $monedaP: String,
  $tipoCambio: Float,
  $pago: Int,
)
{
  setRetencionesP(
    id: $id,
    importeP: $importeP,
    impuestoP: $impuestoP,
    monedaP: $monedaP,
    tipoCambio: $tipoCambio,
    pago: $pago,
  ) {
    retencionesP {
      id
      importeP
      impuestoP
      monedaP
      tipoCambio
      pago {
        id
      }
    }
  }
}
`;

export const SAVE_RETENCIONES_P = `
mutation Save(
  $importeP: Float!,
  $impuestoP: String!,
  $monedaP: String!,
  $tipoCambio: Float!,
  $pago: Int!,
)
{
  saveRetencionesP(
    importeP: $importeP,
    impuestoP: $impuestoP,
    monedaP: $monedaP,
    tipoCambio: $tipoCambio,
    pago: $pago,
  ) {
    retencionesP {
      id
    }
  }
}
`;

export const DELETE_RETENCIONES_P = `
mutation Delete($id: Int!)
{
  deleteRetencionesP(id: $id)
  {
    id
  }
}
`;

export const RETENCIONES_TOTALES = `
{
  retencionesTotales {
    id
    importe
    impuesto
    tipoFactor
    tasaOCuota
    moneda
    tipoCambio
    comprobante {
      id
    }
  }
}
`;

export const SET_RETENCIONES_TOTALES = `
mutation Set(
  $id: Int!,
  $importe: Float,
  $impuesto: String,
  $tipoFactor: String,
  $tasaOCuota: Float,
  $moneda: String,
  $tipoCambio: Float,
  $comprobante: Int,
)
{
  setRetencionesTotales(
    id: $id,
    importe: $importe,
    impuesto: $impuesto,
    tipoFactor: $tipoFactor,
    tasaOCuota: $tasaOCuota,
    moneda: $moneda,
    comprobante: $comprobante,
    tipoCambio: $tipoCambio,
  ) {
    retencionesTotales {
      id
      importe
      impuesto
      tipoFactor
      tasaOCuota
      moneda
      tipoCambio
      comprobante {
        id
      }
    }
  }
}
`;

export const SAVE_RETENCIONES_TOTALES = `
mutation Save(
  $importe: Float!,
  $impuesto: String!,
  $tipoFactor: String!,
  $tasaOCuota: Float!,
  $moneda: String!,
  $tipoCambio: Float!,
  $comprobante: Int!,
)
{
  saveRetencionesTotales(
    importe: $importe,
    impuesto: $impuesto,
    tipoFactor: $tipoFactor,
    tasaOCuota: $tasaOCuota,
    moneda: $moneda,
    comprobante: $comprobante,
    tipoCambio: $tipoCambio,
  ) {
    retencionesTotales {
      id
    }
  }
}
`;

export const DELETE_RETENCIONES_TOTALES = `
mutation Delete($id: Int!)
{
  deleteRetencionesTotales(id: $id)
  {
    id
  }
}
`;

export const TC = `
{
  tc {
    id
    amount
    day
    month
    year
  }
}
`;

export const SET_TC = `
mutation Set(
  $id: Int!,
  $amount: Float,
  $day: Int,
  $month: Int,
  $year: Int,
)
{
  setTc(
    id: $id,
    amount: $amount,
    day: $day,
    month: $month,
    year: $year,
  ) {
    tc {
      id
      amount
      day
      month
      year
    }
  }
}
`;

export const SAVE_TC = `
mutation Save(
  $amount: Float!,
  $day: Int!,
  $month: Int!,
  $year: Int!,
)
{
  saveTc(
    amount: $amount,
    day: $day,
    month: $month,
    year: $year,
  ) {
    tc {
      id
    }
  }
}
`;

export const DELETE_TC = `
mutation Delete($id: Int!)
{
  deleteTc(id: $id)
  {
    id
  }
}
`;

export const TRASLADO_DR = `
{
  trasladoDr {
    id
    baseDr
    impuestoDr
    tipoFactorDr
    tasaOCuotaDr
    importeDr
    moneda
    tipoCambio
    docRelacionado {
      id
    }
  }
}
`;

export const SET_TRASLADO_DR = `
mutation Set(
  $id: Int!,
  $baseDr: Float,
  $impuestoDr: String,
  $tipoFactorDr: String,
  $tasaOCuotaDr: Float,
  $importeDr: Float,
  $moneda: String,
  $tipoCambio: Float,
  $docRelacionado: Int,
)
{
  setTrasladoDr(
    id: $id,
    baseDr: $baseDr,
    impuestoDr: $impuestoDr,
    tipoFactorDr: $tipoFactorDr,
    tasaOCuotaDr: $tasaOCuotaDr,
    importeDr: $importeDr,
    moneda: $moneda,
    tipoCambio: $tipoCambio,
    docRelacionado: $docRelacionado,
  ) {
    trasladoDr {
      id
      baseDr
      impuestoDr
      tipoFactorDr
      tasaOCuotaDr
      importeDr
      moneda
      tipoCambio
      docRelacionado {
        id
      }
    }
  }
}
`;

export const SAVE_TRASLADO_DR = `
mutation Save(
  $baseDr: Float!,
  $impuestoDr: String!,
  $tipoFactorDr: String!,
  $tasaOCuotaDr: Float!,
  $importeDr: Float!,
  $moneda: String!,
  $tipoCambio: Float!,
  $docRelacionado: Int!,
)
{
  saveTrasladoDr(
    baseDr: $baseDr,
    impuestoDr: $impuestoDr,
    tipoFactorDr: $tipoFactorDr,
    tasaOCuotaDr: $tasaOCuotaDr,
    importeDr: $importeDr,
    moneda: $moneda,
    tipoCambio: $tipoCambio,
    docRelacionado: $docRelacionado,
  ) {
    trasladoDr {
      id
    }
  }
}
`;

export const DELETE_TRASLADO_DR = `
mutation Delete($id: Int!)
{
  deleteTrasladoDr(id: $id)
  {
    id
  }
}
`;

export const TRASLADO_P = `
{
  trasladoP {
    id
    baseP
    impuestoP
    tipoFactorP
    tasaOCuotaP
    importeP
    moneda
    tipoCambio
    pago {
      id
    }
  }
}
`;

export const SET_TRASLADO_P = `
mutation Set(
  $id: Int!,
  $baseP: Float,
  $impuestoP: String,
  $tipoFactorP: String,
  $tasaOCuotaP: Float,
  $importeP: Float,
  $moneda: String,
  $tipoCambio: Float,
  $pago: Int,
)
{
  setTrasladoP(
    id: $id,
    baseP: $baseP,
    impuestoP: $impuestoP,
    tipoFactorP: $tipoFactorP,
    tasaOCuotaP: $tasaOCuotaP,
    importeP: $importeP,
    moneda: $moneda,
    tipoCambio: $tipoCambio,
    pago: $pago,
  ) {
    trasladoP {
      id
      baseP
      impuestoP
      tipoFactorP
      tasaOCuotaP
      importeP
      moneda
      tipoCambio
      pago {
        id
      }
    }
  }
}
`;

export const SAVE_TRASLADO_P = `
mutation Save(
  $baseP: Float!,
  $impuestoP: String!,
  $tipoFactorP: String!,
  $tasaOCuotaP: Float!,
  $importeP: Float!,
  $moneda: String!,
  $tipoCambio: Float!,
  $pago: Int!,
)
{
  saveTrasladoP(
    baseP: $baseP,
    impuestoP: $impuestoP,
    tipoFactorP: $tipoFactorP,
    tasaOCuotaP: $tasaOCuotaP,
    importeP: $importeP,
    moneda: $moneda,
    tipoCambio: $tipoCambio,
    pago: $pago,
  ) {
    trasladoP {
      id
    }
  }
}
`;

export const DELETE_TRASLADO_P = `
mutation Delete($id: Int!)
{
  deleteTrasladoP(id: $id)
  {
    id
  }
}
`;

export const USER = `
{
  user {
    id
    username
    firstName
    lastName
    email
    isActive
  }
}
`;

export const SET_USER = `
mutation Set(
  $id: Int!,
  $username: String,
  $firstName: String,
  $lastName: String,
  $email: String,
  $isActive: Boolean,
  $password: String,
)
{
  setUser(
    id: $id,
    username: $username,
    firstName: $firstName,
    lastName: $lastName,
    email: $email,
    isActive: $isActive,
    password: $password,
  ) {
    user {
      id
      username
      firstName
      lastName
      email
      isActive
    }
  }
}
`;

export const SAVE_USER = `
mutation Save(
  $username: String!,
  $firstName: String!,
  $lastName: String!,
  $email: String!,
  $isActive: Boolean!,
  $password: String!,
)
{
  saveUser(
    username: $username,
    firstName: $firstName,
    lastName: $lastName,
    email: $email,
    isActive: $isActive,
    password: $password,
  ) {
    user {
      id
    }
  }
}
`;

export const DELETE_USER = `
mutation Delete($id: Int!)
{
  deleteUser(id: $id)
  {
    id
  }
}
`;