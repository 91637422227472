import React, { useEffect, useState } from "react";
import View from "components/clients/filters/Form.view";
import { useQuery } from "seed/gql";
import { usePost } from "seed/api";
import swal from 'sweetalert';

function Form(props) {
    const clientID = props.match.params.clientId;

    const [selectedType, setSelectedType] = useState("UUID");

    const [callDownload, reqCallDownload] = usePost("/filters/download_template", {
        onCompleted: (data) => {
          const elem = window.document.createElement('a');
          elem.href = data.url;
          elem.download = "filters_template.xlsx";
          document.body.appendChild(elem);
          elem.click();
          document.body.removeChild(elem);
        },
        onError: (error) => { 
          console.error(error);
          alert("Error al descargar el formato de filtro")
        }
    });

    const [callFilter, setCallFilter] = usePost("/filters/create_filter", {
        onCompleted: (data) => {
            if(data["completed"] == true) {
                swal("Filtro creado", data["message"], "success").then(() => {
                    window.location = "/home/clients"
                });
            } else {
                swal("Error", data["message"], "error");
                return
            }
        },
        onError: () => null,
    });

    const handleTypeChange = (e) => {
        setSelectedType(e.target.value);
    };
    
    const onDownloadExcel = () => callDownload();

    const onSubmit = (values) => {

        if (selectedType === "UUID") {
            values.type = "UUID";
        } else if (selectedType === "FOLIO") {
            values.type = "FOLIO";
        }

        values.client_id = parseInt(clientID);
        delete values.uuid;
        delete values.template;

        callFilter(values);

    };

    return (
        <View
        selectedType={selectedType}
        handleTypeChange={handleTypeChange}
        onDownloadExcel={onDownloadExcel}
        onSubmit={onSubmit}
        />
    );
}

Form.propTypes = {};

export default Form;
