import React, { useState } from "react";
import View from "components/dev/Reports.view";
import { usePagination, useQuery } from "seed/gql";
import { usePost, useGetCall } from "seed/api";
import ExcelJS from "exceljs";

const getDownloadFilters = (filters) => {
    
  const clients = filters["clientFilter"];
  const types = filters["typeFilter"];
  const period = filters["dateFilter"];
  
  const queriesClients = [];
  const queriesType = [];
  const queriesDate = [];

  for (let id of clients) {
      queriesClients.push(`cfdiRequest.client.id=${id}`);
  }

  for (let type of types) {
      queriesType.push(`${type}`)
  }

  if (period["startDate"]) {
      queriesDate.push(`cfdiRequest.startDate>=${period["startDate"]}`)
  }

  if (period["endDate"]) {
      queriesDate.push(`cfdiRequest.endDate<=${period["endDate"]}`)
  }

  const queries = []
  if (queriesClients.length >= 2) queries.push("(" + queriesClients.join(" OR ") + ")");
  else if (queriesClients.length == 1) queries.push(queriesClients.join(""));

  if (queriesType.length >= 2) queries.push("(" + queriesType.join(" OR ") + ")");
  else if (queriesType.length == 1) queries.push(queriesType.join(""));
  
  if (queriesDate.length >= 2) queries.push("(" + queriesDate.join(" AND ") + ")");
  else if (queriesDate.length == 1) queries.push(queriesDate.join(""));

  return queries.join(" AND ");

}

function Reports() {

  const userId = sessionStorage.getItem("id");
  const userType = sessionStorage.getItem("user");
  const pageSize = 30;
  const [pageNum, setPageNum] = useState(1);

  const [search, setSearch] = useState("")
  const [typeFilter, setTypeFilter] = useState([])
  const [clientFilter, setClientFilter] = useState([]);
  const [dateFilter, setDateFilter] = useState({ startDate: null, endDate: null });
  const [openAccordion, setOpenAccordion] = useState(null);
  const [detailsRequest, setDetailsRequest] = useState(null);
  const [isExportShown, setIsExportShown] = useState(false);

  let filterString = "";
  filterString = getDownloadFilters({ clientFilter, typeFilter, dateFilter });

  if(search != "") {
    filterString += (filterString.length > 0 ? " AND " : "") 
    filterString = `(cfdiRequest.client.rfc ILIKE ${search} OR comprobantes.folio ILIKE ${search} OR comprobantes.tfdUuid ILIKE ${search})`;
    if(!isNaN(parseInt(search)))
      filterString += ` OR (cfdiRequest.id=${search} OR id=${search})`;
  }

  const qCFDIsRequests = usePagination(`{
    reportPagination {
      totalPages
      totalCount
      reports {
        cfdiRequest {
          createdAt
          type
          startDate
          endDate
          dataType
          generalStatus
          codeStatus
          isAutoDownload
          metadata
          rawDownload {
            name
            url
          }
          client {
            name
            rfc
          }
        }
        comprobantes {
          tfdUuid
          folio
          fecha
          emisorUid
          receptorUid
          noCertificado
        }
      }
    }
  }`, pageNum, pageSize, `(${filterString})`, { orderBy: "-created_at"});

  const reqClients = useQuery(`{
    clients {
      name
      rfc
    }
  }`, {}, {orderBy: "name"});

  const [callGetExport, reqGetExport] = usePost(`/reports/export`, {
    onCompleted: async (data) => {

      const workbook = new ExcelJS.Workbook();

      data.forEach((report, idx) => {
        
        const worksheet = workbook.addWorksheet(report.client.name);

        report.data.forEach((row, rowIdx) => {
          worksheet.addRow(row.map((obj) => obj.data));
          const lastRow = worksheet.lastRow;
          row.forEach((obj, colIdx) => {
            if(rowIdx == 0)
              lastRow.getCell(colIdx + 1).font = { bold: true };
            if(obj.color)
              lastRow.getCell(colIdx + 1).font = { color: { argb: obj.color } };
          });
        });

      });

      const file = await workbook.xlsx.writeBuffer();
      const type = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      const blob = new Blob([file], { type });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "reporte.xlsx";
      a.click();

    }
  });

  const [callRetry, reqRetry] = usePost("/cfdi_requests/retry_request", {
    onCompleted: (data) => {
      alert("Solicitud reintentada con éxito");
      qCFDIsRequests.refetch();
    },
    onError: (error) => {
      alert("Error al reintentar la descarga");
    }
  });

  const [callVerify, reqVerify] = usePost("/cfdi_requests/verify_request", {
    onCompleted: (data) => {
      alert("Verificación realizada con éxito");
      qCFDIsRequests.refetch();
    },
    onError: (error) => {
      alert("Error al verificar la petición");
    }
  });

  const onChangeClient = (id) => {
    const newFilters = [...clientFilter];
    const index = newFilters.findIndex((filter) => filter == id);

    if (index == -1) {
      newFilters.push(id);
    } else {
      newFilters.splice(index, 1);
    }

    setClientFilter(newFilters)
  }

  const onChangeType = (status) => {
    const newFilters = [...typeFilter];
    const index = newFilters.findIndex((filter) => filter == status);

    if (index == -1) {
      newFilters.push(status);
    } else {
      newFilters.splice(index, 1);
    }

    setTypeFilter(newFilters)
  }

  const onChangeDate = (date, type) => {
    const newDateFilter = {...dateFilter};
    if (date == "") newDateFilter[type] = null;
    else newDateFilter[type] = date;
    setDateFilter(newDateFilter);
  }

  const onExport = (values) => {
    callGetExport(values);
  }

  const onRetry = (requestId) => {
    callRetry({ request_id: requestId });
    setOpenAccordion(requestId);
  }

  const onVerify = (requestId) => {
    callVerify({ request_id: requestId });
    setOpenAccordion(requestId);
  }

  const { clients = [] } = reqClients.data;

  const {
    reports = [],
    totalPages = 0,
    totalCount = 0,
  } = qCFDIsRequests?.data?.reportPagination || {};

  return <View 
    openAccordion={openAccordion}
    clients={clients}
    reports={reports}
    pageNum={pageNum}
    totalPages={totalPages}
    totalCount={totalCount}
    clientFilter={clientFilter}
    typeFilter={typeFilter}
    dateFilter={dateFilter}
    detailsRequest={detailsRequest}
    search={search}
    isExportShown={isExportShown}
    onChangeClient={onChangeClient}
    onChangeType={onChangeType}
    onChangeDate={onChangeDate}    
    onClickPage={setPageNum}
    onExport={onExport}
    onRetry={onRetry}
    onVerify={onVerify}
    setOpenAccordion={setOpenAccordion}
    setDetailsRequest={setDetailsRequest}
    setSearch={setSearch}
    setIsExportShown={setIsExportShown}
  />;
}

Reports.propTypes = {};

export default Reports;