import React, { useState } from "react";
import PropTypes from "prop-types";
import View from "components/dashboard/Frame.view";
import { useQuery } from "seed/gql";
import { useGet } from "seed/api";
import { Loading } from "seed/helpers";


const defaultDashboardUrl = "https://app.powerbi.com/reportEmbed?reportId=48464927-36b4-421e-92da-3ff1629c79cf&autoAuth=true&ctid=9dc46dac-dcca-4fac-8caa-13187325af21&pageName=ReportSection"

function Frame(props) {

  const [filters, setFilters] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [filterGroup, setFilterGroup] = useState({});
  const [queryModified, setQueryModified] = useState("");
  const [boolQuery, setBoolQuery] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showClientSelect, setShowClientSelect] = useState(true);
  const [dashboardUrl, setDashboardUrl] = useState("");


  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const { dashboard = "" } = props.match.params;
  const userId = sessionStorage.getItem("id");
  const userType = sessionStorage.getItem("user");

  let queryGql = "";

  if (userType != "SUPER_ADMIN") queryGql += `client_users.user.id = ${userId}`;

  const reqClient = useQuery(`{
    clients {
      id
      name
      urlDashboard
    }
  }`, queryGql, {
    orderBy: "name",
    onCompleted: (data) => {
      let urlDashboards = {};
      for (let client of data.clients) {
        urlDashboards[client.urlDashboard] = true
      }
      if (Object.keys(urlDashboards).length > 1){
        setShowClientSelect(true);
      }else{
        setShowClientSelect(false);
        setDashboardUrl(defaultDashboardUrl)
      }
    }
  });

  const reqFilter = useGet("/filters/get_filters", {
    user_id: userId,
  }, {
    onCompleted: (data) => {
      setFilters(data.filters);
      if (data.filters) {
        setShowFilters(true)
        const groupedFilters = {};
        (data.filters).forEach((filter) => {
          const { client_name, id, type,  name, url_dashboard } = filter;
          if (!groupedFilters[client_name]) {
            groupedFilters[client_name] = [];
          }
          groupedFilters[client_name].push({ id, type, name, url_dashboard });
        });
        setFilterGroup(groupedFilters);
      }
    }
  });

  if (reqClient.loading) return <Loading />;
  if (reqClient.error) return "Error";

  if (reqFilter.loading) return <Loading />;
  if (reqFilter.error) return "Error";

  const { clients = [] } = reqClient.data;

  let query = "";
  if (userType != "SUPER_ADMIN") {
    let clientFilters = []
    clients.forEach((client) => {
      clientFilters.push(client.id)
    })
    query += "&filter=_client/id in (" + clientFilters.join(",") + ")";
  }
  if (userType != "ADMIN" && userType != "SUPER_ADMIN") {
    switch (userType) {
      case "CLIENT_NO_NOMINA":
        query += encodeURI(` and _comprobante/tipo_comprobante in ('E - Egreso', 'I - Ingreso', 'P - Pago', 'T - Traslado')`);
        break;

      case "CLIENT_NO_PAGO":
        query += encodeURI(` and _comprobante/tipo_comprobante in ('E - Egreso', 'I - Ingreso', 'N - Nómina', 'T - Traslado')`);
        break;

      case "CLIENT_NO_NOMINA_PAGO":
        query += encodeURI(` and _comprobante/tipo_comprobante in ('E - Egreso', 'I - Ingreso', 'T - Traslado')`);
        break;

      default:
        query += ""
        break;

    }
  }


  const redirectDashboard = (dashboardUrl) => {
    let url = `${dashboardUrl}&language=es-MX${boolQuery ? queryModified : query}`
    window.open(url, '_blank')
  }

  const changeQuery = (filterId) => {

    if (filterId == "na")
      return

    let updatedQuery = "";

    const filter = filters.find((filter) => filter.id === parseInt(filterId, 10));
    if (filter) {
      updatedQuery += encodeURI(`&filter=_comprobante/id in (${filter.ids})`);
      setBoolQuery(true);
      setDashboardUrl(filter.url_dashboard == "" ? defaultDashboardUrl : filter.url_dashboard)
      setShowClientSelect(false)
      setShowModal(false)
      setQueryModified(updatedQuery);
    }
  };



  const onChangeSelect = (value) => {
    if (value == "") {
      setShowClientSelect(false);
      setDashboardUrl(defaultDashboardUrl);
    } else if (value != "na") {
      setShowClientSelect(false);
      setDashboardUrl(value)
    }
  }


  return <View
    query={query}
    queryModified={queryModified}
    clients={clients}
    dashboardUrl={dashboardUrl}
    showFilters={showFilters}
    showClientSelect={showClientSelect}
    boolQuery={boolQuery}
    filterGroup={filterGroup}
    filters={filters}
    onChangeSelect={onChangeSelect}
    handleCloseModal={handleCloseModal}
    handleShowModal={handleShowModal}
    changeQuery={changeQuery}
    showModal={showModal}
  />;
}

Frame.propTypes = {};

export default Frame;