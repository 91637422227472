import React from "react";
import { APP_URL } from "settings";
import PropTypes from "prop-types";

const InformationView = ({ type = "" }) => (

  <div class="card mb-3 mb-lg-5 px-3 py-2">
    <div class="card-header card-header-content-sm-between">
      <h3 class="card-header-title mb-2 mb-sm-0">Materiales de apoyo</h3>
    </div>

    <div class="card-body">

      <div className="mt-3 mb-3 mx-1">
        <div className="row">
          <div className="col-md-6">
            <h4>Manuales de operación del sistema</h4>
          </div>
        </div>
      </div>

      <ul class="list-group">

        {type == "ADMIN" || type == "SUPER_ADMIN" ? (
          <li class="list-group-item mb-4">
            <div class="row align-items-center">
              <div class="col-auto">
                <img class="avatar avatar-xs avatar-4x3"
                  src={`${APP_URL}/theme/svg/logos/pdf-icon.svg`} alt="PDF icon" />
              </div>
              <div class="col">
                <h5 class="mb-0">
                  <a class="text-dark" href={`${APP_URL}/resources/manuals/VisorCFDIs-ManualCompleto.pdf`}
                    target="_blank" rel="noreferrer">
                    Manual completo
                  </a>
                </h5>
              </div>
              <div class="col-auto">
                <a class="btn btn-primary btn-sm" target="_blank" rel="noreferrer"
                  href={`${APP_URL}/resources/manuals/VisorCFDIs-ManualCompleto.pdf`} >
                  Descargar
                </a>
              </div>
            </div>
          </li>
        ) : null}

        <li class="list-group-item">
          <div class="row align-items-center">
            <div class="col-auto">
              <img class="avatar avatar-xs avatar-4x3" src={`${APP_URL}/theme/svg/logos/pdf-icon.svg`} alt="PDF icon" />
            </div>
            <div class="col">
              <h5 class="mb-0">
                <a class="text-dark" href={`${APP_URL}/resources/manuals/VisorCFDIs-ManualDashboard.pdf`}
                  target="_blank" rel="noreferrer">
                  1. Dashboard
                </a>
              </h5>
            </div>
            <div class="col-auto">
              <a class="btn btn-primary btn-sm" target="_blank" rel="noreferrer"
                href={`${APP_URL}/resources/manuals/VisorCFDIs-ManualDashboard.pdf`} >
                Descargar
              </a>
            </div>
          </div>
        </li>

        {type == "SUPER_ADMIN" ? (

          <li class="list-group-item">
            <div class="row align-items-center">
              <div class="col-auto">
                <img class="avatar avatar-xs avatar-4x3"
                  src={`${APP_URL}/theme/svg/logos/pdf-icon.svg`} alt="PDF icon" />
              </div>
              <div class="col">
                <h5 class="mb-0">
                  <a class="text-dark" href={`${APP_URL}/resources/manuals/VisorCFDIs-ManualManejoDeClientes.pdf`}
                    target="_blank" rel="noreferrer">
                    2. Manejo de clientes
                  </a>
                </h5>
              </div>
              <div class="col-auto">
                <a class="btn btn-primary btn-sm" target="_blank" rel="noreferrer"
                  href={`${APP_URL}/resources/manuals/VisorCFDIs-ManualManejoDeClientes.pdf`} >
                  Descargar
                </a>
              </div>
            </div>
          </li>
        ) : null}

        {type != "CLIENT" ? (
          <>
            <li class="list-group-item">
              <div class="row align-items-center">
                <div class="col-auto">
                  <img class="avatar avatar-xs avatar-4x3"
                    src={`${APP_URL}/theme/svg/logos/pdf-icon.svg`} alt="PDF icon" />
                </div>
                <div class="col">
                  <h5 class="mb-0">
                    <a class="text-dark" href={`${APP_URL}/resources/manuals/VisorCFDIs-ManualCargaDeDatos.pdf`}
                      target="_blank" rel="noreferrer">
                      3. Carga de datos
                    </a>
                  </h5>
                </div>
                <div class="col-auto">
                  <a class="btn btn-primary btn-sm" target="_blank" rel="noreferrer"
                    href={`${APP_URL}/resources/manuals/VisorCFDIs-ManualCargaDeDatos.pdf`} >
                    Descargar
                  </a>
                </div>
              </div>
            </li>

            <li class="list-group-item">
              <div class="row align-items-center">
                <div class="col-auto">
                  <img class="avatar avatar-xs avatar-4x3"
                    src={`${APP_URL}/theme/svg/logos/pdf-icon.svg`} alt="PDF icon" />
                </div>
                <div class="col">
                  <h5 class="mb-0">
                    <a class="text-dark" href={`${APP_URL}/resources/manuals/VisorCFDIs-ManualPeticionesAlSAT.pdf`}
                      target="_blank" rel="noreferrer">
                      4. Peticiones al SAT
                    </a>
                  </h5>
                </div>
                <div class="col-auto">
                  <a class="btn btn-primary btn-sm" target="_blank" rel="noreferrer"
                    href={`${APP_URL}/resources/manuals/VisorCFDIs-ManualPeticionesAlSAT.pdf`} >
                    Descargar
                  </a>
                </div>
              </div>
            </li>




            <li class="list-group-item">
              <div class="row align-items-center">
                <div class="col-auto">
                  <img class="avatar avatar-xs avatar-4x3"
                    src={`${APP_URL}/theme/svg/logos/pdf-icon.svg`} alt="PDF icon" />
                </div>
                <div class="col">
                  <h5 class="mb-0">
                    <a class="text-dark" href={`${APP_URL}/resources/manuals/VisorCFDIs-ManualDescargaDeDatosLocal.pdf`}
                      target="_blank" rel="noreferrer">
                      5. Descarga de datos local
                    </a>
                  </h5>
                </div>
                <div class="col-auto">
                  <a class="btn btn-primary btn-sm" target="_blank" rel="noreferrer"
                    href={`${APP_URL}/resources/manuals/VisorCFDIs-ManualDescargaDeDatosLocal.pdf`} >
                    Descargar
                  </a>
                </div>
              </div>
            </li>


            <li class="list-group-item">
              <div class="row align-items-center">
                <div class="col-auto">
                  <img class="avatar avatar-xs avatar-4x3"
                    src={`${APP_URL}/theme/svg/logos/pdf-icon.svg`} alt="PDF icon" />
                </div>
                <div class="col">
                  <h5 class="mb-0">
                    <a class="text-dark" href={`${APP_URL}/resources/manuals/VisorCFDIs-ManualDescargaDeDatosPDF.pdf`}
                      target="_blank" rel="noreferrer">
                      6. Descarga de datos PDF
                    </a>
                  </h5>
                </div>
                <div class="col-auto">
                  <a class="btn btn-primary btn-sm" target="_blank" rel="noreferrer"
                    href={`${APP_URL}/resources/manuals/VisorCFDIs-ManualDescargaDeDatosPDF.pdf`} >
                    Descargar
                  </a>
                </div>
              </div>
            </li>


          </>
        ) : null}





      </ul>
    </div>
  </div>
);

InformationView.propTypes = {
  type: PropTypes.string
};

export default InformationView;