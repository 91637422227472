const applyFilter = (key, filters) => filters[key] != null && filters[key] != "all" && filters[key] != "";

const deleteDuplicates = (array) => {
    const hashmap = {}; 
    const newArray = [];

    array.forEach(element => {
        if (!hashmap[element]) {
            newArray.push(element)
            hashmap[element] = true;
        }
    })

    return newArray;
}

const deleteDuplicatesObject = (array, key) => {
    const hashmap = {}; 
    const newArray = [];
    array.forEach(element => {
        if (!hashmap[element[key]]) {
            newArray.push(element)
            hashmap[element[key]] = true;
        }
    })
    return newArray;
}

const getComprobantesFilters = (filters) => {
    const queries = [];

    if (applyFilter("client", filters))
        queries.push(`client.id = ${filters.client}`)

    if (applyFilter("provider", filters))
        queries.push(`(emisorRfc = ${filters.provider} OR receptorRfc = ${filters.provider})`)
    
    if (applyFilter("month", filters)){
        const data = JSON.parse(filters.month)
        // eslint-disable-next-line max-len
        queries.push(`(fecha >= ${data.year}-${data.month}-01 AND fecha < ${data.year}-${parseInt(data.month) + 1}-01)`);
    }

    if (applyFilter("status", filters))
        queries.push(`biEmitidoRecibido = ${filters.status}`);

    return queries.join(" AND ");
} 

const getClientFilter = (clients) => {
    const queries = [];
    for (let id of clients) {
        queries.push(`client.id=${id}`)
    }
    return queries.join(" OR ")
}


const getDownloadFilters = (filters) => {
    
    const clients = filters["clientFilter"];
    const types = filters["typeFilter"];
    const period = filters["dateFilter"];
    
    const queriesClients = [];
    const queriesType = [];
    const queriesDate = [];

    for (let id of clients) {
        queriesClients.push(`client.id=${id}`);
    }

    for (let type of types) {
        queriesType.push(`${type}`)
    }

    if (period["startDate"]) {
        queriesDate.push(`startDate>=${period["startDate"]}`)
    }

    if (period["endDate"]) {
        queriesDate.push(`endDate<=${period["endDate"]}`)
    }

    const queries = []
    if (queriesClients.length >= 2) queries.push("(" + queriesClients.join(" OR ") + ")");
    else if (queriesClients.length == 1) queries.push(queriesClients.join(""));

    if (queriesType.length >= 2) queries.push("(" + queriesType.join(" OR ") + ")");
    else if (queriesType.length == 1) queries.push(queriesType.join(""));
    
    if (queriesDate.length >= 2) queries.push("(" + queriesDate.join(" AND ") + ")");
    else if (queriesDate.length == 1) queries.push(queriesDate.join(""));

    return queries.join(" AND ");

}


export { deleteDuplicates, deleteDuplicatesObject, getComprobantesFilters, getClientFilter, getDownloadFilters }

