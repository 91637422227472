import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import View from "components/export_cfdi/ExportCFDI.view";
import { usePost } from "seed/api";
import { stringToDate } from "components/utils/date";

function ExportCFDI({ defaultClientId = 0, onCompleted = () => null, clients, providers }) {

  const [errorInput, setErrorInput] = useState(null);
  
  const [callZip, reqZip] = usePost("/comprobantes/download_cfdis", {
    onCompleted: (data) => {
      if (data["status"] == "OK") {
        window.location.href = data["message"];
        onCompleted();
      }
    }
  });

  const today = new Date();
  const year = today.getFullYear();
  const month = ('0' + (today.getMonth() + 1)).slice(-2);
  const day = ('0' + today.getDate()).slice(-2);

  const startDate = `${year}-${month}-01`;
  const endDate = `${year}-${month}-${day}`;

  const onSubmit = (values) => {

    const clientId = values["client_id"];
    let start_date = values["start_date"];
    let end_date = values["end_date"];
    const provider = values["provider"];
    const status = values["status"];

    if(!start_date) start_date = new Date('1995-12-17T03:24:00')
    else start_date = stringToDate(start_date, "yyyy-mm-dd", "-")
        
    if(!end_date) end_date = new Date()
    else end_date = stringToDate(end_date, "yyyy-mm-dd", "-")

    if(start_date > end_date) {
      setErrorInput("La fecha inicial debe ser menor a la fecha final");
      return;
    }

    start_date = start_date.toISOString().substr(0, 10)
    end_date = end_date.toISOString().substr(0, 10)

    setErrorInput(null);
    callZip({
      client_id: clientId,
      start_date: start_date,
      end_date: end_date,
      provider: provider,
      status: status
    });

  };

  const error = reqZip.error ? "Ha ocurrido un error" : null;
  const loading = reqZip.loading;

  return <View
    clients={clients}
    providers={providers}
    defaultStartDate={startDate}
    defaultEndDate={endDate}
    defaultClientId={defaultClientId}
    error={error}
    loading={loading}
    errorInput={errorInput}
    onSubmit={onSubmit} />;
}

ExportCFDI.propTypes = {
  clientId: PropTypes.number.isRequired,
  onCompleted: PropTypes.func
};

export default ExportCFDI;
