import React from "react";
import Footer from "components/helpers/Footer";
import moment from "moment/moment";
import { Modal } from "seed/helpers";
import CFDIsDetails from "components/download/Details";
import "moment/locale/es";
moment.locale("es");

const DownloadsView = ({ 
  openAccordion,
  detailsRequest,
  requests = [], 
  clients = [],
  pageNum = 1, 
  totalCount = 1, 
  totalPages = 0,
  clientFilter = [],
  typeFilter = [],
  dateFilter = { startDate: null, endDate: null },
  search = "",
  onChangeClient = () => { },
  onChangeType = () => { },
  onChangeDate = () => { },
  onClickPage = () => { },
  onExport = () => { },
  onRetry = () => { },
  onVerify = () => { },
  setOpenAccordion = () => { },
  setDetailsRequest = () => { },
  setSearch = () => { },
}) => (
  <div class="card mb-3 mb-lg-5 px-3 py-2">

    <div class="card-header d-flex justify-content-between">
      <h3>Descargas SAT (DEV)</h3>
      <div className="d-flex">
        <input
          class="form-control mr-2"
          type="text"
          placeholder="Buscar por ID..."
          value={search}
          onChange={(event) => setSearch(event.target.value)}
        />
        <button class="btn btn-primary d-flex" onClick={onExport}>
          <i class="fas fa-file-export mr-2 mt-1"></i> Exportar
        </button>
      </div>
    </div>

    <div class="card-body">

      <div className="row border font-weight-bold mx-1">

        <div className="col-md-3 p-2 text-center">
          <div class="dropdown" style={{ marginTop: "-10px", marginBottom: "-10px", zIndex: "100" }}>

            <a class="btn btn-link dropdown-toggle"
              href="#"
              role="button"
              id="statusFilterDropdown"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              style={{ color: "#677788" }}>
              NOMBRE
            </a>

            <div class="dropdown-menu" aria-labelledby="statusFilterDropdown">
              <form>

                {
                  clients.map((client) => (
                    <div class="dropdown-item" key={client.id}>
                      <input defaultChecked={clientFilter.find((filter) => filter == client.id)}
                        onChange={() => onChangeClient(client.id)}
                        type="checkbox" id={`filter_client_${client.id}`} name={`client_${client.id}`} />
                      <label className="px-2 align-middle" for={`filter_client_${client.id}`}>
                        <h5>{client.name}</h5>
                      </label>
                    </div>
                  ))
                }

              </form>
            </div>

          </div>
        </div>

        <div className="col-md-1 p-2">
          RFC
        </div>

        <div className="col-md-1 p-2">
          ESTADO
        </div>

        <div className="col-md-1 p-2 text-center">
          <div class="dropdown" style={{ marginTop: "-10px", marginBottom: "-10px", zIndex: "100" }}>

            <a class="btn btn-link dropdown-toggle"
              href="#"
              role="button"
              id="statusFilterDropdown"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              style={{ color: "#677788" }}>
              TIPO
            </a>

            <div class="dropdown-menu" aria-labelledby="statusFilterDropdown">
              <form>

                <div class="dropdown-item">
                  <input defaultChecked={typeFilter.find((filter) => filter == "type=EMITTED")}
                    onChange={() => onChangeType("type=EMITTED")}
                    type="checkbox" id="filter_type_EMITTED" name="type_EMITTED" />
                  <label className="px-2 align-middle" for="filter_type_EMITTED">
                    <h5>Emitidas</h5>
                  </label>
                </div>

                <div class="dropdown-item">
                  <input defaultChecked={typeFilter.find((filter) => filter == "type=RECEIVED")}
                    onChange={() => onChangeType("type=RECEIVED")}
                    type="checkbox" id="filter_type_RECEIVED" name="type_RECEIVED" />
                  <label className="px-2 align-middle" for="filter_type_RECEIVED">
                    <h5>Recibidas</h5>
                  </label>
                </div>

                <div class="dropdown-item">
                  <input defaultChecked={typeFilter.find((filter) => filter == "dataType=CFDI")}
                    onChange={() => onChangeType("dataType=CFDI")}
                    type="checkbox" id="filter_dataType_CFDI" name="dataType_CFDI" />
                  <label className="px-2 align-middle" for="filter_dataType_CFDI">
                    <h5>CFDI</h5>
                  </label>
                </div>

                <div class="dropdown-item">
                  <input defaultChecked={typeFilter.find((filter) => filter == "dataType=METADATA")}
                    onChange={() => onChangeType("dataType=METADATA")}
                    type="checkbox" id="filter_dataType_METADATA" name="dataType_METADATA" />
                  <label className="px-2 align-middle" for="filter_dataType_METADATA">
                    <h5>METADATA</h5>
                  </label>
                </div>

              </form>
            </div>

          </div>
        </div>

        <div className="col-md-2 p-2 text-center">
          <div class="dropdown" style={{ marginTop: "-10px", marginBottom: "-10px", zIndex: "100" }}>

            <a class="btn btn-link dropdown-toggle"
              href="#"
              role="button"
              id="statusFilterDropdown"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              style={{ color: "#677788" }}>
              PERIODO
            </a>

            <div class="dropdown-menu p-2" aria-labelledby="statusFilterDropdown" style={{width: "400px"}}>
              <form>
                <div class="row">
                  <div class="col-md-6 text-center">
                    Fecha inicio
                    <input type="date" name="start_date" class="form-control"
                      onChange={(event) => onChangeDate(event.target.value, "startDate")} />
                  </div>
                  <div class="col-md-6 text-center">
                    Fecha de fin
                    <input type="date" name="end_date" class="form-control"
                      onChange={(event) => onChangeDate(event.target.value, "endDate")} />
                  </div>
                </div>
              </form>
            </div>

          </div>
        </div>

        <div className="col-md-2 p-2">
          FECHA DE PETICIÓN
        </div>

        <div className="col-md-2 p-2">
          ACCIONES
        </div>

      </div>

      {
        requests.map((request) => (
          <div 
            className="row border mx-1" 
            id={`request_${request.id}`}
            key={`request_${request.id}`}
          >

            <div className="col-md-3 p-2">
              <span class="d-block font-size-sm">{request.client.name}</span>
            
              {request.isAutoDownload ? (
                <span class="badge badge-secondary">Autómatica</span>
              ) : (
                <span class="badge badge-info">Manual</span>
              )}
            </div>

            <div className="col-md-1 p-2">
              <span class="d-block font-size-sm">{request.client.rfc}</span>
            </div>

            <div className="col-md-1 p-2">
              <span class="d-block font-size-sm">
                {function () {
                  if (request.generalStatus == "REQUEST") return "Solicitado";
                  if (request.generalStatus == "VERIFICATION") return "En verificación";
                  if (request.generalStatus == "DOWNLOAD") return "Descargando";
                  if (request.generalStatus == "FINISHED") return "Finalizado";
                  if (request.codeStatus == 404 || request.codeStatus == 304) return "Error SAT";
                  if (request.generalStatus == "ERROR") return "Error";
                  if (request.generalStatus == "REJECTED") return "Rechazado";
                  if (request.generalStatus == "PARSING") return "Procesando";
                  if (request.generalStatus == "ERROR_PARSING") return "Error en el proceso";
                  if (request.generalStatus == "NO_DATA") return "Sin información";
                }()}
              </span>
            </div>

            <div className="col-md-1 p-2">
              {request.dataType}
              <br />
              <small>
                {function () {
                  if (request.type == "EMITTED") return "Emitidas";
                  if (request.type == "RECEIVED") return "Recibidas";
                }()}
              </small>
            </div>

            <div className="col-md-2 p-2">
              <span class="d-block font-size-sm">
                {moment(request.startDate).format("DD/MM/YYYY")} - {moment(request.endDate).format("DD/MM/YYYY")}
              </span>
            </div>

            <div className="col-md-2 p-2">
              <span class="d-block font-size-sm">
                {moment(request.createdAt).format("DD/MM/YYYY HH:mm:ss")}
              </span>
              <small>
                {function () {
                  if(request.generalStatus != "VERIFICATION") return;
                  moment.relativeTimeThreshold('m', 60);
                  moment.relativeTimeThreshold('h', 24 * 2);
                  return moment(request.createdAt).locale('es').fromNow();
                }()}
              </small>
            </div>

            <div className="col-md-2 p-2">

              <a 
                class="btn btn-primary text-center" 
                style={{ width: "50px" }}
                onClick={() => setOpenAccordion(openAccordion == request.id ? null : request.id)}
              >
                <i class="fas fa-eye"></i>
              </a>

              <a
                class="btn btn-info ml-1 text-center"
                href="#"
                style={{ width: "50px" }}
                onClick={() => setDetailsRequest(request.id)}
              >
                <i class="fas fa-info"></i>
              </a>

              {
                (request.generalStatus == "ERROR" || request.generalStatus == "REJECTED") &&
                  <a 
                    class="btn btn-danger ml-1 text-center"
                    href="#"
                    style={{ width: "50px" }}
                    onClick={() => onRetry(request.id)}
                  >
                    <i class="fas fa-redo"></i>
                  </a>
              }

              {
                (request.generalStatus == "REQUEST" || request.generalStatus == "VERIFICATION") &&
                  <a 
                    class="btn btn-success ml-1 text-center"
                    href="#"
                    style={{ width: "50px" }}
                    onClick={() => onVerify(request.id)}
                  >
                    <i class="fas fa-check"></i>
                  </a>
              }

            </div>

            <div className="col-md-12 mb-3">
              <div class={`collapse ${openAccordion == request.id ? "show" : ""}`} id={`details-${request.id}`}>
                <div class="pl-3">
                  
                  <hr />

                  <div class="row border mx-1">
                    <div class="col-md-3 p-2">
                      <span class="d-block font-size-sm">Fecha petición</span>
                    </div>
                    <div class="col-md-9 p-2">
                      <span class="d-block font-size-sm">Contenido</span>
                    </div>
                  </div>

                  {
                    function(){

                      let responses = request?.metadata?.responses??[];
                      // let count = 0;
                      // let last = null;

                      // (request?.metadata?.responses??[]).forEach((_metadata, index) => {

                      //   let metadata = Object.assign({}, _metadata);

                      //   if(metadata.estado_solicitud == 1){
                      //     last = metadata;
                      //     count++;
                      //   } 
                      //   else {
                          
                      //     if(count > 0) last.count = count;
                      //     if(last) responses.push(last);

                      //     last = null;
                      //     count = 0;
                      //     responses.push(metadata);

                      //   }

                      // });

                      // if(last) responses.push(last);

                      return responses.map((metadata, index) => (
                        <div 
                          id={`request_${request.id}_response_${index}`}
                          key={`request_${request.id}_response_${index}`}  
                          class={function(){
                            try {
                              const errors = [4, 404, 304, 500, 5002, 5004];
                              if(errors.includes(parseInt(metadata.estado_solicitud)) || errors.includes(parseInt(metadata.codigo_estado_solicitud)) 
                                || errors.includes(parseInt(metadata.cod_estatus)))
                                return `row border mx-1 bg-danger text-white`;
                            } catch (error) {
                            }
                            return `row border mx-1 ${(responses).length - 1 == index ? "bg-light" : ""}`
                          }()}
                        >
                          <div class="col-md-3 p-2">
                            <span class="d-block font-size-sm">{metadata?.date ? moment(metadata?.date).format("DD/MM/YYYY HH:mm:ss") : "-"}</span>
                          </div>
                          <div class="col-md-9 p-2">
                            <span class="d-block font-size-sm">
                              <pre>{JSON.stringify(metadata, null, 2)}</pre>
                            </span>
                          </div>
                        </div>
                      ))

                    }()
                  }

                  {
                    function(){
                      if(openAccordion == request.id) 
                        setTimeout(() => {
                          let index = (request?.metadata?.responses??[]).length - 1;
                          document?.getElementById(`request_${request.id}_response_${index}`)?.scrollIntoView();
                        }, 300);
                    }()
                  }

                </div>
              </div>
            </div>

            {
              openAccordion == request.id &&
                <div className="col-md-12 text-center mb-3">
                  <button 
                    class="btn btn-primary" 
                    onClick={() => {
                      setOpenAccordion(prev => {
                        document?.getElementById(`request_${prev}`)?.scrollIntoView({})
                        return null;
                      });
                    }}
                  >
                    <i class="fas fa-eye-slash"></i> Ocultar detalles
                  </button>
                </div>
            }

          </div>
        ))
      }

    </div>

    <Footer
      objectsInPage={requests.length}
      pageNum={pageNum}
      totalPages={totalPages}
      totalCount={totalCount}
      onClickPage={onClickPage}
    />

    {
      detailsRequest &&
        <Modal
          height="700"
          title="Detalles de la solicitud"
          onClose={() => setDetailsRequest(null)}
        >
          <CFDIsDetails 
            requestId={detailsRequest}
          />
        </Modal>
    }

  </div>
);

export default DownloadsView;
