import React from "react";
import { BrowserRouter } from "react-router-dom";
import { NavLink, Route } from "react-router-dom";
import Frame from "components/dashboard/Frame";

const Dashboard = ({ clientUsers, setSelectedClient, showMessage, onClickHideMessage }) => (
  <BrowserRouter basename="/home/dashboard">

    <div class="mb-1" style={{
      background: "#FBF9F8", borderRadius: "0.75rem", boxShadow: "0 6px 12px rgb(140 152 164 / 8%)",
      position: "relative", width: Math.min(window.innerWidth -120,1360), marginLeft: "auto", marginRight: "auto"
    }}>

      { /*
        clientUsers.length > 1 ? (
          <div class="col p-5">
            <form>
              <div class="form-group row">
                <h1 class="col-sm-1 col-form-label">Cliente</h1>
                <div class="col-sm-11">
                  <select class="form-control w-50" onChange={(e) => { setSelectedClient(e.target.value); }}>
                    <option selected value="">Selecciona un cliente</option>
                    {
                      clientUsers.map((clientUser) => (
                        <option key={clientUser.id} value={clientUser.client.id}>
                          {clientUser.client.name}
                        </option>
                      ))
                    }
                  </select>
                </div>
              </div>
            </form>
          </div>
        ) : null
      */ }

      {
        showMessage ?
          <div class="w-100 p-3 text-center" style={{ background: "#F2C811"}}>
            En caso de requerir inicio de sesión, ingresar con el usuario: <b>cw_visor@aplicsmx.onmicrosoft.com</b>, contraseña: <b>copperwolf_2023</b>
            <button onClick={onClickHideMessage} class="btn btn-sm btn-light ml-3">Ocultar mensaje</button>
          </div> : null
      }

    </div>
    <Route
        path="/:dashboard"
        component={Frame} />
  </BrowserRouter>

);

Dashboard.propTypes = {};

export default Dashboard;
