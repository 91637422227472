import React, { useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "seed/gql";
import { Loading } from "seed/helpers";
import View from "components/load/Load.view";

function Processes() {
  const userId = sessionStorage.getItem("id");
  const userType = sessionStorage.getItem("user");
  if (userType != "ADMIN" && userType != "SUPER_ADMIN") 
    window.location.href = "/dashboard/general";
  
  const [clientsFilter, setClientsFilter] = useState([])

  let clientQuery =  "";
  if (userType != "SUPER_ADMIN") 
    clientQuery = `client_users.user.id = ${userId}`;

  const reqClients = useQuery(`{
    clients {
      name
      rfc
    }
  }`, clientQuery, {orderBy: "name"})

  if (reqClients.loading) return <Loading />;
  if (reqClients.error) return "Error";

  const { clients = [] } = reqClients.data;

  const onChangeCheckbox = (id) => {
    const newFilters = [...clientsFilter];
    const index = newFilters.findIndex((filter) => filter == id);

    if (index == -1) {
      newFilters.push(id);
    } else {
      newFilters.splice(index, 1);
    }

    setClientsFilter(newFilters)
  }

  return (
    <View 
      clients={clients} 
      clientsFilter={clientsFilter}
      onChangeCheckbox={onChangeCheckbox}
    />
  );
}

Processes.propTypes = {};

export default Processes;