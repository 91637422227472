import React from "react";
import PropTypes from "prop-types";
import View from "components/navigation/Information.view";

function Information() {

  const type = sessionStorage.getItem("user")
  return <View type={type} />;
}

Information.propTypes = {};

export default Information;