import React, { useState } from "react";
import PropTypes from "prop-types";
import View from "components/download/List.view";
import { usePagination } from "seed/gql";
import { Loading } from "seed/helpers";
import { usePost } from "seed/api";
import { getDownloadFilters } from "components/utils/filtersUtil";

function CFDIsRequestsList({ filters }) {

  const userId = sessionStorage.getItem("id");
  const userType = sessionStorage.getItem("user");
  const pageSize = 30;
  const [pageNum, setPageNum] = useState(1);

  
  let filterString = getDownloadFilters(filters);
  if (userType != "SUPER_ADMIN") 
    filterString = `(${filterString}) AND client.client_users.user.id = ${userId}`;

  const qCFDIsRequests = usePagination(`{  
    cfdiRequestPagination {
      totalPages
      totalCount
      cfdiRequests {
        createdAt
        type
        startDate
        endDate
        dataType
        generalStatus
        codeStatus
        isAutoDownload
        rawDownload {
          name
          url
        }
        client {
          name
          rfc
        }
      }
    }
  }`, pageNum, pageSize, filterString, { orderBy: "-created_at"});

  const [ callRetry, qRetry ] = usePost("/cfdi_requests/retry_parsing", {
    onCompleted: () => {
      alert("Se reintentará el proceso");
    },
    onError: () => {
      alert("ha ocurrido un error, intente más tarde");
    }
  });

  const [callRetryDownload, reqRetryDownload] = usePost("/cfdi_requests/retry_request", {
    onCompleted: (data) => {
      alert("Solicitud reintentada con éxito");
      qCFDIsRequests?.refetch();
    },
    onError: (error) => {
      alert("Error al reintentar la descarga");
    }
  });

  const retryDownload = (requestId) =>
    callRetryDownload({ request_id: requestId });

  const retryParsing = (id) => {
    callRetry({
      request_id: id
    });
  }

  if(qCFDIsRequests.loading) return <Loading />;
  if(qCFDIsRequests.error) return <div>Error</div>;

  const {
    cfdiRequests = [],
    totalPages = 0,
    totalCount = 0,
  } = qCFDIsRequests.data.cfdiRequestPagination;

  return <View 
    qCFDIsRequests={qCFDIsRequests}
    requests={cfdiRequests}
    pageNum={pageNum}
    totalPages={totalPages}
    totalCount={totalCount}
    onClickPage={setPageNum}
    retryParsing={retryParsing}
    retryDownload={retryDownload}
  />;
}

CFDIsRequestsList.propTypes = {
  filters: PropTypes.object.isRequired
};

export default CFDIsRequestsList;