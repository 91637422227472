import React from "react";
import PropTypes from "prop-types";
import { BrowserRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import ClientList from "components/clients/List";
import ClientFormSave from "components/clients/FormSave";
import ClientFormSet from "components/clients/FormSet";
import Accounts from "components/clients/accounts/Accounts";
import FiltersSave from "components/clients/filters/Form";
import Filters from "components/clients/filters/List";
import { ModalRoute } from "seed/helpers";

const Clients = ({ isAdmin = false }) => (
  <BrowserRouter basename="/home/clients">
    <div class="card mb-3 mb-lg-5 px-3 py-2">
      <div class="card-header card-header-content-sm-between">
        <h3 class="card-header-title mb-2 mb-sm-0">Clientes</h3>
        {isAdmin ? (
          <Link to="/create" className="btn btn-primary">
            <i class="tio-user-add mr-2"></i>Nuevo cliente
          </Link>
        ) : null}
      </div>

      <div class="card-body">
        <ClientList />
      </div>

      <ModalRoute
        path="/create"
        component={ClientFormSave} />

      <ModalRoute
        path="/:clientId(\d+)/edit"
        component={ClientFormSet} />

      <ModalRoute
        width="900"
        path="/:clientId(\d+)/accounts"
        component={Accounts} />

      <ModalRoute
        path="/:clientId(\d+)/create_filter"
        component={FiltersSave} />

      <ModalRoute
        path="/:clientId(\d+)/filters"
        component={Filters} />

    </div>

  </BrowserRouter>
);

Clients.propTypes = {
  isAdmin: PropTypes.bool
};

export default Clients;
