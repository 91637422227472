import React from "react";
import PropTypes from "prop-types";
import Footer from "components/helpers/Footer";
import LoadForm from "components/load/Form";
import { ModalRoute } from "seed/helpers";

const ProcessList = ({
  processes = [],
  pageNum = 1,
  totalPages = 0,
  totalCount = 0,
  onClickPage = () => { },
  reqProcesses = {},
  retryParsing
}) => (

  <>

    {processes.map((process) => (
      <div className="row border mx-1" key={process.id}>
        <div className="col-md-1 p-2">
          <span class="d-block font-size-sm">{process.id}</span>
        </div>

        <div className="col-md-2 p-2">
          <span class="d-block font-size-sm">{process.createdAt.replace("T", " ").substring(0, 19)}</span>
        </div>

        <div className="col-md-3 p-2">
          {process.client && process.client.name}
        </div>

        <div className="col-md-2 p-2">
          <span class="d-block font-size-sm">{function () {
            if (process.status == "COMPLETED") return "Completado";
            if (process.status == "ERROR") return "Error";
            if (process.status == "PROCESSING") return "Procesando";
          }()}</span>
        </div>

        <div className="col-md-3 p-2">
          <span class="d-block font-size-sm">{process.description}</span>
        </div>

        <div className="col-md-1 p-2">
          {process.status == "ERROR" && <a className="btn btn-sm btn-white ml-2" role="button"
            onClick={() => {
              retryParsing(process.id);
            }}>
            Reintentar
          </a>}
        </div>
      </div>
    ))}

    <Footer
      objectsInPage={processes.length}
      pageNum={pageNum}
      totalPages={totalPages}
      totalCount={totalCount}
      onClickPage={onClickPage}
    />

    <ModalRoute
      path="/create"
      component={LoadForm}
      reqProcesses={reqProcesses}
      height={550}
    />

  </>
);

ProcessList.propTypes = {
  processes: PropTypes.array,
  pageNum: PropTypes.number,
  totalPages: PropTypes.number,
  totalCount: PropTypes.number,
  onClickPage: PropTypes.func,
  reqProcesses: PropTypes.object,
  retryParsing: PropTypes.func
};

export default ProcessList;
