import React from "react";
import { NavLink, Link } from "react-router-dom";
import PropTypes from "prop-types";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Popover from 'react-bootstrap/Popover';

const Topnav = ({ user, isAdmin, userType }) => (
  <div style={{ background: "#233548" }}>
    <div
      class="content container"
      style={{ height: "23rem", maxWidth: "68rem" }}
    >
      <div class="page-header page-header-light">
        <div class="navbar-nav">
          <div class="row align-items-center flex-grow-1 mt-3">
            <div class="col">
              {/* Logo */}
              <Link class="navbar-brand" to="/" aria-label="Front">
                <img
                  class="navbar-brand-logo"
                  src="/theme/img/logo.png"
                  alt="Logo"
                  style={{ maxWidth: "7.5rem", width: "7.5rem" }}
                />
              </Link>
            </div>

            <div class="col-auto">
              <ul class="list-inline row justify-content-center align-items-center">
                <li class="list-inline-item">

                  <a class="js-hs-unfold-invoker navbar-dropdown-account-wrapper"
                    data-hs-unfold-options={`{
                      "target": "#accountNavbarDropdown1",
                      "type": "css-animation"
                    }`} style={{ cursor: "pointer" }}
                  >
                    <h4 class="mt-2 text-center mx-3" style={{ color: "white" }} >
                      <div>
                        {user.firstName} {user.lastName}
                      </div>
                      <small>
                        {
                          function () {
                            if (userType == "SUPER_ADMIN")
                              return "Súper Administrador"
                            else if (userType === "ADMIN")
                              return "Administrador"
                            else
                              return "Cliente"
                          }()
                        }
                      </small>
                    </h4>

                    <div class="avatar avatar-sm avatar-circle" style={{ border: "2px solid #dddde0" }}>
                      <img
                        class="avatar-img"
                        src="/theme/img/160x160/img1.jpg"
                        alt="Profile"
                      />
                    </div>

                  </a>

                  <div
                    id="accountNavbarDropdown1"
                    class={`hs-unfold-content dropdown-unfold
                      dropdown-menu dropdown-menu-right navbar-dropdown-menu navbar-dropdown-account`}
                    style={{ width: "16rem" }}
                  >
                    <div class="dropdown-item-text">
                      <div class="media align-items-center">
                        <div class="avatar avatar-sm avatar-circle mr-2">
                          <img
                            class="avatar-img"
                            src="/theme/img/160x160/img1.jpg"
                            alt="Profile"
                          />
                        </div>
                        <div class="media-body">
                          <span class="card-title h5">
                            {user.firstName} {user.lastName}
                          </span>
                          <span class="card-text">{user.email}</span>
                        </div>
                      </div>
                    </div>

                    <div class="dropdown-divider"></div>

                    {/* Options */}
                    <Link to="/information" className="dropdown-item">
                      <span class="text-truncate pr-2" title="Material de apoyo">
                        Material de apoyo
                      </span>
                    </Link>
                    {isAdmin ?
                    <a href="https://seed-project.atlassian.net/servicedesk/customer/portal/5" className="dropdown-item">
                      <span class="text-truncate pr-2" title="Material de apoyo">
                        Reportar incidente
                      </span>
                    </a> : null}

                    <Link to="/logout" className="dropdown-item">
                      <span class="text-truncate pr-2" title="Sign out">
                        Cerrar Sesión
                      </span>
                    </Link>
                  </div>

                </li>
              </ul>
            </div>
          </div>

          <div class="js-nav-scroller hs-nav-scroller-horizontal mt-1">
            <ul
              id="pageHeaderTab"
              class="nav nav-tabs nav-tabs-light page-header-tabs"
              role="tablist"
            >

              {/* Dashboard */}
              <li class="nav-item">
                <NavLink
                  to="/dashboard/general"
                  className="nav-link"
                  style={{ padding: "0.45rem 0.85rem" }}
                >
                  Dashboard
                </NavLink>
              </li>

              {isAdmin ? (
                <>
                  {/* Clients */}
                  <OverlayTrigger key="bottom" placement="bottom"
                    overlay={<Popover id="popover-positioned-right">
                      <Popover.Body class="p-3">
                        Registra clientes, da de alta cuentas de acceso.
                      </Popover.Body>
                    </Popover>}>
                    <li class="nav-item">
                      <NavLink
                        to="/clients"
                        className="nav-link"
                        style={{ padding: "0.45rem 0.85rem" }}
                      >
                        Clientes
                      </NavLink>
                    </li>
                  </OverlayTrigger>

                  <li className="nav-item"
                    style={{ borderRight: "1px solid rgba(255,255,255,0.15)", marginBottom: "7px" }}></li>

                  
                  {/* Download */}
                  <OverlayTrigger key="bottom" placement="bottom"
                    overlay={<Popover id="popover-positioned-right">
                      <Popover.Body class="p-3">
                        Envía solicitudes al SAT para descargar CFDIs o Metadata.
                      </Popover.Body>
                    </Popover>}>
                    <li class="nav-item">
                      <NavLink
                        to="/download"
                        className="nav-link"
                        style={{ padding: "0.45rem 0.85rem" }}
                      >
                        Descargas SAT
                      </NavLink>
                    </li>
                  </OverlayTrigger>


                  {/* Load */}
                  <OverlayTrigger key="bottom" placement="bottom"
                    overlay={<Popover id="popover-positioned-right">
                      <Popover.Body class="p-3">
                        Carga desde tu computadora CFDIs (XMLs) o Metadata (txt) de tus clientes.
                      </Popover.Body>
                    </Popover>}>
                    <li class="nav-item">
                      <NavLink
                        to="/load"
                        className="nav-link"
                        style={{ padding: "0.45rem 0.85rem" }}
                      >
                        Carga manual
                      </NavLink>
                    </li>
                  </OverlayTrigger>
                  
                  {/* Export CFDI in PDF */}
                  <OverlayTrigger key="bottom" placement="bottom"
                    overlay={<Popover id="popover-positioned-right">
                      <Popover.Body class="p-3">
                        Descarga a tu computadora los CFDIs almacenados en el sistema en formato PDF.
                      </Popover.Body>
                    </Popover>}>
                    <li class="nav-item">
                      <NavLink
                        to="/export_pdf"
                        className="nav-link"
                        style={{ padding: "0.45rem 0.85rem" }}
                      >
                        Exportar a PDF
                      </NavLink>
                    </li>
                  </OverlayTrigger>

                  
                  {/* Export data */}
                  <OverlayTrigger key="bottom" placement="bottom"
                    overlay={<Popover id="popover-positioned-right">
                      <Popover.Body class="p-3">
                        Descarga a tu computadora los CFDIs almacenados en el sistema.
                      </Popover.Body>
                    </Popover>}>
                    <li class="nav-item">
                      <NavLink
                        to="/export_cfdi"
                        className="nav-link"
                        style={{ padding: "0.45rem 0.85rem" }}
                      >
                        Exportar CFDIs
                      </NavLink>
                    </li>
                  </OverlayTrigger>

                </>
              ) : null}


            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
);

Topnav.propTypes = {
  user: PropTypes.object.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  userType: PropTypes.string.isRequired,
};

export default Topnav;
