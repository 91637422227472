import React from "react";
import PropTypes from "prop-types";
import Footer from "components/helpers/Footer";
import { Link } from "react-router-dom";
import CFDIsForm from "components/download/Form";
import { ModalRoute } from "seed/helpers";
import moment from "moment/moment";
import "moment/locale/es";
moment.locale("es");

const CFDIsRequestsList = ({ requests = [], pageNum = 1, totalCount = 1, totalPages = 0, onClickPage = () => { },
  qCFDIsRequests, retryParsing, retryDownload }) => (

  <>

    {requests.map((request) => (
      <div className="row border mx-1" key={request.id}>

        <div className="col-md-3 p-2">
          <span class="d-block font-size-sm">{request.client.name}</span>
         
          {request.isAutoDownload ? (
            <span class="badge badge-secondary">Autómatica</span>
          ) : (
            <span class="badge badge-info">Manual</span>
          )}
        </div>

        <div className="col-md-1 p-2">
          <span class="d-block font-size-sm">{request.client.rfc}</span>
        </div>

        <div className="col-md-1 p-2">
          <span class="d-block font-size-sm">
            {function () {
              if (request.generalStatus == "REQUEST") return "Solicitado";
              if (request.generalStatus == "VERIFICATION") return "En verificación";
              if (request.generalStatus == "DOWNLOAD") return "Descargando";
              if (request.generalStatus == "FINISHED") return "Finalizado";
              if (request.codeStatus == 404 || request.codeStatus == 304) return "Error SAT";
              if (request.generalStatus == "ERROR") return "Error";
              if (request.generalStatus == "REJECTED") return "Rechazado";
              if (request.generalStatus == "PARSING") return "Procesando";
              if (request.generalStatus == "ERROR_PARSING") return "Error en el proceso";
              if (request.generalStatus == "NO_DATA") return "Sin información";
              if (request.generalStatus == "NO_DATA_REJECTED") return "Sin información  (Rechazado)";
            }()}
          </span>
        </div>

        <div className="col-md-1 p-2">
          {request.dataType}
          <br />
          <small>
            {function () {
              if (request.type == "EMITTED") return "Emitidas";
              if (request.type == "RECEIVED") return "Recibidas";
            }()}
          </small>
        </div>

        <div className="col-md-2 p-2">
          <span class="d-block font-size-sm">
            {moment(request.startDate).format("DD/MM/YYYY")} - {moment(request.endDate).format("DD/MM/YYYY")}
          </span>
        </div>

        <div className="col-md-2 p-2">
          <span class="d-block font-size-sm">
            {moment(request.createdAt).format("DD/MM/YYYY HH:mm:ss")}
          </span>
          <small>
            {function () {

              if (request.generalStatus != "VERIFICATION") return;

              let date = moment(request.createdAt);
              let now = moment();
              let diff = now.diff(date, 'hours');

              moment.relativeTimeThreshold('m', 60);
              moment.relativeTimeThreshold('h', 24 * 2);

              if (diff < 48) return moment(request.createdAt).locale('es').fromNow();
              else return "hace más de 48 horas"

            }()}
          </small>
        </div>

        <div className="col-md-2">
          <Link to={`/${request.id}`} class="btn btn-sm btn-white mt-2">
            Detalles
          </Link>
          {function () {

            if (request.generalStatus == "ERROR_PARSING")
              return <a className="btn btn-sm btn-white ml-2 mt-2" role="button"
                onClick={() => {
                  retryParsing(request.id);
                }}>
                Reintentar
              </a>

            if (request.codeStatus == 404 || request.generalStatus == "NO_DATA_REJECTED")
              return <a className="btn btn-sm btn-white ml-2 mt-2" role="button"
                onClick={() => {
                  retryDownload(request.id);
                }}>
                Reintentar
              </a>

          }()}
        </div>

      </div>
    ))}

    <Footer
      objectsInPage={requests.length}
      pageNum={pageNum}
      totalPages={totalPages}
      totalCount={totalCount}
      onClickPage={onClickPage}
    />

    <ModalRoute
      path="/create"
      component={CFDIsForm}
      qCFDIsRequests={qCFDIsRequests} />


  </>
);

CFDIsRequestsList.propTypes = {
  requests: PropTypes.array,
  pageNum: PropTypes.number,
  totalCount: PropTypes.number,
  totalPages: PropTypes.number,
  onClickPage: PropTypes.func,
  qCFDIsRequests: PropTypes.object,
  retryParsing: PropTypes.func
};

export default CFDIsRequestsList;
