const stringToDate = (date, format, delimiter) => {

    let formatLowerCase = format.toLowerCase();
    let formatItems = formatLowerCase.split(delimiter);

    let dateItems = date.split(delimiter);
    let monthIndex = formatItems.indexOf("mm");
    let dayIndex = formatItems.indexOf("dd");
    let yearIndex = formatItems.indexOf("yyyy");

    let year = parseInt(dateItems[yearIndex]);
    let month = parseInt(dateItems[monthIndex]) - 1;
    let day = parseInt(dateItems[dayIndex]);

    let formatedDate = new Date(year, month, day);
    return formatedDate;

}

export { stringToDate }