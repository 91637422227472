import React, { useState } from "react";
import PropTypes from "prop-types";
import { usePagination } from "seed/gql";
import { Loading } from "seed/helpers";
import { usePost } from "seed/api";
import { getClientFilter } from "components/utils/filtersUtil";
import View from "components/load/List.view";


function LoadList({ clientsFilter }) {

  const userId = sessionStorage.getItem("id");
  const userType = sessionStorage.getItem("user");

  const pageSize = 30;
  const [pageNum, setPageNum] = useState(1);

  let clientsFilterString = getClientFilter(clientsFilter);
  if (userType != "SUPER_ADMIN") 
    clientsFilterString = `(${clientsFilterString}) AND client.client_users.user.id = ${userId}`;
  
  if (clientsFilterString == "") {
    clientsFilterString += "cfdiRequestId = 0";
  } else {
    clientsFilterString += " AND cfdiRequestId = 0";

  }

  const reqProcesses = usePagination(`
  {
    processPagination {
      totalPages
      totalCount
      processes {
        status
        description
        createdAt
        client {
          name
          rfc
        }
      }
    }
  }`, pageNum, pageSize, clientsFilterString, { orderBy: "-id" });

  const [callRetry, qRetry] = usePost("/processes/retry_parsing", {
    onCompleted: () => {
      alert("Se reintentará el proceso");
    },
    onError: () => {
      alert("ha ocurrido un error, intente más tarde");
    }
  });

  const retryParsing = (id) => {
    reqProcesses.refetch();
    callRetry({ process_id: id });
  }

  if (reqProcesses.loading) return <Loading />;
  if (reqProcesses.error) return "Error";
  const {
    processes = [],
    totalPages = 0,
    totalCount = 0,
  } = reqProcesses.data.processPagination;

  const onClickPage = (pageNum) => setPageNum(pageNum);

  return (
    <View
      reqProcesses={reqProcesses}
      processes={processes}
      pageNum={pageNum}
      totalPages={totalPages}
      totalCount={totalCount}
      onClickPage={onClickPage}
      retryParsing={retryParsing}
    />
  );
}

LoadList.propTypes = {
  clientsFilter: PropTypes.array.isRequired
};

export default LoadList;
