import React from "react";
import PropTypes from "prop-types";
import View from "components/helpers/Footer.view";

function Footer({
  objectsInPage = 0,
  pageNum = 1,
  totalPages = 0,
  totalCount = 0,
  onClickPage = () => {},
}) {
  return (
    <View
      objectsInPage={objectsInPage}
      pageNum={pageNum}
      totalPages={totalPages}
      totalCount={totalCount}
      onClickPage={onClickPage}
    />
  );
}

Footer.propTypes = {
  objectsInPage: PropTypes.number,
  pageNum: PropTypes.number,
  totalPages: PropTypes.number,
  totalCount: PropTypes.number,
  onClickPage: PropTypes.func,
};

export default Footer;
