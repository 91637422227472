import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";

const Frame = ({
  query = "",
  queryModified,
  clients,
  dashboardUrl,
  showFilters,
  showClientSelect,
  boolQuery,
  filterGroup,
  filters,
  onChangeSelect,
  handleCloseModal,
  handleShowModal,
  changeQuery,
  showModal,
}) => (
  <div style={{
    width: Math.min(window.innerWidth - 120, 1360),
    marginLeft: "auto", marginRight: "auto", position: "relative"
  }}>

    {showFilters ?
      <div className="text-right mt-0 mb-2">
        <Link to="#" className="btn btn-dark btn-xs" onClick={handleShowModal}>
          <i class="fa fa-receipt mr-2"></i>
          Filtros precargados
        </Link>
      </div>
      : null}
    <Modal className="mx-auto" show={showModal} onHide={handleCloseModal}>
      <Modal.Header>
        <Modal.Title>Filtros precargados</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <select className="form-control" onChange={(e) => { changeQuery(e.target.value) }}>
          <option value="na">
            Selecciona un filtro
          </option>
          {Object.entries(filterGroup).map(([cliente, values]) => (
            <optgroup key={cliente} label={cliente}>
              {values.map((value) => (
                <option key={value.id} value={value.id}>
                  {value.name}
                </option>
              ))}
            </optgroup>
          ))}
        </select>
      </Modal.Body>
      <Modal.Footer>
        <div className="btn btn-primary" onClick={handleCloseModal}>
          Cerrar
        </div>
      </Modal.Footer>
    </Modal>

    {showClientSelect ?
      <div className="w-100">
        <div className={"text-center mb-3 px-3 " + (!localStorage.getItem("hideMessageB") ? "mt-0" : "mt-3") }>
          <select className="btn btn" style={{background: "#fff"}} 
            onChange={(e) => onChangeSelect(e.target.value)}>
            <option value="na">Selecciona un cliente</option>
            {
              clients.map(client => <option value={client.urlDashboard}>{client.name}</option>)
            }
          </select>
        </div>
      </div>
      : null }

      {/*<div className="text-left mb-2" style={{marginTop: "-2.25rem"}}>
        <select className="btn btn-dark btn-xs" 
            onChange={(e) => onChangeSelect(e.target.value)}>
            <option value="na">Clientes</option>
            {
              clients.map(client => <option value={client.urlDashboard}>{client.name}</option>)
            }
        </select>
          </div>*/}

    {dashboardUrl != "" ?
      <div class="w-100 text-center">
        <iframe
          style={{
            width: Math.min(window.innerWidth - 120, 1360), border: "none", overflow: "hidden", background: "#FBF9F8",
            height: "2500px",
          }}
          className="mx-auto"
          scrolling="no"
          frameBorder="0"
          title="Panel"
          src={`${dashboardUrl}&language=es-MX${boolQuery ? queryModified : query}`}></iframe>
        <div
          style={{
            width: "100%",
            height: "40px",
            background: "#FFF",
            zIndex: "1000",
            position: "relative",
            marginTop: "-40px"
          }}
        ></div>
      </div> : null}
  </div>
);

Frame.propTypes = {
  pageId: PropTypes.string.isRequired,
  queryClient: PropTypes.string.isRequired
};

export default Frame;
