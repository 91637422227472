import React, { useState } from "react";
import PropTypes from "prop-types";
import View from "components/clients/accounts/Form.view";
import { useDetail } from "seed/gql";
import { usePut } from "seed/api";
import { Loading } from "seed/helpers";
import { useHistory } from "react-router-dom";

function FormSet({ clientUserId, reqUsers }) {

  const history = useHistory();
  const [error, setError] = useState(null);

  const [callPut, reqPut] = usePut("/client_users/accounts", {
    onCompleted: () => {
      reqUsers.refetch();
      history.goBack();
    },
    onError: (data) => {
      switch (data.status) {
        
        case 401:
          setError("Correo ya registrado, por favor actualize a otro");
          break;
        
        case 404:
          setError("Error del sistema, el usuario no se encontró, por favor intente nuevamente");
          break;

        default:
          setError("Ha ocurrido un error, por favor intente nuevamente");
          break;
      }
    }
  });

  const reqClientUser = useDetail(`{
    clientUser {
      id
      type
      user {
        id
        firstName
        lastName
        email
      }
    }
  }`, clientUserId);

  if(reqClientUser.loading) return <Loading />;
  if(reqClientUser.error && reqClientUser.error.message.includes("matching query does not exist")) 
    history.goBack();
    
  if(reqClientUser.error) return "Error";

  const { clientUser = {} } = reqClientUser.data;

  const user = {
    firstName: clientUser.user.firstName,
    lastName: clientUser.user.lastName,
    email: clientUser.user.email,
    userType: clientUser.type
  }

  const onSubmit = (values) => {
    const data = {
      first_name: values["firstName"],  
      last_name: values["lastName"],
      email: values["email"], 
      user_type: values["userType"], 
      password: values["password"] ? values["password"] : "", 
      client_user_id: clientUserId
    };
    callPut(data);
  }

  return <View
    user={user}
    isUpdate={true}
    onSubmit={onSubmit}
    error={error}
  />;

}

FormSet.propTypes = {
  clientUserId: PropTypes.number.isRequired,
  reqUsers: PropTypes.object.isRequired,
};

export default FormSet;