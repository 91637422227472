import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import View from "components/clients/accounts/Accounts.view";
import { useQuery, useDetail } from "seed/gql";
import { Loading } from "seed/helpers";

function Accounts(props) {

  const history = useHistory();
  const { clientId = 0 } = props.match.params;
  const userId = sessionStorage.getItem("id");
  const userType = sessionStorage.getItem("user");

  let query = "";
  if (userType == "ADMIN") query = `user.id = ${userId} AND client.id = ${clientId}`;

  const qClient = useDetail(`{
    client {
      name
      rfc
      dailyDownload
    }
  }`, clientId);

  const reqClientUser = useQuery(`{
    clientUsers {
      client { id }
      user { id }
    }
  }`, query)

  if(qClient.loading) return <Loading />;
  if(qClient.error && qClient.error.message.includes("matching query does not exist")) 
    window.location.href = "/home/clients";

  if(reqClientUser.loading) return <Loading />;
  if(reqClientUser.error) return "Error";

  const { clientUsers = [] } = reqClientUser.data;

  return <View clientId={clientId} />;
}

Accounts.propTypes = {
  props: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default Accounts;